import React from "react";
import Ripple from "../shared/LoadingAnimation"

export const NoMessage = () => {
  return (
    <div id="dashboard" className="container p-20">
      <div className="h-100 flex row p-20 w-100 align-self-center text-center">
        <div className="col-6 align-self-center  w-50 p-3 h-50 mx-auto " id="inboxEmpty">
          {/* <Ripple color="#0033cc" style={{ width: "200px", height: "200px" }} /> */}
          <div style={{height: "150px"}}>
            <div className="mb-3 rounded w-75 b-2 h-50 float-right container" style={{border: "2px solid #DCDCDC"}}>
              <div className="mt-3 ml-1 rounded my-2 " style={{backgroundColor: "#C4C4C4", height: "27%", width:"43%"}}></div>
              <div className="ml-1 rounded w-25" style={{backgroundColor: "#DCDCDC", height: "27%"}}></div>
            </div>
            <div className=" mb-3 w-75 rounded b-2 h-50 float-left container" style={{border: "2px solid #DCDCDC"}}>
              <div className="  my-2 mt-3 ml-1 rounded w-25"  style={{backgroundColor: "#C4C4C4", height: "27%"}}></div>
              <div className="  rounded ml-1 mb-2 " style={{backgroundColor: "#DCDCDC", width: "65%" , height: "27%"}}></div>
            </div>
          </div>
          <br></br>
          <div style={{width:"100%"}}>
            <h1 className="mb-3" style={{fontSize:"large"}}> <br></br>  No conversations yet? <br></br> Start by connecting a channel.</h1>
            
            <p className="mb-5">Connecting a channel lets you chat with your leads and customers in NovoChat’s Inbox.</p>
            <div  className="btn btn-primary p-2" onClick={()=>{window.open(window.location.href.replace("conversations", "platforms"), "_self")}}> + Connect Channel </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMessage;
