import React, { useState, useContext, useEffect } from "react";
import DashboardContext from "../dashboard/DashboardContext.js";
import { Button, Modal, Form, Table } from "react-bootstrap";
import SettingIcon from "images/svgs/settings.svg";
import axios from "axios";
import * as _ from "lodash";

const ChannelStatus = ({ showChannelStatus, handleClose, handleSubmit }) => {
  const dashboardContext = useContext(DashboardContext);
  const [channelList, setChannelList] = useState([]);
  const { state } = dashboardContext;
  let tempChannels = [];
  useEffect(() => {
    loadChannelList();
  }, []);

  const loadChannelList = () => {
    let url = `${state.domain_url}/apps/${state.app_id}/platforms.json`;
    axios({
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        tempChannels = response.data.data;
        response.data.data.map((channel, index) => {
          LoadStatus(channel.id);
        });
      })
      .catch((error) => {
        if (error.response) {
          toastr.error("An error has occurred");
        }
      });
  };

  const LoadStatus = (id) => {
    let url = `${state.domain_url}/apps/${state.app_id}/platforms/${id}/status`;
    let channel_status = "";
    let current_channel = _.find(tempChannels, ["id", id]);
    axios({
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        let data = response.data;
        tempChannels = [
          ...tempChannels.filter((channel) => channel.id !== data.id),
          { ...current_channel, channel_status: data },
        ];
        setChannelList(tempChannels);
      })
      .catch((error) => {
        console.log(error);
        tempChannels = [
          ...tempChannels.filter((channel) => channel.id !== id),
          { ...current_channel, channel_status: { status: "error" } },
        ];
        setChannelList(tempChannels);
        // toastr.error("There was an error");
      });
  };

  const ChannelList = () => {
    let list = [];
    _.sortBy(channelList, "name").map((data, index) => {
      list.push(
        <tr className="border-0">
          <td colSpan="2">{data.internal_name}</td>
          <td>
            {!("channel_status" in data) ? (
              <SpinnerLoading />
            ) : data.channel_status.status === "inactive" ? (
              <>
                <p className="text-danger d-inline">Inactive</p>
                <a target="_blank" href={`${data.channel_status.platform_url}`}>
                  <img
                    src={SettingIcon}
                    width={25}
                    height={15}
                    className="d-inline"
                  />
                </a>
              </>
            ) : data.channel_status.status === "active" ? (
              <p className="text-success">Active</p>
            ) : (
              <>
                <p className="text-danger d-inline">Inactive</p>
                <a
                  target="_blank"
                  href={`/apps/${state.app_id}/platforms/${data.id}/edit`}
                >
                  <img
                    src={SettingIcon}
                    width={25}
                    height={15}
                    className="d-inline"
                  />
                </a>
              </>
            )}
          </td>
        </tr>
      );
    });
    return list;
  };

  const SpinnerLoading = () => {
    return (
      <>
        <div
          className="spinner-border spinner-border-sm text-secondary"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </>
    );
  };

  return (
    <Modal
      show={showChannelStatus}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="mt-5 pt-3"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title><h3>Channel Status</h3></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table borderless>
          <thead>
            <tr className="border-0 thead-light">
              <th scope="col">Channels</th>
              <th scope="col"></th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <ChannelList />
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChannelStatus;
