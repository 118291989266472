import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Modal, Card } from "react-bootstrap";
import Avatar from "./Avatar";

const MyVerticallyCenteredModal = (props) => {
  const { currentWorkspace } = props;

  const cardRows = {
    orphans: "1",
    widows: "1",
    rowGap: "1rem",
    display: "grid",
    gridTemplateColumns: "minmax(0, 32%) 32% 32%",
    columnGap: "1rem",
  };

  const cardStyle = {
    display: "grid",
  };

  const getCardColor = (workspaceId) => {
    if (workspaceId === currentWorkspace.id) {
      return "primary";
    }
    return "dark";
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0 p-4">
        <Modal.Title id="contained-modal-title-vcenter">
          Your Workspaces
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid pt-0 pb-4 mb-2">
        {/* <Container>
          <Row>
            <Col xs={6} md={4}> */}
        <div style={cardRows}>
          {props.workspaces.map((workspace, i) => (
            <a
              href={`${props.domain_url}/apps/${workspace.id}/conversations`}
              key={workspace.id}
              style={cardStyle}
            >
              <Card bg={getCardColor(workspace.id)} text="white">
                <Card.Body>
                  <Card.Title>{workspace.name}</Card.Title>
                </Card.Body>
              </Card>
            </a>
          ))}
        </div>
        {/* </Col>
          </Row>
        </Container> */}
      </Modal.Body>
    </Modal>
  );
};

const WorkspaceModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { currentWorkspace, workspaces, domain_url } = props;

  const textStyle = {
    width: "100%",
    padding: "5px 10px 0 10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    textAlign: "center",
    color: "#3586F6",
  };

  return (
    <>
      <Tippy content="Switch Workspaces" placement="top">
        <div onClick={() => setModalShow(true)}>
          <Avatar workspaceName={currentWorkspace.name} />
          <p style={textStyle}>{currentWorkspace.name}</p>
        </div>
      </Tippy>
      <MyVerticallyCenteredModal
        domain_url={domain_url}
        currentWorkspace={currentWorkspace}
        workspaces={workspaces}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default WorkspaceModal;
