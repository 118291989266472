import React from "react";

export const SearchBar = () => {
  return (
    <>
      <div className="input-group px-2 mb-2">
        <div className="input-group-prepend">
          <i
            className="fas fa-search form-control-feedback"
            style={{ margin: "auto 0", width: "30px" }}
          />
        </div>
        <input
          type="text"
          className="form-control rounded-lg"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
};

export default SearchBar;
