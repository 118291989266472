import React from 'react';
import CheckBoxSVG from './CheckBoxSVG';
import { Button } from 'react-bootstrap';
import Detail from './Detail';

const StepThree = ({ 
    active = false,
    email,
    handleChangeInput,
    isSubmit,
    showDetail,
    showHeader = true
  }) => {  
  return(
    <div className="section-item border-bottom-0 mb-0 pb-4">
      <div className="header">
        { showHeader && (
          <div className="icon-checkbox">
            <CheckBoxSVG active={active} />
          </div>
        )}

        <div className="header-items">
          <div className={`item title ${ active ? 'active' : '' }`}>
            Step 3
          </div>

          <div className={`item subtitle ${ active ? 'active' : '' }`}>
            Add widget to your website
          </div>
        </div>
      </div>

      <div className="content-data">
        <div className="col-10 col-sm-12 p-0 content-fill">
          <div className="form">
            <div className="form-inline align-items-end">
              <div className="left">
                <label className={active ? 'active' : ''}>We will send the widget code to your email</label>
                <input 
                  type="email"
                  className="text-input" 
                  placeholder="Enter email here"
                  disabled={!active || isSubmit}
                  value={email}
                  required={true}
                  onChange={(e) => handleChangeInput('email', e.target.value)} />
              </div>

              <div className="right">
                <Button 
                  type="submit"
                  className={`btn btn-primary ${!active || isSubmit ? 'disabled' : ''}`}
                  disabled={!active || isSubmit}>
                  {isSubmit ? 'Loading...' : 'Get widget code'}
                </Button>
              </div>
            </div>
          </div>
          
          { showDetail && (
            <Detail />
          ) }
        </div>
      </div>
    </div>
  );
};

export default StepThree;