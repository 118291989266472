import React, { useContext, useState, useEffect } from "react";
import DashboardContext from "./DashboardContext.js";
import axios from "axios";

export const ResolveConversationButton = () => {
  const dashboardContext = useContext(DashboardContext);
  const {
    state,
    setTotalCountsTab,
    removeFromConversations,
  } = dashboardContext;
  const [resolved, setResolved] = useState(state.selectedConversation.resolved);

  const handleClick = () => {
    let url;
    if (resolved) {
      // Re-open
      url = `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/reopen`;
    } else {
      // Resolve
      url = `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/resolve`;
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (
            (!resolved && state.selectedTab === "me") ||
            (!resolved && state.selectedTab === "unassigned") ||
            (resolved && state.selectedTab === "resolved") ||
            (resolved && state.selectedTab === "all")
          ) {
            removeFromConversations(state.selectedConversation.id);
          }
          setResolved(!resolved);
          setTotalCountsTab();
          toastr.success("Success");
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });
  };

  const buttonText = resolved == true ? "Re-open" : "Resolve";

  return (
    <span
      className="btn btn-primary float-right"
      type="button"
      onClick={handleClick}
    >
      {buttonText}
    </span>
  );
};

export default ResolveConversationButton;
