import React, { useEffect } from "react";
import { Button } from 'react-bootstrap';

const EmptyData = ({ title = '', subtitle = '', handleOnClick = () => {}, buttonText = '', link = null }) => {
  useEffect(() => {
    if(link) {
      handleOnClick = click;
    }
  },
  []);
  
  const click = () => {
    if(link) {
      window.location.href = link;
    }
  };

  return (
    <div className="info-empty d-flex justify-content-center align-self-center text-center">
      
      <div className="w-50">
        <div className="title">
          { title }
        </div>
        <div className="subtitle">
          { subtitle }
        </div>

        <div className="actions">
          <Button 
            className="btn btn-primary"
            onClick={() => handleOnClick()}>
            { buttonText }
          </Button>
        </div>
      </div>
      
    </div>
  );
};

export default EmptyData;