import React, { useContext } from "react";
import axios from "axios";
import { debounce } from 'lodash'
import { Button } from "react-bootstrap";
import DashboardContext from "../dashboard/DashboardContext";
import ConfirmationModal from "../shared/ConfirmationModal";

class AssignContact extends React.Component{
  static contextType = DashboardContext;

  constructor(props){
    super(props)

    this.state = {
      base_url: '/contacts',
      by_keyword: '',
      app_id: this.props.app_id,
      domain_url: this.props.domain_url,
      conversation_id: this.props.selectedConversation.id,
      contact: this.props.contact,
      setContact: this.props.setContact,
      contact_list: [],
      current_page: 1,
      show_modal: false,
      confirmation_modal: false,
      all_errors_add_contact: [],
      disabled_add_contact: false,
      is_loading: true,
      show_modal_import: false,
      errorFile: false,
      assigned_to_contact_id: ''
    }

  }

  _dropdown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  _findContact = debounce((value) => {
    if (value == '') {
      this.setState({
        by_keyword: `?by_keyword=${value}`,
        contact_list: []
      })
    } else {
      this.setState({
        by_keyword: `?by_keyword=${value}`,
      }, () => {
        this._getContactList();
      })
    }
  }, 500)

  _getContactList = () => {
    this.setState({is_loading: true})

    let _this = this;
    const { base_url, by_keyword, domain_url, app_id, current_page } = this.state
    const url = domain_url+'/apps/'+app_id+base_url+by_keyword
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "GET",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params:{
        page: current_page
      }
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {
            var response_data = response.data.data;
            // console.log('response_data', response_data)
            if(response_data){
              var state = {
                contact_list: response_data.data,
                disabled_add_contact: false,
                confirmation_modal: false,
                show_modal: false,
                all_errors_add_contact: []
              }
              _this._setState(state)
            }

          }
          else{
            if(response.data.errors){
              _this.setState({all_errors_add_contact: response.data.errors})
            }
            if(response.data.message){
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });

      this._LOADING = setInterval(() => {
        this.setState({is_loading: false})
      }, 1000);
  }

  _setState = (state) => {
    this.setState(state)
  }

  _confirmationToAssign = (contact) => {
    this.setState({
      confirmation_modal: true,
      show_modal: true,
      assigned_to_contact_id: contact.id,
      contact: contact
    });
  }

  _closeModal = () => {
    this.setState({
      confirmation_modal: false,
      show_modal: false,
      assigned_to_contact_id: ''
    });
  }

  _submitAssignedToContact = () => {
    let _this = this
    const { base_url, domain_url, app_id, import_file } = this.state
    const { state, setAssignContact, setConversations } = this.context;
    const url = domain_url+'/apps/'+app_id+base_url+'/assigned_to_contact'
    const token = document.querySelector("[name=csrf-token]").content;

    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios.post(url, {
      assigned_contact_id: this.state.assigned_to_contact_id,
      conversation_id: this.state.conversation_id
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {
            toastr.success(response.data.data.message);
            _this.setState({
              confirmation_modal: false,
              show_modal: false,
              assigned_to_contact_id: ''
            })
            _this.state.setContact(_this.state.contact)

            setAssignContact({
              ...state.selectedConversation,
              name: _this.state.contact.name
            })

            let newConversations = state.conversations.map(el => (
              el.id===state.selectedConversation.id ? { ...el, attributes: { ...el.attributes, name: _this.state.contact.name } } : el
            ))

            setConversations( newConversations, state.selectedTab )

            document.getElementById("myDropdown").classList.toggle("show");
          }
          else{
            if(response.data.errors){
              _this.setState({all_errors_add_contact: response.data.errors})
            }
            if(response.data.message){
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
        toastr.error("There was an error");
      });
      // this.setState({disabled_add_contact: false})
  }

  render = () =>{
    const { is_loading, contact_list, confirmation_modal } = this.state
    return(
      <>
        <hr/>
        <div className="dropdown-replace-contact">
          <a onClick={ () => this._dropdown() } className="w-100 mb-1 btn btn-link">Assigned to another contact?</a>
          <div id="myDropdown" className="dropdown-content mb-5">
            <input type="text" placeholder="Search.." id="myInput" onChange={ (e) => this._findContact(e.target.value) } className="rounded-0"/>
            <div className="contact-list-assigned">
              { contact_list.length ?  ( <ContactList dataList={contact_list} confirmationToAssign={ this._confirmationToAssign } /> ) : '' }
            </div>
          </div>
        </div>

        {confirmation_modal && (<ConfirmationModal
          modalTitle="Assign to another contact"
          modalContent="Are you sure want to assign to this contact?"
          showConfirmModal={confirmation_modal}
          handleSubmit={() => this._submitAssignedToContact()}
          handleClose={() => this._closeModal()} />)}

      </>
    )
  }
}

const ContactList = ({dataList, confirmationToAssign}) => {
  var list = []
  dataList.map((data, index) => {
    list.push(
      <a onClick={ () => confirmationToAssign(data.attributes) }>
        {data.attributes.name}
        <br/>
        <small className="text-muted">{data.attributes.email}</small>
      </a>
    )
  })
  return list;
}

export default AssignContact;
