import React from "react";
import { Button } from "react-bootstrap";

class ConversationContacts extends React.Component{

  constructor(props){
    super(props)

    this.state = {
      selectedConversation: props.selectedConversation,
      conversation_contacts: props.selectedConversation.conversation_contacts,
      domain_url: props.domain_url,
      app_id: props.app_id
    }
  }

  render = () =>{
    const { domain_url, app_id, conversation_contacts } = this.state
    return(
      <>
        <hr/>
        <Contacts
          domainUrl={domain_url}
          appId={app_id}
          conversationContacts={conversation_contacts}
        />
      </>
    )
  }
}

const Contacts = ( {domainUrl, appId, conversationContacts} ) => {
  var list = [];
  conversationContacts.map((contact, index) => {
    list.push(
      <div
        className="text-center mr-2 mb-1"
        key={index}
      >
        <a className="btn-link text-dark" href={`${domainUrl}/apps/${appId}/contacts/${contact.id}`} >
          {contact.name} {contact.phone !== '' && `(${contact.phone})`}
        </a>
      </div>
    );
  });
  return list;
};



export default ConversationContacts;
