import React from "react";
import PhoneInput from 'react-phone-input-2';
import { Button, Modal, Form } from 'react-bootstrap';

const AddContact = ({errors, disabled, country, showModal, handleClose, handleSubmitContact, handleChangeState, tagSuggestions, onDelete, onAddition, tagRef}) => {
  return(
    <Modal className="contacts" show={showModal} onHide={handleClose} size={'lg'} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="contactForm.ControlInput1">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" isInvalid={errors.find(e => e.type === 'name') ? true : false} onChange={(value) => handleChangeState('name', value)} placeholder="Enter name" />
          {errors.find(e => e.type === 'name') && (
            <div className="errors">{errors.find(e => e.type === 'name').message}</div>
          )}
        </Form.Group>

        <Form.Group controlId="contactForm.ControlInput1">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" isInvalid={errors.find(e => e.type === 'email') ? true : false} onChange={(value) => handleChangeState('email', value)} placeholder="Enter email address" />
          {errors.find(e => e.type === 'email') && (
            <div className="errors">{errors.find(e => e.type === 'email').message}</div>
          )}
        </Form.Group>

        <Form.Group controlId="contactForm.ControlInput1">
          <Form.Label>Number</Form.Label>
          <PhoneInput
            inputProps={{
              required: true
            }}
            isValid={errors.find(e => e.type === 'phone') ? false : true}
            containerStyle={{display: "inline-flex", flex: 1}}
            country={country}
            placeholder={'Enter number'}
            onChange={phone => handleChangeState('phone', phone)}/>

            {errors.find(e => e.type === 'phone') && (
            <div className="errors">{errors.find(e => e.type === 'phone').message}</div>
          )}
        </Form.Group>

      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={disabled} variant="primary" onClick={handleSubmitContact}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddContact;
