import React, {  useState } from "react";
import axios from "axios";
import $ from 'jquery';
export function ContactTagFilter({tags, setTags, domain_url, app_id, setContactData, getContactList, setIsLoading, current_page}){

  const [suggestions,setSuggestions] = useState([])
  const [tagToggle, setTagToggle] = useState(false)
  const base_url = domain_url + "/apps/" + app_id;

  const searchTags = (value)=>{
    if (value.length < 2) return;
    const url = domain_url + '/tags'

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
          method: "GET",
          url: url,
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params:{
            name: value,
            app_id: app_id,
          }
        })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status == 'success') {
                let s = response.data.data;
                s = s.map((tag)=>tag.name)
                setSuggestions(s)

              }
              else{
                if(response.data.message){
                  toastr.error(response.data.message);
                }
              }
            }

          })
          .catch((error) => {
            toastr.error("There was an error");
          });




  }

  const onTagClick = (event) => {
    if (tags.includes(event.target.value)) return;

    let taglist = tags
    taglist = taglist.concat(event.target.value)
    setTags(taglist)
    document.getElementById("tag-search").value = ""
    setSuggestions([])
    filterContacts(taglist)
  }

  const onTagKeyDown = (event)=>{
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById("tag-suggestions").children[0].click()
    }
  }
  const filterContacts = (tags)=>{

    setIsLoading(true)
    const url = base_url + "/contacts" + "/filter_contacts"
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "POST",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params:{
        tags: tags,
        app_id: app_id,
        page: current_page,
      }
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {

            setContactData(response.data.data)

          }
          else{
            if(response.data.errors){
              setErrors(response.data.errors)
            }
            if(response.data.message){
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {

        toastr.error("There was an error");
      });

      setInterval(() => {
        setIsLoading(false)
      }, 1000);


  }
  const onTagCancel = (event) =>{


    if (!tags.includes(event.target.dataset.tag)) return;
    let taglist = tags
    taglist.pop(event.target.dataset.tag)
    setTags(taglist)
    if (tags){
      filterContacts(tags)
    }
    else {
      getContactList()
    }

  }
  const onFilterClick = ()=>{
    if (!tagToggle){
     setTagToggle(true)
    }
    else setTagToggle(false)
  }

  return(
    <div className="d-flex flex-column">
      <div className={`filter-icon ${tags.length > 0 ? "highlight" : ''}` }
      onClick={onFilterClick}></div>
      {tagToggle ?
      <div className="tag-search-container">
         <div id="tag-suggestions">
            {suggestions.map((suggestion, index)=><button className="tag-search" value={suggestion} onClick={onTagClick} key={index}>{suggestion}</button>)}
          </div>
          <input className="form-control mr-sm-2" id="tag-search" type="search" placeholder="Search tags" aria-label="Search" autoComplete="off" onChange={(e) => searchTags(e.target.value)} onKeyDown={onTagKeyDown} />
          <div className="mt-2 d-block">
            {tags.map((tag, index)=>
              <button key={index} className="tag-search d-flex align-items-center float-left mr-2">
                {tag}
                <div className="ml-2" data-tag={tag} onClick={onTagCancel}>
                  &times;
                </div>
              </button>)}

          </div>
      </div>
      : null}
    </div>
  )
}

