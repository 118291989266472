import React, { useContext } from "react";
import axios from "axios";
import DashboardContext from "../DashboardContext";
import sendIcon from "images/svgs/send.svg";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import _ from "lodash";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import OutsideClickHandler from "react-outside-click-handler";
import { MentionsInput, Mention } from 'react-mentions'

class ConversationSendForm extends React.Component {
  static contextType = DashboardContext;

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      errorFile: false,
      messageType: "default",
      uploadPercentage: 0,
      showSendButton: false,
      isLoading: true,
      isLoadingMessageContents: false,
      messageContents: [],
      selectedMessageContent: null,
      showEmoji: false,
      messageContent: "",
      appId: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoading: false,
      showSendButton: true,
    });
    this.getMessageFromParam();
  };

  handleClick(event) {
    event.preventDefault();
    let message =
      document.getElementById("conversation-textarea") !== null
        ? document.getElementById("conversation-textarea").value
        : null;
    const {
      file,
      messageType,
    } = this.state;

    // check if text message is not blank
    if (
      !this.checkIsEmptyString(message) ||
      file != null
    ) {
      let _this = this;
      const token = document.querySelector("[name=csrf-token]").content;
      const {
        state,
        addNewMessage,
        set_conversation_id_and_subscribe,
      } = this.context;
      let request_url = `${state.domain_url}/api/live_chat/v1/messages?platform_id=${state.platformId}&conversation_id=${state.selectedConversation.id}&external_id=${state.externalId}&user_name=${state.userName}&user_email=${state.userEmail}&source=${state.source}&customer_id=${state.customerId}`;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          if (percent < 100 && file !== null) {
            this.setState({ uploadPercentage: percent });
          }
        },
      };
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      if (file) {
        formData.append("message[file]", file, file.name);
      }
      if (this.props.quotedMessage) {
        formData.append(
          "message[quoted_message_id]",
          this.props.quotedMessage.id
        );
      }
      if (message) {
        formData.append("message[content]", message);
      }

      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .post(request_url, formData, config)
        .then((response) => {
          parent.postMessage(["novochatRemoveUnread"], "*");
          if (response.status == 200 || response.status == 201) {
            addNewMessage(response.data.data);
            set_conversation_id_and_subscribe(response.data.data.attributes.conversation_id);
            if (file != null) {
              this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                  this.setState({ uploadPercentage: 0 });
                }, 1000);
              });
            }
          }
        })
        .catch((error) => {
          console.log("error!", error);
        });

      if (message) {
        document.getElementById("conversation-textarea").value = "";
        this.setState({ messageContent: "", messageType: messageType });
      }

      // clear file upload
      _this.handleRemoveFile();
      this.props.handler(null);
    }
  }



  getMessageFromParam = () => {
    // Get the full URL
    let _url = new URL(window.location.href);
    let _url_params = new URLSearchParams(_url.search);

    // Get specific parameters
    let store_url = new URL(_url_params.get('page_visit_url'));
    let store_url_param = new URLSearchParams(store_url.search);
    let param_chat = store_url_param.get('chat');

    if(param_chat){
      this.setState({
        messageContent: param_chat
      });
    }
  }

  checkIsEmptyString = (text) => {
    return !text || /^\s*$/.test(text);
  };

  handleMessageChange = (e) => {
    this.setState({
      messageContent: e.target.value
    });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.handleClick(event);
    }
  };


  handlePaste = (event) => {
    if (event.clipboardData.files.length > 0) {
      let file = event.clipboardData.files[0];
      this.uploadFile(file);
    }
  };

  handleRemoveFile = () => {
    // clear file upload
    if (document.getElementById("message-file-attachment") != null) {
      document.getElementById("message-file-attachment").value = null;
    }
    this.setState({
      file: null,
      errorFile: false,
    });
  };

  handleChangeFile = (event) => {
    let file = event.target.files[0];
    // Validate file
    this.uploadFile(file);
  };

  uploadFile = (fileData) => {
    if (
      fileData &&
      fileData.size / 1024 / 1024 < 20 &&
      this.validateFileType(fileData.name)
    ) {
      // Update the state
      this.setState({ file: fileData, errorFile: false });
    } else {
      this.setState({ fileData: null, errorFile: true });
    }
  }

  validateFileType = (fileName) => {
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "gif" ||
      extFile == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  };

  setShowEmoji = (value) => {
    this.setState({ showEmoji: value });
  };

  insertEmoji = (emoji) => {
    let message = document.getElementById("conversation-textarea");
    let insertPos = message.selectionStart;
    let textAreaValue = message.value;
    let txtToAdd = emoji.native;

    this.setState({
      messageContent: textAreaValue.substring(0, insertPos) +
      txtToAdd +
      textAreaValue.substring(insertPos)
    });

    message.selectionStart = insertPos + txtToAdd.length;
    message.selectionEnd = insertPos + txtToAdd.length;
    message.focus();
    message.blur();
  };

  buttonText = () => {
    const locale = this.context.state.locale
    if (locale == 'zh_TW' || locale == 'zh-TW') {
      return "登入聊天"
    } else {
      return "Login to Chat"
    }
  }

  render() {
    const {
      showEmoji,
      file,
      errorFile,
      messageType,
      templates,
      mentionTemplates,
      isLoadingMessageContents,
      messageContents,
      showSendButton,
      showSendTemplateConfirmModal,
      selectedMessageContent,
      uploadPercentage,
      messageContent,
      confirmCreateTemplate,
      showAskCreateTemplate,
    } = this.state;

    const { state } = this.context;
    if (state.source == 'easystore-logout') {
      return (
        <>
          <div id="conversation-send-form">
            <Button
              variant="outline-secondary"
              className="btn-redirect"
              style={{background: state.primaryColor}}
              onClick={() => window.parent.location.href = `${ (new URL(state.pageVisitUrl)).origin}/account/login`}
            >
              { this.buttonText() }
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                className="icon glyph ml-2"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#FFF"
                  d="M21.66 12a2 2 0 01-1.14 1.81L5.87 20.75A2.08 2.08 0 015 21a2 2 0 01-1.82-2.82L5.46 13H11a1 1 0 000-2H5.46L3.18 5.87a2 2 0 012.68-2.62l14.65 6.94A2 2 0 0121.66 12z"
                ></path>
              </svg>
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div id="conversation-send-form">
            <div className="conversation-form-message">
              <>
                <MentionsInput
                  value={messageContent}
                  onChange={this.handleMessageChange}
                  className="send-message-area"
                  placeholder="Type a message"
                  id="conversation-textarea"
                  autoFocus
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  onPaste={(e) => this.handlePaste(e)}
                >
                  <Mention
                    markup="￿__id__"
                    appendSpaceOnAdd={true}
                    displayTransform={(id) => `${id}`}
                    trigger="/"
                  />
                </MentionsInput>
              </>
              <div id="conversation-send-form-bottom">
                <div id="file-attachement-detail">
                  {uploadPercentage > 0 && (
                    <ProgressBar className="w-100">
                      <ProgressBar
                        animated
                        now={uploadPercentage}
                        className="position-relative"
                      />
                    </ProgressBar>
                  )}
                  {file && !errorFile && uploadPercentage == 0 && (
                    <span className="file-detail-span">
                      <i className="fa fa-file" color="gray" />
                      <p>{file.name}</p>
                      <div className="remove-file-wrap">
                        <Button
                          variant="outline-secondary"
                          className="btn-remove-file"
                          onClick={() => this.handleRemoveFile()}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      </div>
                    </span>
                  )}
                  {errorFile && (
                    <span className="file-detail-span">
                      <p className="error-message-text">
                        File attachement only for image and pdf file (Size is
                        &#8804; 20MB).
                      </p>
                    </span>
                  )}
                </div>

                <div id="conversation-send-form-bottom-right">
                  {/* <OutsideClickHandler
                    onOutsideClick={() => this.setShowEmoji(false)}
                  >
                    <button
                      className="btn btn-no-border icon"
                      onClick={() =>
                        this.setShowEmoji(showEmoji ? false : true)
                      }
                    >
                      <i className="fa fa-smile" />
                    </button>
                    {showEmoji && (
                      <Picker
                        style={{
                          position: "absolute",
                          bottom: "70px",
                          right: "0px",
                          left: "0px",
                        }}
                        set={"twitter"}
                        showPreview={false}
                        showSkinTones={false}
                        onSelect={(emoji) => this.insertEmoji(emoji)}
                      />
                    )}
                  </OutsideClickHandler> */}
                  <div className="upload-attachment-btn-wrapper">
                    <label
                      className="btn btn-no-border icon mb-0"
                      style={{color: state.primaryColor}}
                      htmlFor="message-file-attachment"
                    >
                      <i className="fa fa-paperclip" />
                    </label>
                    <input
                      type="file"
                      className="btn btn-no-border"
                      id="message-file-attachment"
                      accept="application/pdf,image/*"
                      onChange={(event) => this.handleChangeFile(event)}
                      disabled={uploadPercentage > 0}
                    />
                  </div>
                  {/* <span data-tip={state.disabledSendButton == true ? 'Session Expired' : 'Send'} data-tip-disable={false} data-place={"top"} data-multiline={false}> */}

                  {showSendButton && (
                    <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      className="icon glyph"
                      id="conversation-send-btn-livechat"
                      viewBox="0 0 24 24"
                      onClick={(event) => this.handleClick(event)}
                      disabled={state.disabledSendButton || uploadPercentage > 0}
                    >
                      <path
                        fill={ state.primaryColor }
                        d="M21.66 12a2 2 0 01-1.14 1.81L5.87 20.75A2.08 2.08 0 015 21a2 2 0 01-1.82-2.82L5.46 13H11a1 1 0 000-2H5.46L3.18 5.87a2 2 0 012.68-2.62l14.65 6.94A2 2 0 0121.66 12z"
                      ></path>
                    </svg>
                    </>
                  )}
                  {/* </span> */}

                  {showAskCreateTemplate && !confirmCreateTemplate && (
                    <AskCreateTemplate
                      showAskCreateTemplate={showAskCreateTemplate}
                      cancelAskCreateTemplate={this.cancelAskCreateTemplate}
                      appId={state.app_id}
                      redirectTemplatePage={this.redirectTemplatePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default ConversationSendForm;
