import React, { useContext, useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import DashboardContext from "../dashboard/DashboardContext.js";

const ConversationsTabs = (props) => {
  const { state, setSelectedTab, setTotalCountsTab } = useContext(
    DashboardContext
  );

  useEffect(() => {
    setTotalCountsTab();
  }, []);

  const supStyle = {
    top: "-1em",
    right: "5px",
    position: "relative",
    fontSize: "100% !important",
    lineHeight: "0",
    verticalAlign: "baseline",
  };

  const badgeStyle = {
    fontSize: "9px !important",
    opacity: 0.8,
    position: "relative",
    bottom: "6px",
    padding: "0px 8px",
    left: "8px"
  };

  const tabs = ["Me", "Unassigned", "All"];

  if (state.selectedTab === "resolved") {
    tabs.push("Resolved");
  }
  const displayConversationTabs = tabs.map((category) => {
    const categoryLowercase = category.toLowerCase();
    const categoryCount = state.totalCountsTab[categoryLowercase];
    let categoryCountDisplay = "";
    if (
      categoryCount !== undefined &&
      categoryCount > 0 &&
      categoryLowercase != "resolved"
    ) {
      if (state.totalCountsTab[categoryLowercase] > 99) {
        categoryCountDisplay = "99+";
      } else {
        categoryCountDisplay = state.totalCountsTab[categoryLowercase];
      }
    }
    const isActive =
      categoryLowercase === state.selectedTab
        ? "conversations-tab nav-link active"
        : "conversations-tab nav-link";
    return (
      <li
        className="nav-item m-0"
        key={category}
        onClick={() => setSelectedTab(category.toLowerCase())}
      >
        <a className={isActive} href="#" onClick={(e) => e.preventDefault()}>
          {category}
          <sup style={supStyle}>
            <Badge variant="primary" style={badgeStyle}>
              {categoryCountDisplay}
            </Badge>
          </sup>
        </a>
      </li>
    );
  });

  return (
    <ul className="nav nav-fill nav-tabs border-bottom">{displayConversationTabs}</ul>
  );
};

export default ConversationsTabs;
