import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import lineIcon from "images/svgs/icons/line.svg";
import facebookIcon from "images/svgs/icons/facebook.svg";
import telegramIcon from "images/svgs/icons/telegram.svg";
import liveChatIcon from "images/svgs/icons/live_chat.svg";
import whatsappIcon from "images/svgs/icons/whatsapp.svg";
import instagramIcon from "images/svgs/icons/instagram.svg";
import chevronRight from "images/svgs/icons/chevron_right.svg";

export const ChannelList = (props) => {
  const { channels, primary_color, handler } = props;
  const channel_list = [];

  channels.map((data, index) => {
    let { service, name, external_link } = data.attributes
      ? data.attributes
      : data;
    let platformIcon = "";
    switch (service) {
      case "line":
        platformIcon = lineIcon;
        break;
      case "whatsapp":
        platformIcon = whatsappIcon;
        break;
      case "whatsapp_chatapi":
        platformIcon = whatsappIcon;
        break;
      case "whatsapp_business":
        platformIcon = whatsappIcon;
        break;
      case "facebook":
        platformIcon = facebookIcon;
        break;
      case "instagram":
        platformIcon = instagramIcon;
        break;
      case "telegram":
        platformIcon = telegramIcon;
        break;
      case "live_chat":
        platformIcon = liveChatIcon;
        break;
    }
    if (service === "live_chat") {
      channel_list.push(
        <li className="novochat-widget-list-item" key={index}>
          <a onClick = {handler} rel="noreferrer">
            <div className="widget-container">
              <img src={platformIcon} width={35} height={35} />
              <span className="text-platform">{name}</span>
              <img src={chevronRight} className="novochat-chevron-right" />
            </div>
          </a>
        </li>
      );
    } else {
      channel_list.push(
        <li className="novochat-widget-list-item" key={index}>
          <a href={external_link} target="_blank" rel="noreferrer">
            <div className="widget-container">
              <img src={platformIcon} width={35} height={35} />
              <span className="text-platform">{name}</span>
              <img src={chevronRight} className="novochat-chevron-right" />
            </div>
          </a>
        </li>
      );
    }
  });

  return channel_list;
};

export default ChannelList;
