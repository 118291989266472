import React, { useState, createRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import Axios from "axios";
import { Spinner } from "react-bootstrap";
import ReactTags from 'react-tag-autocomplete';
import _ from 'lodash';

const FilterTags = forwardRef(({ appId, broadcastId, domainUrl = '', handleSetContactData, searchText = '' }, ref) => {
  const [ tags, setTags ] = useState([]);
  const [ suggestions, setSuggestions ] = useState([]);
  const [ isLoadingTagSuggestions, setIsLoadingTagSuggestions ] = useState(false);
  const tagRef = createRef();

  useEffect(() => {
    ref.current = [];
  }, []);

  useEffect(() => {
    if(tags.length > 0) {
      async function filter() {
        _filterTags();
      }
      filter();
    }
  },
  [tags]);

  const _filterTags = () => {
    ref.current = tags.map(x => x.name);

    const url = `${domainUrl}/apps/${appId}/broadcasts/${broadcastId}/broadcast_recipients/contact_recipients`
    const token = document.querySelector("[name=csrf-token]").content;
    Axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    Axios({
      method: "POST",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data:{
        tags: tags.map(x => x.name),
        by_keyword: searchText
      }
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {
            handleSetContactData(response.data.data)

          }
          else{
            _handleError(response.data);
          }
        }
      })
      .catch((error) => {
        _handleError(error);
      });
  };

  const _handleError = (error = null) => {
    if(error && error.message) {
      toastr.error(error.message);
    }
    else if(error && error.response.message) {
      toastr.error(error.response.message);
    }
    else {
      toastr.error("There was an error");
    }
  };

  const _onDelete = _.debounce((i) => {
    if (i === -1) return false;
    // const tags = tags.slice(0)
    const removedTag = tags[i];
    let new_suggestions = [].concat(suggestions, removedTag);
    tags.splice(i, 1);
    setTags(tags);
    setSuggestions(new_suggestions);
    _filterTags();
  }, 300);

  const _onAddition = _.debounce((tag) => {
    tag.name = tag.name.toLowerCase()
    let tag_find = tags.find(t => t.name == tag.name)
    if(tag_find){
      tag = tag_find
    }
    else {
      setTags([...tags, tag]);
    }

    // remove from suggestions
    let index = suggestions.findIndex(t => t.name == tag.name);
    if(index > -1) {
      suggestions.splice(index, 1);
      setSuggestions(suggestions);
    }
  }, 300);

  const _onInput = _.debounce((query) => {
    if(query.length >= 2){

      if(!isLoadingTagSuggestions){
        setIsLoadingTagSuggestions(true)
      }

      // get tag suggestion
      const url = domainUrl+'/tags'
      const token = document.querySelector("[name=csrf-token]").content;
      Axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      Axios({
        method: "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params:{
          name: query,
          app_id: appId
        }
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == 'success') {
              let tag_suggestion_response = response.data.data;
              const suggestions_result = [].concat(tag_suggestion_response.filter(x => !tags.some(tag => x.name == tag.name)))
              if(suggestions_result) {
                setSuggestions(suggestions_result);
              }
            }
            else{
              if(response.data.message){
                toastr.error(response.data.message);
              }
            }
          }
         
          setIsLoadingTagSuggestions(false);
        })
        .catch((error) => {
          setIsLoadingTagSuggestions(false);
          _handleError(error);
        });
    }
  }, 300);

  if(!appId || !broadcastId) return null;
  return(
    <>
      <ReactTags
          ref={tagRef}
          tags={tags}
          suggestions={suggestions}
          allowNew={false}
          autoresize={false}
          delimiters={['Enter', 'Tab', ' ']}
          onDelete={_onDelete.bind(this)}
          onAddition={_onAddition.bind(this)}
          onInput={_onInput.bind(this)}
          allowBackspace={false} />

        {isLoadingTagSuggestions && (
          <div className="loading-tag-suggestion-wrap">
            <Spinner animation="grow" variant="secondary" size="sm" />
            <span className="text-loading-suggestions">Loading</span>
          </div>
        )}
    </>
  )
});

export default FilterTags;