import React, { useEffect, Component } from "react";
import dateFormat from "dateformat";
import IncomingMessage from "./messages/IncomingMessage.jsx";
import OutgoingMessage from "./messages/OutgoingMessage.jsx";
import LogMessage from "./messages/LogMessage.jsx";
import NoteMessage from "./messages/NoteMessage.jsx";
import ConversationSendForm from "./ConversationSendForm.js";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import DashboardContext from "./DashboardContext";
import { MSG_STATUS } from "../../constants/messages";
import UserAvatar from "./UserAvatar.jsx";
import AgentDropdown from "./AgentDropdown.jsx";
import ResolveConversationButton from "./ResolveConversationButton.jsx";
import ContactsPane from "../contacts/ContactsPane";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

class MessageArea extends Component {
  static contextType = DashboardContext;

  state = {
    hasMoreItems: false,
    current: null,
    next: null,
    prev: null,
    last: null,
    isLoading: false,
    toggleContactInfo: false,
    selectedAgentId: null,
    selectedMessage: null,
    isLoadingNewMessages: false,
    goToMessage: false
  };

  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
  }
  handler(message) {
    this.setState({
      selectedMessage: message,
    });
  }

  componentDidMount() {
    // Load context
    const { state } = this.context;
    const { isLoadingNewMessages } = this.state;
    let url = `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/messages`;
    if (state.messagesStartPage !== null) {
      url = url + `?message_id=${state.messageId}&page=${state.messagesStartPage}&prev_page=${state.messagesStartPage}`
    }

    this.setState({
      current: url,
      next: url,
      hasMoreItems: true,
    });
  }

  loadItems = () => {
    this.setState({ isLoading: true }, () => {});
    setTimeout(() => {
      // Load context
      const { setMessages, state } = this.context;

      if (this.state.current != this.state.last) {
        this.setState({ current: this.state.next });

        const currentScrollHeightFromBottom = $(".message-list")[0].scrollHeight - $(".message-list").scrollTop();
        axios.get(this.state.next).then((response) => {
          let next = response.data.links.next;
          let prev = response.data.links.prev;
          let last = response.data.links.last;

          if (state.selectedConversation.id !== '') {
            setMessages(
              response.data.data.reverse(),
              state.selectedConversation.id,
              currentScrollHeightFromBottom
            );
          }

          if (this.state.next == this.state.current) {
            $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
            if ((state.messageId !== null) && !this.state.goToMessage && state.messageId !== undefined) {
              setTimeout(() => {
                var elmnt = document.getElementById(`${state.messageId}`);
                elmnt.scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
                let text = $(`#${state.messageId}  > div > div > .text-content-message, #${state.messageId}  > div > div > div > .text-content-message`).text()
                $(`#${state.messageId} > div > div > .text-content-message, #${state.messageId}  > div > div > div > .text-content-message`).html(`<mark>${text}</mark>`);
                this.setState({ goToMessage: true });
              }, 1000);
            }
          }

          if (next != undefined || prev != undefined) {
            this.setState({
              hasMoreItems: true,
              next: next,
              prev: prev,
              last: last,
              isLoading: false,
            });
          }
        });
      } else {
        this.setState(
          {
            hasMoreItems: false,
          },
          () => {}
        );
      }
    }, 100);
  };

  toggleContactInfo = () => {
    this.setState({ toggleContactInfo: !this.state.toggleContactInfo });
  };

  setSelectedAgentId = (agentId) => {
    if (agentId) {
      this.setState({
        selectedAgentId: agentId,
      });
    }
  };

  handleScroll = () => {
    const { setMessages, state } = this.context;

    let setLoadNewMessages = $(".message-list")[0].scrollHeight-1738 < $(".message-list").scrollTop()

    if (setLoadNewMessages) {
      if (!this.state.isLoadingNewMessages) {
        this.loadNewMessages()

        this.setState({
          isLoadingNewMessages: true
        });
      }
    }
  }

  loadNewMessages = () => {
    const { setMessages, state } = this.context;
    const currentScrollHeightFromBottom = $(".message-list")[0].scrollHeight;
    if (this.state.prev) {
      axios.get(this.state.prev).then((response) => {
        let prev = response.data.links.prev;

        setTimeout(() => {
          this.setState({
            prev: prev,
            isLoadingNewMessages: false
          });
        }, 1000);

        setMessages(
          response.data.data.reverse(),
          state.selectedConversation.id,
          currentScrollHeightFromBottom,
          'bottom'
        );
      });
    }
  }

  render() {

    // Load React Context
    const { state } = this.context;
    const { selectedAgentId, prev } = this.state;

    // Adjust size of Area based on toggleContactInfo
    const adjustedSize = this.state.toggleContactInfo
      ? "col-xs-12 col-md-8 col-xl-6 p-0"
      : "col-xs-12 col-md-8 col-xl-9 p-0";

    const threeDotsListTrigger = (eventKey) => {
      if (eventKey == 'Sync') {
        confirm( "After syncing, the page will refresh. Proceed?");
        handleSync();
      } else if (eventKey === "Unread") {
        setAsUnread()
      }
    }

    function setAsUnread() {
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "PUT",
        url: `/apps/${state.app_id}/conversations/${state.selectedConversation.id}/set_conversation_as_unread`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          id: state.selectedConversation.id
        }
      }).then((response)=> {
        toastr.success("Mark as unread");
        setTimeout(function () {
          location.reload();
        }, 1000);
      })
    }

    function handleSync() {
      toastr.success("Syncing...");
      $.ajax({
        //define appId
        url: `/apps/${state.app_id}/sync/sync_conversations`,

        type: "POST",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: { chat_id: state.selectedConversation.id },
        success: function () {
          toastr.success("Conversation Synced!");
          setTimeout(function () {
            location.reload();
          }, 1000);
        },
        error: function (data) {
          toastr.error(
            "Oops! Something went wrong. Please try again later. Error: " +
              data.statusText
          );
        },
      });
    }
    let display_messages = [];
    state.messages.map((message_obj, i) => {
      let position = { first: false };
      let message = message_obj.attributes;

      // Set first message of group
      if (
        i == 0 ||
        message.status !== state.messages[i - 1].attributes.status ||
        message.name !== state.messages[i - 1].attributes.name ||
        message.agent_id !== state.messages[i - 1].attributes.agent_id
      ) {
        position.first = true;
      }

      if (message.status == MSG_STATUS.RECEIVED) {
        display_messages.push(
          <IncomingMessage
            key={i}
            message={message}
            position={position}
            handler={this.handler}
            toggleContactInfo={this.toggleContactInfo}
            locale={state.locale}
          />
        );
      } else if (message.message_type == "log") {
        display_messages.push(
          <LogMessage key={i} message={message} position={position} locale={state.locale} />
        );
      } else if (message.message_type == "note") {
        display_messages.push(
          <NoteMessage key={i} message={message} position={position} locale={state.locale} />
        );
      } else {
        display_messages.push(
          <OutgoingMessage
            key={i}
            message={message}
            position={position}
            handler={this.handler}
            primaryColor={state.primaryColor}
            locale={state.locale}
            replyText={
              this.state.selectedMessage
                ? this.state.selectedMessage.content
                : null
            }
          />
        );
      }
    });

    let displayAgentDropdown;
    let displayResolveButton;
    if (
      state.isOwner == true ||
      state.selectedConversation.assigned_agent_id == state.currentUserId
    ) {
      displayAgentDropdown = (
        <AgentDropdown selectedAgentId={selectedAgentId} />
      );
      displayResolveButton = <ResolveConversationButton />;
    }

    return (
      <>
        <div id="message-area" className={adjustedSize}>
          <div className="conversation-title shadow-sm">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex flex-row col-6 col-lg-6 float-left pl-0 align-items-center">
                <UserAvatar
                  name={state.selectedConversation.name || "Unknown"}
                  platformName={state.selectedConversation.platform_name}
                />
                <div className="conversation-info">
                  <h4
                    onClick={() => this.toggleContactInfo()}
                    className="contact-detail"
                  >
                    {state.selectedConversation.name || "Unknown"}
                  </h4>
                  <p className="chat-datetime">
                    Last seen at&nbsp;
                    {state.messages.length > 0
                      ? dateFormat(
                          state.messages[state.messages.length - 1][
                            "attributes"
                          ]["created_at"],
                          "mmm dS, h:MM TT"
                        )
                      : "long ago"}
                  </p>
                </div>
              </div>
              {
                <div className="col-6 col-lg-6 p-0 m-0 w-100">
                  <div className="d-flex align-items-center">
                    <div className="col-8 pl-0">{displayAgentDropdown}</div>
                    <div className="col-4 d-flex m-0 pl-2 pr-0 border-left">
                      <div className="col-9 px-0">{displayResolveButton}</div>

                      <div className="col-3 pr-0">
                          <Dropdown
                            className="z-index-1"
                            onSelect={threeDotsListTrigger}
                            style={{
                              position: "absolute",
                              right: "-18px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <Dropdown.Toggle
                              as={dropdownIcon}
                              id="dropdown-custom-components"
                            />

                            <Dropdown.Menu>
                              {state.selectedConversation.platform_service ==
                              "whatsapp_chatapi" ? (
                                <Dropdown.Item eventKey="Sync" >
                                  Sync Conversation
                                </Dropdown.Item>
                              ) : null}
                              <Dropdown.Item eventKey="Unread">
                                Mark as unread
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div
            className="message-list"
            onScroll={(ref) => (this.scrollParentRef = ref, this.handleScroll())}
          >
            <InfiniteScroll
              isReverse={true}
              pageStart={0}
              loadMore={this.loadItems}
              hasMore={this.state.hasMoreItems && !this.state.isLoading}
              threshold={50}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              useWindow={false}
            >
              {display_messages}
            </InfiniteScroll>
          </div>
          {this.state.selectedMessage ? (
            <div className="reply-message">
              <div className="reply-message-content">
                <div style={{ fontWeight: "700" }}>
                  {this.state.selectedMessage.agent_name
                    ? this.state.selectedMessage.agent_name
                    : this.state.selectedMessage.sender_name}
                </div>
                <div>{this.state.selectedMessage.content}</div>
              </div>
              <div
                onClick={() => this.handler(null)}
                className="cancel-btn"
              ></div>
            </div>
          ) : null}

          <ConversationSendForm
            key={state.selectedConversation.id}
            setSelectedAgentId={(agentId) => this.setSelectedAgentId(agentId)}
            quotedMessage={this.state.selectedMessage}
            handler={this.handler}
          />
        </div>

        {this.state.toggleContactInfo && <ContactsPane />}
      </>
    );
  }
}
const dropdownIcon = React.forwardRef(({ children, onClick }, ref) => (
  <div className="conversation-icon">
    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id="button-tooltip-2">More</Tooltip>}
    >
      <Button
        variant="light"
        className="btn btn-no-border icon"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <i className="fa fa-ellipsis-v" />
      </Button>
    </OverlayTrigger>
  </div>
));
export default MessageArea;
