import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReactTags from 'react-tag-autocomplete';

const TagsRecipient = (props) => {
  const [ actionUrl, setActionUrl ] = useState(null);
  const [ appId, setAppId ] = useState(null);
  const [ broadcastId, setBroadcastId ] = useState(null);
  const [ domainUrl, setDomainUrl ] = useState(null);
  const [ tags, setTags ] = useState([]);
  const [ suggestions, setSuggestions ] = useState([]);
  const [ isLoadingTagSuggestions, setIsLoadingTagSuggestions ] = useState(false);
  const reactTags = createRef();

  useEffect(() => {
    if(props) {
      setAppId(props.app_id)
      setDomainUrl(props.domain_url);
      setBroadcastId(props.broadcast_id);
      setActionUrl(props.action_url)
    }
  },
  [props]);

  const _handleError = (error = null) => {
    if(error && error.message) {
      toastr.error(error.message);
    }
    else if(error && error.response.message) {
      toastr.error(error.response.message);
    }
    else {
      toastr.error("There was an error");
    }
  };

  const _onDelete = (i) => {
    if (i === -1) return false;
    // const tags = tags.slice(0)
    const removedTag = tags[i];
    // delete tag
    const url = `${domainUrl}/apps/${appId}/broadcasts/${broadcastId}/${actionUrl}/remove_tag_to_recipients`
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "DELETE",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data:{
        tag:{
          tag_id: removedTag.id,
          app_id: appId
        }
      }
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {
            let new_suggestions = [].concat(suggestions, removedTag);
            tags.splice(i, 1);
            setTags(tags);
            setSuggestions(new_suggestions);
          }
          else{
            _handleError(response.data);
          }
        }
      })
      .catch((error) => {
        _handleError(error);
      });
  }

  const _onAddition = (tag) => {
    tag.name = tag.name.toLowerCase()
    let tag_find = tags.find(t => t.name == tag.name)
    if(tag_find){
      tag = tag_find
    }
    else{
      // upload tag
      let post_data = null;
      if(tag.id){
        post_data = {
          tag:{
            tag_id: tag.id,
            app_id: appId
          }
        }
      }
      else {
        post_data = {
          tag:{
            name: tag.name,
            app_id: appId
          }
        }
      }

      const url = `${domainUrl}/apps/${appId}/broadcasts/${broadcastId}/${actionUrl}/add_tag_to_recipients`
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "POST",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: post_data
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == 'success') {
              tag = response.data.data.data_success
              const tags_result = [].concat(tags.filter(x => x.name !== tag.name), tag)
              const suggestions_result = [].concat(suggestions.filter(x => x.name !== tag.name), tag);
              suggestions_result.splice(suggestions_result.findIndex(t => t.name === tag.name), 1)
              setTags(tags_result)
              setSuggestions(suggestions_result);
            }
            else{
              if(response.data.message){
                _handleError(response.data);
              }
            }
          }
        })
        .catch((error) => {
          _handleError(error);
        });
    }
  }

  const _onInput = (query) => {
    if(query.length >= 2){

      if(!isLoadingTagSuggestions){
        setIsLoadingTagSuggestions(true)
      }

      // get tag suggestion
      const url = domainUrl+'/tags'
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params:{
          name: query,
          app_id: appId
        }
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == 'success') {
              let tag_suggestion_response = response.data.data;
              const suggestions_result = [].concat(tag_suggestion_response.filter(x => !tags.some(tag => x.name == tag.name)))
              if(suggestions_result) {
                setSuggestions(suggestions_result);
              }
            }
            else{
              if(response.data.message){
                toastr.error(response.data.message);
              }
            }
          }
         
          setIsLoadingTagSuggestions(false);
        })
        .catch((error) => {
          setIsLoadingTagSuggestions(false);
          _handleError(error);
        });
    }
  }

  if(actionUrl && appId && domainUrl) {
    return(
      <>
        <ReactTags
          ref={reactTags}
          tags={tags}
          suggestions={suggestions}
          allowNew={true}
          autoresize={false}
          delimiters={['Enter', 'Tab', ' ']}
          onDelete={_onDelete.bind(this)}
          onAddition={_onAddition.bind(this)}
          onInput={_onInput.bind(this)}
          allowBackspace={false} />

        {isLoadingTagSuggestions && (
          <div className="loading-tag-suggestion-wrap">
            <Spinner animation="grow" variant="secondary" size="sm" />
            <span className="text-loading-suggestions">Loading</span>
          </div>
        )}
      </>
    )
  }
  else return null;
};

export default TagsRecipient;
