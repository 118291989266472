import React from "react";
import { CSSTransition } from "react-transition-group";
import Chat from "../dashboard/widget/Chat";

class ChatWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetName: this.props.platform_name,
      domainUrl: this.props.domain_url,
      externalId: this.props.external_id,
      conversationId: this.props.conversation_id,
      userName: this.props.user_name,
      userEmail: this.props.user_email,
      customerId: this.props.customer_id,
      integration_id: this.props.integration_id,
      platformId: this.props.platform_id,
      primaryColor: this.props.primary_color,
      locale: this.props.locale || 'en-US',
      pageVisitUrl: this.props.page_visit_url,
      source: this.props.source,
      displayContent: true,
    };
  }

  componentDidMount() {}

  render() {
    const {
      primaryColor,
      externalId,
      platformId,
      conversationId,
      userName,
      userEmail,
      customerId,
      source,
      domainUrl,
      widgetName,
      displayContent,
      locale,
      pageVisitUrl,
    } = this.state;

    return (
      <>
        <div
          id="widget-page-container"
          className="novochat-primary-color"
          style={{ background: primaryColor }}
        >
          <div className="novochat-widget-header">
            <div className="novochat-text-logo">{widgetName}</div>
          </div>
          <CSSTransition
            in={displayContent}
            timeout={2000}
            classNames="slide-up"
            unmountOnExit
          >
            <div className="novochat-widget-content">
              <div className="novochat-main-section novochat-current-section novochat-section">
                <Chat
                  conversationId={conversationId}
                  platformId={platformId}
                  domain_url={domainUrl}
                  primaryColor={primaryColor}
                  source={source}
                  externalId={externalId}
                  userName={userName}
                  userEmail={userEmail}
                  customerId={customerId}
                  locale={locale}
                  pageVisitUrl={pageVisitUrl}
                />
              </div>
            </div>
          </CSSTransition>
        </div>
      </>
    );
  }
}

export default ChatWidget;
