import React, { useState, useContext } from "react";
import DashboardContext from "../dashboard/DashboardContext.js";
import ConversationsList from "./ConversationsList.jsx";
import ConversationsTabs from "./ConversationsTabs.jsx";

const ConversationsArea = () => {
  // const [selectedTab, setSelectedTab] = useState("me");
  const dashboardContext = useContext(DashboardContext);
  const { state, setSelectedTab } = dashboardContext;
  return (
    <>
      <ConversationsTabs
        selectedTab={state.selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <ConversationsList
        key={state.selectedTab}
        fetchingConversations={state.fetchingConversations}
      />
    </>
  );
};

export default ConversationsArea;
