function postParams(appId, data) {
  $.ajax({
    url: `/apps/${appId}/events/search`,
    type: "POST",
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    data,
  });
}

$(window).on("load", function () {
  let searchEvent = document.getElementById("searchEvents");
  if (searchEvent) {
    document.getElementById("searchEvents").oninput = (event) => {
      appId = event.target.dataset.appid;

      debounce(() => {
        postParams(appId, {
          search: event.target.value
        });
      }, 500)();
    };
  }
});
