import React from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ReactTags from 'react-tag-autocomplete';

class TagsContact extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      base_url: this.props.base_url ? this.props.base_url : '/contacts',
      app_id: this.props.app_id,
      domain_url: this.props.domain_url,
      id: this.props.id,

      tags: [],
      suggestions: [],
      isLoadingTagSuggestions: false
    }

    this.reactTags = React.createRef()
  }

  componentDidMount = () => {
    const { tags, suggestions } = this.props;
    this.setState({
      tags,
      suggestions
    })
  }

  _onDelete (i) {
    if (i === -1) return false;
    const tags = this.state.tags.slice(0)
    const removedTag = this.state.tags[i];
    // delete tag
    let _this = this

    const {  base_url, domain_url, app_id, id } = this.state
    const url = domain_url+'/apps/'+app_id+base_url+'/'+'delete_tag/'+id
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "DELETE",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data:{
        contact:{
          tag: removedTag
        },
        app_id: app_id,
        contact_id: id
      }
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'success') {
            const suggestions = [].concat(this.state.suggestions, removedTag);
            tags.splice(i, 1)
            _this.setState({ tags, suggestions })
          }
          else{
            if(response.data.message){
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });


  }

  _onAddition (tag) {
    tag.name = tag.name.toLowerCase()
    const {tags, suggestions} = this.state;
    let tag_find = tags.find(t => t.name == tag.name)
    if(tag_find){
      tag = tag_find
    }
    else{
      // if(!tag.id){
        // upload tag
        let _this = this
        const {  base_url, domain_url, app_id, id } = this.state
        const url = domain_url+'/apps/'+app_id+base_url+'/'+'create_tag'
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          method: "POST",
          url: url,
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data:{
            contact:{
              tag: tag
            },
            app_id: app_id,
            contact_id: id,
            new_tag: !tag.id
          }
        })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status == 'success') {
                tag = response.data.data.data_success
                const tags_result = [].concat(tags.filter(x => x.name !== tag.name), tag)
                const suggestions_result = [].concat(suggestions.filter(x => x.name !== tag.name), tag);
                suggestions_result.splice(suggestions_result.findIndex(t => t.name === tag.name), 1)
                _this.setState({
                  tags: tags_result,
                  suggestions: suggestions_result
                })
              }
              else{
                if(response.data.message){
                  toastr.error(response.data.message);
                }
              }
            }
          })
          .catch((error) => {
            toastr.error("There was an error");
          });
      // }
    }

  }

  _onInput = (query) => {
    if(query.length >= 2){

      if(!this.state.isLoadingTagSuggestions){
        this.setState({
          isLoadingTagSuggestions: true
        })
      }

      // get tag suggestion
      let _this = this
      const {  tags, domain_url, app_id } = this.state
      const url = domain_url+'/tags'
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params:{
          name: query,
          app_id: app_id // add app_id
        }
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == 'success') {
              let tag_suggestion_response = response.data.data;
              const suggestions_result = [].concat(tag_suggestion_response.filter(x => !tags.some(tag => x.name == tag.name)))

              _this.setState({
                suggestions: suggestions_result
              })
            }
            else{
              if(response.data.message){
                toastr.error(response.data.message);
              }
            }
          }
          _this.setState({
            isLoadingTagSuggestions: false
          })
        })
        .catch((error) => {
          toastr.error("There was an error");
        });
    }
  }

  render = () =>{
    return(
      <div className="contacts tags-wrap">
        <hr />

        <div className="tags-content">

          <div className="title-wrap">
            <div className="title">
              <h5 className="bold-text title-text">Tags</h5>
              <i className="fa fa-tags tags-icon" />
            </div>

            <div className="info">
                Add tags to this contact
            </div>
          </div>

          <div className="tags-item-wrap">
            <ReactTags
              ref={this.reactTags}
              tags={this.state.tags}
              suggestions={this.state.suggestions}
              allowNew={true}
              autoresize={false}
              delimiters={['Enter', 'Tab', ' ']}
              onDelete={this._onDelete.bind(this)}
              onAddition={this._onAddition.bind(this)}
              onInput={this._onInput.bind(this)}
              allowBackspace={false} />

            {this.state.isLoadingTagSuggestions && (
              <div className="loading-tag-suggestion-wrap">
                <Spinner animation="grow" variant="secondary" size="sm" />
                <span className="text-loading-suggestions">Loading</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default TagsContact;
