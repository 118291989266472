import React from 'react';

const CheckBoxItemSVG = ({ active = false, color = '#14207a' }) => {
  if(active === true) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M10 16.25C11.6576 16.25 13.2473 15.5915 14.4194 14.4194C15.5915 13.2473 16.25 11.6576 16.25 10C16.25 8.3424 15.5915 6.75269 14.4194 5.58058C13.2473 4.40848 11.6576 3.75 10 3.75C8.3424 3.75 6.75269 4.40848 5.58058 5.58058C4.40848 6.75269 3.75 8.3424 3.75 10C3.75 11.6576 4.40848 13.2473 5.58058 14.4194C6.75269 15.5915 8.3424 16.25 10 16.25Z" 
          fill={color}/>
        <path 
          d="M10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM1.25 10C1.25 8.85093 1.47633 7.71312 1.91605 6.65152C2.35578 5.58992 3.0003 4.62533 3.81282 3.81282C4.62533 3.0003 5.58992 2.35578 6.65152 1.91605C7.71312 1.47633 8.85093 1.25 10 1.25C11.1491 1.25 12.2869 1.47633 13.3485 1.91605C14.4101 2.35578 15.3747 3.0003 16.1872 3.81282C16.9997 4.62533 17.6442 5.58992 18.0839 6.65152C18.5237 7.71312 18.75 8.85093 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10Z" 
          fill={color} />
      </svg>
    );
  }

  return(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM1.25 10C1.25 8.85093 1.47633 7.71312 1.91605 6.65152C2.35578 5.58992 3.0003 4.62533 3.81282 3.81282C4.62533 3.0003 5.58992 2.35578 6.65152 1.91605C7.71312 1.47633 8.85093 1.25 10 1.25C11.1491 1.25 12.2869 1.47633 13.3485 1.91605C14.4101 2.35578 15.3747 3.0003 16.1872 3.81282C16.9997 4.62533 17.6442 5.58992 18.0839 6.65152C18.5237 7.71312 18.75 8.85093 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10Z" 
        fill="#161B7F" 
        fillOpacity="0.2"/>
    </svg>
  );
};

export default CheckBoxItemSVG;
