import React, { useState, useEffect } from 'react';
import ToggleSwitch from './ToggleSwitch';

function MultiChannelActive ({ active = false }) {
  const [ checked, setChecked ] = useState(active);
  const [ value, setValue ] = useState(false);

  useEffect(() => {
    changeActive(checked);
  },
  [checked]);


  function changeActive (value) {
    setChecked(value);
    document.getElementById('widget_setting_active').value = `${value || false}` ;
    if (value == true) setValue(true);
    else setValue(false);
  }

  return (
    <ToggleSwitch
      id="switch-active-multichannel"
      checked={checked}
      small={true}
      value={value}
      onChange={active => changeActive(active)} />
  )
}

export default MultiChannelActive;