// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("@rails/ujs").start();

import jquery from "jquery";
require("@nathanvda/cocoon");
require("@joeattardi/emoji-button");
window.$ = window.jquery = jquery;
import { TIMEZONE_MAPPING } from "../constants/timezone_mapping";
window.browser_timezone =
  TIMEZONE_MAPPING[Intl.DateTimeFormat().resolvedOptions().timeZone];

require("toastr");
require("uuid");
// import moment from "moment";
// global.moment = moment;
// require("tempusdominus-bootstrap-4");
require("./intl_tel_input/intlTelInput.min");
require("./intl_tel_input/utils");
require("react-phone-input-2");
require("react-tag-autocomplete");
require("react-grid-gallery");
require("react-modal-image");
require("react-tooltip");
require("react-skeleton-loader");
require("react-transition-group");
require("react-outside-click-handler");
require("dropzone");
const flatpickr = require("flatpickr");
import 'flatpickr/dist/flatpickr.css';





const dateFormat = require("dateformat");
window.dateFormat = dateFormat;
// For REACT
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "bootstrap";
import "dropzone/dist/dropzone.css";
import "./src/react_tags.scss";
import "./src/application.scss";
import "./src/widget.scss";
import "./src/contacts.scss";
import "./src/widget_settings.scss";
import "./src/onboarding.scss";
import "./src/platform_settings.scss";
import "./src/billings.scss";
import "./apps.js";
import "./broadcasts.js";
import "./events.js";
import "./onboarding.js";
import "./widget_settings.js";
import "./widget.js";
import "./template.js";
import "@fortawesome/fontawesome-free/js/all";
import "react-phone-input-2/lib/style.css";
import "./settings.js";
import "./src/integrations.scss";
import "./src/events.scss";
import "./src/template_messages.scss";
import "./src/broadcast.scss";
import "./src/platforms.scss";
import "./src/empty.scss";
import "./src/single_button.scss";
import "./src/chat_button.scss";
import "./src/container.scss";
import "./src/toggleswitch.scss";
import "./src/manual_automations.scss";
import "./src/purchase_numbers.scss";
import "./src/workspaces.scss";
import { EmojiButton } from '@joeattardi/emoji-button';
import { createConsumer } from "@rails/actioncable";
window.EmojiButton = EmojiButton;

var path = require('path');

// For Toastr notifications
import toastr from "toastr";
import { object } from "prop-types";
toastr.options = {
  closeButton: true,
  debug: false,
  positionClass: "toast-bottom-left",
  onclick: null,
  showDuration: "300",
  hideDuration: "1500",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
window.toastr = toastr;

const debounce = (func, delay = 275) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};
window.debounce = debounce;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("./serviceworker-companion");
