import React from "react";
import axios from "axios";
import ChannelList from "./ChannelList";
import Chat from "../dashboard/widget/Chat";
import { CSSTransition } from "react-transition-group";

class Widget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widget_name: "",
      logo: "",
      channels: [],
      domain_url: this.props.domain_url,
      widget_url: this.props.widget_url,
      app_id: this.props.app_id,
      externalId: this.props.external_id,
      conversationId: this.props.conversation_id,
      userName: this.props.user_name,
      userEmail: this.props.user_email,
      customerId: this.props.customer_id,
      integration_id: this.props.integration_id,
      platformId: this.props.platform_id,
      locale: this.props.locale || 'en-US',
      primary_color: "",
      company_logo: "",
      title: "",
      display_content: false,
      show_chat: false
    };

    this.handler = this.handler.bind(this)
  }

  componentDidMount() {
    this.loadWidget();
  }

  loadWidget() {
    let _this = this;
    const { app_id, domain_url, widget_url, integration_id } = this.state;
    const url = `${domain_url}/v1/widgets/${app_id}/${widget_url}?integration_id=${integration_id}`;

    axios({
      method: "GET",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == "success") {
            if (widget_url == "load_widget.json") {
              var data = response.data.data;
              var { channels, design_settings, name, company_logo, title } =
                response.data.data.data.attributes;
              var state = {
                channels: channels.data,
                primary_color: design_settings.primary_color,
                widget_name: name,
                company_logo: company_logo,
                title: title,
                display_content: true,
              };
              _this._setState(state);
            } else if (widget_url == "load_default.json") {
              var channels = response.data.data.channels;
              var name = response.data.data.name;
              _this._setState({ channels: channels });

              var state = {
                channels: channels,
                company_logo: null,
                widget_name: "Novochat",
                display_content: true,
                primary_color: "#0033cc",
              };
              _this._setState(state);
            }
            // Show scroll after 2 seconds
            setTimeout(function () {
              document.getElementById("widget-page-container").style.overflowY =
                "unset";
            }, 2000);
          } else {
            if (response.data.errors) {
              toastr.error(response.data.message);
            }
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error("There was an error");
      });
  }

  handler = () => {
    this.setState({show_chat: true})
  }

  _setState = (state) => {
    this.setState(state);
  };

  render() {

    const {
      primary_color,
      externalId,
      platformId,
      conversationId,
      userName,
      userEmail,
      customerId,
      domain_url,
      channels,
      widget_name,
      company_logo,
      title,
      display_content,
      show_chat,
      locale
    } = this.state;

    function CompanyLogo(props) {
      const companyLogo = props.companyLogo;
      if (companyLogo != null) {
        return <img src={companyLogo} className="novochat-image-logo" />;
      }
      return <div className="novochat-text-logo"> {widget_name} </div>;
    }

    return (
      <>
        <div
          id="widget-page-container"
          className="novochat-primary-color"
          style={{ background: primary_color }}
        >
          <div className="novochat-widget-header">
            <CompanyLogo companyLogo={company_logo} />
          </div>
          <CSSTransition
            in={display_content}
            timeout={2000}
            classNames="slide-up"
            unmountOnExit
          >
            <div className="novochat-widget-content">
              <div className="novochat-main-section novochat-current-section novochat-section">
                {
                  show_chat &&
                  <Chat conversationId={conversationId} platformId={platformId} domain_url={domain_url} primaryColor={primary_color} externalId={externalId} userName={userName} userEmail={userEmail} customerId={customerId} locale={locale}/>
                }
                {
                  !show_chat &&
                  <>
                    <div className="novochat-widget-title"> {title} </div>
                    <ul className="novochat-widget-list">
                      <ChannelList
                        channels={channels}
                        primary_color={primary_color}
                        handler={this.handler}
                      />

                    </ul>
                  </>
                }
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="novochat-widget-footer" style={{ display: show_chat ? 'none' : 'block' }}>
          <a href="https://novochat.co" target="_blank">
            💬 &nbsp;Get your own button
          </a>
        </div>
      </>
    );
  }
}

export default Widget;
