import React from "react";
import { Button, Modal, Form } from 'react-bootstrap';

const ConfirmationModal = ({showConfirmModal, handleClose, handleSubmit, modalTitle, modalContent}) => {
  return(
    <Modal show={showConfirmModal} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
