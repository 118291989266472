import React, { useState, useContext } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Button, Dropdown } from "react-bootstrap";
import SearchConversation from "./SearchConversation.js";
import SendMessage from "./SendMessage";
import DashboardContext from "../dashboard/DashboardContext.js";
import axios from "axios";
import ChannelStatus from "./ChannelStatus";
import { useEffect } from "react";
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ConversationButtons = () => {
  const [showSearchConversation, setShowSearchConversation] = useState(false);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const dashboardContext = useContext(DashboardContext);
  const { state, setSelectedTab, filterPlatforms, setFilterPlatfom } = dashboardContext;
  const [showChannelStatus, setShowChannelStatus] = useState(false);
  const [ channels, setChannels ] = useState([]);

  useEffect(() => {
    setChannels(state.platforms)
  },
  []);

  useEffect(() => {
    if (state.directMessage) {
      setShowSendMessage(true);
    }
  }, [state.directMessage]);

  const changeModalSearchConversation = () => {
    setShowSearchConversation(!showSearchConversation);
  };

  const changeModalSendMessage = () => {
    setShowSendMessage(!showSendMessage);
  };

  const markConversationsAsRead = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm("Are you sure you want to mark all conversations as read?")
    ) {
      const url = `${state.domain_url}/apps/${state.app_id}/conversations/mark_as_read`;
      const token = document.querySelector("[name=csrf-token").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "PUT",
        url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {},
      })
        .then((response) => {
          toastr.success("All conversations marked as read");
          setTimeout(() => {
            toastr.success("Reloading page...");
          }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
        .catch((error) => {
          if (error.response) {
            toastr.error("An error has occurred");
          }
        });
    }
  };


  const assignAllUnsignedToMe = () => {
    const token = document.querySelector("[name=csrf-token").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url: `${state.domain_url}/apps/${state.app_id}/conversations/assign_all`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {},
    })
      .then((response) => {
        toastr.success("All unssigned conversations assigned to you");
        setTimeout(() => {
          toastr.success("Reloading page...");
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error("An error has occurred");
        }
      });
  }

  const resolveAllAssigned = () => {
    const token = document.querySelector("[name=csrf-token").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url: `${state.domain_url}/apps/${state.app_id}/conversations/resolve_all`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {},
    })
      .then((response) => {
        toastr.success("All unssigned conversations assigned to you");
        setTimeout(() => {
          toastr.success("Reloading page...");
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error("An error has occurred");
        }
      });
  }

  const dropdownIcon = React.forwardRef(({ children, onClick }, ref) => (
    <div className="conversation-icon">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">More</Tooltip>}>
        <button
          className="btn btn-no-border icon"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i className="fa fa-ellipsis-v" />
        </button>
      </OverlayTrigger>
    </div>
  ));

  const triggerMethod = (eventKey) => {
    if (eventKey === "search") {
      changeModalSearchConversation();
    } else if (eventKey === "read") {
      markConversationsAsRead();
    } else if (eventKey === "resolved") {
      setSelectedTab("resolved");
    } else if (eventKey === "status") {
      setShowChannelStatus(true);
    } else if (eventKey === "assign_all") {
      assignAllUnsignedToMe();
    } else if (eventKey === "resolve_all") {
      resolveAllAssigned();
    }
  };

  const dropdownFilterIcon = React.forwardRef(({ _children, onClick }, ref) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">Filter</Tooltip>}>
      <button
        className="btn btn-no-border icon"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <i className="fa fa-filter" />
      </button>
    </OverlayTrigger>
  ));

  const filterTriggerMethod = (eventKey) => {
    setFilterPlatfom(eventKey);
  };

  const filterCheckboxChange = (checked_id) => {
    if (checked_id) {
      let checkboxes = document.getElementsByClassName('platform-filter');
      for(let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].removeAttribute('checked');
      }

      // this.state.filterPlatforms.map((id) => {

      // });
      let checkbox = document.getElementById(`platform-filter-id-${checked_id}`);
      if (checkbox) {
        checkbox.setAttribute('checked', 'checked');
      }
    }
  };

  return (
    <>
      <div className="conversation-icon d-flex">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Message</Tooltip>}>
          <button
            className="btn btn-no-border icon"
            onClick={() => changeModalSendMessage()}
          >
            <i className="fa fa-edit" />
          </button>
        </OverlayTrigger>

        <Dropdown onSelect={filterTriggerMethod}>
          <Dropdown.Toggle as={dropdownFilterIcon} id="dropdown-filter-conversations" />
          <Dropdown.Menu style={{ maxHeight: '270px', overflow: 'hidden auto' }}>
            <Dropdown.ItemText className="px-2">
              <div className="semi-bold">
                Select Channels
              </div>
            </Dropdown.ItemText>

            { channels.length > 0 && channels.map((data) => (
              <Dropdown.Item className="px-2" eventKey={data.value} key={data.value}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="platform-filter"
                    id={`platform-filter-id-${data.value}`}
                    checked={state.filterPlatforms.findIndex(x => x === data.value) > -1}
                    onChange={() => filterCheckboxChange(data.value)} />

                  { data.service === 'line' && (
                    <img src={SvgLineIconPath} alt={data.service} width={15} />
                  )}
                  { data.service === 'telegram' && (
                    <img src={SvgTelegramIconPath} alt={data.service} width={15} />
                  )}
                  { data.service === 'live_chat' && (
                    <img src={SvgLiveChatIconPath} alt={data.service} width={15} />
                  )}
                  { data.service === 'facebook' && (
                    <img src={SvgFacebookIconPath} alt={data.service} width={15} />
                  )}
                  { data.service === 'instagram' && (
                    <img src={SvgInstagramIconPath} alt={data.service} width={15} />
                  )}
                  { (data.service === 'whatsapp' || data.service === 'whatsapp_chatapi' || data.service === 'whatsapp_business') && (
                    <img src={SvgWhatsappIconPath} alt={data.service} width={15} />
                  )}
                  <div className="mb-1 ml-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={data.name}>
                    { data.label }
                  </div>
                </div>
              </Dropdown.Item>
            )) }
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Dropdown onSelect={triggerMethod}>
        <Dropdown.Toggle as={dropdownIcon} id="dropdown-custom-components" />

        <Dropdown.Menu>
          <Dropdown.Item eventKey="search">Search Conversation</Dropdown.Item>
          <Dropdown.Item eventKey="read">Mark all as read</Dropdown.Item>
          <Dropdown.Item eventKey="resolved"> Show Resolved Conversations </Dropdown.Item>
          <Dropdown.Item eventKey="status"> Channel Status </Dropdown.Item>
          <Dropdown.Item eventKey="assign_all"> Assign all to me </Dropdown.Item>
          <Dropdown.Item eventKey="resolve_all"> Resolve all assigned </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {showSearchConversation && (
        <SearchConversation
          app_id={state.app_id}
          domain_url={state.domain_url}
          showModal={showSearchConversation}
          changeModalSearchConversation={changeModalSearchConversation}
        />
      )}
      {showSendMessage && (
        <SendMessage
          showModal={showSendMessage}
          changeModalSendMessage={changeModalSendMessage}
        />
      )}
      {showChannelStatus && (<ChannelStatus
        showChannelStatus={showChannelStatus}
        handleSubmit={() => setShowChannelStatus(false) }
        handleClose={() => setShowChannelStatus(false) } />)}
    </>
  );
};

export default ConversationButtons;
