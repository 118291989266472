import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { createConsumer } from "@rails/actioncable";
import MessageArea from "./MessageArea";
import { DashboardProvider } from "../DashboardContext";
import NovochatRing from "audios/novochat_ring2.mp3";
import { v4 as uuidv4 } from 'uuid';


class Chat extends React.Component {
  /*
   ** Lifecycle Methods Only
   */

  constructor(props) {
    super(props);
    // Setup ActionCable
    const cableAddress = `${this.props.domain_url}/cable`;
    this.cable = createConsumer(cableAddress);

    // Setup Channel
    this.liveChatChannel = {
      channel: "LiveChatChannel",
    };
    this.state = {
      primaryColor: this.props.primaryColor,
      locale: this.props.locale || 'en-US',
      pageVisitUrl: this.props.pageVisitUrl,
      source: this.props.source || '',
      notifSound: true,
      domain_url: this.props.domain_url,
      selectedConversation: {name: 'annoymous', id: this.props.conversationId || ''},
      externalId: this.props.externalId || uuidv4(),
      userName: this.props.userName || '',
      userEmail: this.props.userEmail || '',
      customerId: this.props.customerId || '',
      platformId: this.props.platformId,
      messages: [],
      directConversation: this.props.directConversation || null,
      disabledSendButton: false,
      directMessage: false,
      socket: false,
    };
  }

  componentDidMount() {
    // check url params for direct message
    this.checkUrlParams();
    if (this.state.selectedConversation.id) {
      this.subscribe_to_socket();
    }
  }

  set_conversation_id_and_subscribe = (conversation_id) => {
    parent.postMessage(["novochatStoreConversationId", conversation_id, 14], "*");
    if (conversation_id) {
      this.setState({
        selectedConversation: {name: 'annoymous', id: conversation_id},
      });
      this.subscribe_to_socket();
    }
  }

  subscribe_to_socket = () => {
    if (!this.state.socket) {
      this.setState({
        socket: true,
      });
      // Subscribe to convo list channel
      this.liveChatChannel = this.cable.subscriptions.create(
        {
          channel: "LiveChatChannel",
          conversation_id: this.state.selectedConversation.id
        },
        {
          disconnected() {
            console.log("disconecting");
          },
          received: (data) => {
            // Incoming Message Data
            if (data.message != undefined) {
              this.setMessagesForConversation(data.message);
              if (data.message && JSON.parse(data.message).data.attributes.status != 'received') {
                this.playNotificationSound();
                parent.postMessage(["novochatAddUnread"], "*");
              }
            }

            if (data.updateMessage != undefined) {
              this.updateMessagesForConversation(data.updateMessage);
            }

            // Send notfication for error
            if (data.error_msg != undefined) {
              toastr.error(data.error_msg);
            }
          },
        }
      );
    }
  }

  checkUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const directMessage = urlParams.get('direct_message');
    this.setState({
      directMessage: directMessage
    })
  }

  playNotificationSound = () => {
    let audio = new Audio(NovochatRing);
    if (this.state.notifSound) {
      audio.play();
    }
  };

  // Message receive from Ajax
  setMessages = (
    messages,
    conversation_id,
    currentScrollHeightFromBottom = 0,
    scrollTopBottom = "top"
  ) => {
    if (this.state.selectedConversation.id === conversation_id) {
      const newMessages =
        scrollTopBottom === "bottom"
          ? [...this.state.messages, ...messages]
          : [...messages, ...this.state.messages];
      const uniqueMessages = newMessages.filter(
        (item, index) => newMessages.indexOf(item) === index
      );

      this.setState(
        {
          messages: uniqueMessages,
        },
        () => {
          if (scrollTopBottom !== "bottom") {
            setTimeout(() => {
              let elmnt = document.getElementById(`${messages[messages.length - 1].id}`);
              elmnt.scrollIntoView({
                behavior: "auto",
              });
            }, 500);
          }
        }
      );
    }
  };

  addNewMessage = (message) => {
    const currentMessages = [...this.state.messages, message];
    let uniqueMessages = [];
    uniqueMessages = currentMessages.filter(
      (m, index) => currentMessages.findIndex((a) => a.id === m.id) === index
    );
    this.setState({
      messages: uniqueMessages,
    });
    if ($(".message-list")[0]) {
      $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
    }
  };


  // Message received from ActionCable
  setMessagesForConversation = (data) => {
    const messageObj = JSON.parse(data).data;
    const newMessages = [...this.state.messages, messageObj];
    const uniqueMessages = newMessages.filter(
      (e, i) => newMessages.findIndex((a) => a["id"] === e["id"]) === i
    );
    if (
      this.state.selectedConversation != null &&
      this.state.selectedConversation.id ==
        messageObj.attributes.conversation_id
    ) {
      this.setState(
        {
          messages: uniqueMessages,
        },
        () => {
          $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
        }
      );
    }
  };

  updateMessagesForConversation = (data) => {
    const messageObj = JSON.parse(data).data;
    const newMessages = this.state.messages.map((m) => {
      if (m.id == messageObj.id) {
        return messageObj;
      }
      return m;
    });
    if (
      this.state.selectedConversation != null &&
      this.state.selectedConversation.id ==
        messageObj.attributes.conversation_id
    ) {
      this.setState({
        messages: newMessages,
      });
    }
  };

  render() {
    const {
      state,
      setMessages,
      addNewMessage,
      set_conversation_id_and_subscribe,
    } = this;

    let messageArea;
    messageArea = <MessageArea key={"8e19defc-82ff-43ca-8c2d-783c5b0314c6"} />

    return (
      <>
        <DashboardProvider
          value={{
            state,
            setMessages,
            addNewMessage,
            set_conversation_id_and_subscribe
          }}
        >
          {messageArea}
        </DashboardProvider>
      </>
    );
  }
}

Chat.propTypes = {
  domain_url: PropTypes.string,
};

export default Chat
