import React from "react";


export const PendingMessage = () => {
  return (
    <div id="dashboard" className="container p-20">
      <div className="flex row p-20" style={{height: "100vh"}}>
        <div className="col-12 align-self-center text-center">
          
          <h4>Waiting for messages</h4>
          <p>More Instructions in Helpdesk</p>
        </div>
      </div>
    </div>
  );
};

export default PendingMessage;