import React from "react";
import dateFormat from "dateformat";
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import DashboardContext from "../dashboard/DashboardContext";

class Conversation extends React.Component {
  static contextType = DashboardContext;

  constructor(props) {
    super(props);
  }

  render() {
    // Load React Context
    const { state, setConversation } = this.context;

    let conversation = this.props.conversation;
    let activeClass = "";

    if (
      state.selectedConversation != null &&
      conversation.id == state.selectedConversation.id
    ) {
      activeClass = "active_chat";
    } else if (conversation.resolved) {
      activeClass = "conversation-resolved";
    }

    let displayChannelIcon;
    if (conversation.platform_service == "telegram") {
      displayChannelIcon = (
        <img
          src={SvgTelegramIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    } else if (conversation.platform_service == "line") {
      displayChannelIcon = (
        <img
          src={SvgLineIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    } else if (conversation.platform_service == "live_chat") {
      displayChannelIcon = (
        <img
          src={SvgLiveChatIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    } else if (conversation.platform_service == "facebook") {
      displayChannelIcon = (
        <img
          src={SvgFacebookIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    } else if (conversation.platform_service == "instagram") {
      displayChannelIcon = (
        <img
          src={SvgInstagramIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    } else if (
      conversation.platform_service == "whatsapp" ||
      conversation.platform_service == "whatsapp_chatapi" ||
      conversation.platform_service == "whatsapp_business"
    ) {
      displayChannelIcon = (
        <img
          src={SvgWhatsappIconPath}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      );
    }

    let unreadIcon;
    if (conversation.has_unread == true && !conversation.resolved) {
      unreadIcon = (
        <div className="conversation-status"></div>
      );
    } else {
      unreadIcon = (
        <div className="conversation-status" style={{ background: "transparent" }}></div>
      );
    }

    let checkSelectedConversation = () => {
      return (
        state.selectedConversation == undefined ||
        state.selectedConversation == null ||
        this.props.conversation.id !== state.selectedConversation.id
      );
    };

    if (state.directConversation != null && checkSelectedConversation()) {
      setConversation(this.props.conversation);
    }

    return (
      <div
        className={activeClass}
        onClick={() => {
          if (checkSelectedConversation()) {
            setConversation(this.props.conversation);
          }
        }}
      >
        <div className="conversation-item row-fluid">
          {unreadIcon}
          <div className="conversation-info col-12">
            <div className="conversation-platform">
              {conversation.platform_name}
            </div>
            <h5>
              {displayChannelIcon}
              {this.props.conversation.name}
              <span className="chat-datetime">
                {this.props.conversation.last_message_at && dateFormat(
                  this.props.conversation.last_message_at,
                  "mmm dS, h:MM TT"
                )}
              </span>
            </h5>
            {this.props.conversation.last_message_content && (
              <p>{this.props.conversation.last_message_content}</p>
            )}
            {!this.props.conversation.last_message_content && (
              <span>
                <i className="fa fa-file" />
                <p>File</p>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Conversation;
