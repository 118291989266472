import React, { useEffect, useContext, useReducer, useState } from "react";
import Ripple from "../shared/LoadingAnimation";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import Conversation from "./Conversation.js";
import DashboardContext from "../dashboard/DashboardContext.js";
import Skeleton from "react-skeleton-loader";
import _ from "lodash";

const ConversationsList = (props) => {
  const { state, resetConversations, setConversations, handleIsChangedFilterPlatforms } =
    useContext(DashboardContext);
  const { fetchingConversations } = props;
  const [ url, setUrl ] = useState(null);

  useEffect(() => {
    setUrl(setCurrentURL());
  },
  []);

  function setCurrentURL () {
    let url = `${state.domain_url}/apps/${state.app_id}/conversations?tab=${state.selectedTab}`;
    if (state.directConversation != null) {
      url += `&by_id=${state.directConversation}`;
    }

    if (state.filterPlatforms.length > 0) {
      url += `&by_platform_ids=${state.filterPlatforms.join(",")}`;
    }
    return url;
  }

  useEffect(() => {
    if (url) {
      callTheAPI();
    }
  },
  [url]);

  const initialState = {
    hasMoreItems: true,
    current: url,
    next: url,
    last: null,
    isLoading: false,
  };
  const reducer = (oldState, newState) => {
    return { ...oldState, ...newState };
  };
  const [listState, setListState] = useReducer(reducer, initialState);

  const loadItems = _.debounce(async () => {
    setListState({
      current: listState.next || url,
      isLoading: true,
    });
    setUrl(listState.next || url);
  }, 200);


  useEffect(() => {
    if (state.isChangedFilterPlatforms) {
      setListState({
        next: null
      });

      setUrl(setCurrentURL())

      handleIsChangedFilterPlatforms(false);
    }
  },
  [state.isChangedFilterPlatforms])
  

  useEffect(() => {
    // Load context
    if (fetchingConversations) {
      resetConversations();
      loadItems();
    }
  }, [fetchingConversations]);

  const setConversationList = async (list, tab) => {
    setConversations(list, tab);
  };

  const changeListState = async (next, last, hasMoreItems, current) => {
    setListState({
      current,
      next,
      last,
      hasMoreItems: hasMoreItems,
      isLoading: false,
    });
  };

  function callTheAPI() {
    axios({
      method: "GET",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(async (response) => {
      const { next, last } = response.data.links;
      // setConversations(response.data.data, state.selectedTab);
      await setConversationList(response.data.data, state.selectedTab);
      // setListState({
      //   next,
      //   last,
      //   hasMoreItems: next !== undefined && listState.current !== last,
      //   isLoading: false,
      // });

      await changeListState(
        next,
        last,
        next !== undefined && listState.current !== last,
        null
      );
    });
  }

  if (state.fetchingConversations) {
    const skeletonConversations = [];

    for (let i = 0; i < 3; i++) {
      skeletonConversations.push(
        <div className="conversation-skeleton-loading" key={i}>
          <div className="conversation-item row-fluid">
            <div className="conversation-info col-12">
              <div className="loading-skeleton">
                <span className="icon-skeleton">
                  <Skeleton
                    cir
                    height="24px"
                    width="24px"
                    borderRadius="12px"
                    color="#dedede"
                    widthRandomness={0}
                    heightRandomness={0}
                  />
                </span>
                <span className="name-skeleton">
                  <Skeleton
                    width="70px"
                    height="10px"
                    color="#dedede"
                    borderRadius="1em"
                    widthRandomness={0}
                    heightRandomness={0}
                  />
                </span>
                <span name="date-time-skeleton">
                  <Skeleton
                    width="90px"
                    height="10px"
                    color="#dedede"
                    borderRadius="1em"
                    widthRandomness={0}
                    heightRandomness={0}
                  />
                </span>
              </div>
              <span className="last-time-message-skeleton">
                <Skeleton
                  width="180px"
                  height="10px"
                  color="#dedede"
                  borderRadius="1em"
                  widthRandomness={0}
                  heightRandomness={0}
                />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return skeletonConversations;
  }

  let displayConversations = <div />;

  if (!state.fetchingConversations) {
    if (state.conversations.length !== 0) {
      displayConversations = state.conversations.map((conversation) => {
        return (
          <Conversation
            conversation={conversation.attributes}
            key={conversation.attributes.id}
          />
        );
      });
    } else {
      return (
        <div className="no-conversations">
          <p>No Conversations yet</p>
        </div>
      );
    }
  }

  const loader = (
    <div className="loader text-center mt-3" key={0}>
      <Ripple
        color="#0033cc"
        style={{
          textAlign: "center",
          margin: "0 auto",
          width: "75px",
          height: "75px",
          display: "inline-block",
        }}
      />
    </div>
  );

  const endMessage = (
    <div className="mt-3 text-center component-padding">
      <small
        className="mt-3 sm-text font-italic"
      >
        No more messages
      </small>
    </div>
  );

  async function conversationsListStyle() {
    const tabbedNav = document.getElementsByClassName("nav")[0];
    const workspaceName = document.getElementById("workspace-name");
    const navbar = document.getElementById("navbar");
    if (
      tabbedNav !== undefined &&
      tabbedNav !== null &&
      workspaceName !== undefined &&
      workspaceName !== null &&
      navbar !== undefined &&
      navbar !== null
    ) {
      const totalHeightOffset =
        tabbedNav.clientHeight +
        workspaceName.clientHeight +
        navbar.clientHeight;
      return {
        height: `calc(100vh - ${totalHeightOffset}px) !important`,
      };
    }
    return {};
  }

  return (
    <div
      id="conversations-list"
      className="conversations-list"
      style={conversationsListStyle()}
    >
      <InfiniteScroll
        dataLength={state.conversations.length}
        next={loadItems}
        hasMore={listState.hasMoreItems}
        loader={loader}
        endMessage={endMessage}
        scrollableTarget="conversations-list"
      >
        {displayConversations}
      </InfiniteScroll>
    </div>
  );
};

export default ConversationsList;
