import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import DashboardContext from "./DashboardContext";

export const AgentDropdown = (props) => {
  const dashboardContext = useContext(DashboardContext);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState();
  const [selectedAgentId, setSelectedAgentId] = useState();
  const { state, removeFromConversations } = dashboardContext;

  useEffect(() => {
    axios({
      method: "GET",
      url: `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/agents`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.data != null && response.data.agents != null) {
        const agentsArray = JSON.parse(response.data.agents).data;
        const extractAgents = agentsArray.map((agent) => {
          return agent.attributes;
        });
        setAgents(extractAgents);
        setSelectedAgentId(response.data.selected_agent_id);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedAgent(agents.find((agent) => agent.value === selectedAgentId));
    agents.unshift({ value: null, label: "Unassigned" });
  }, [selectedAgentId]);

  useEffect(() => {
    setSelectedAgentId(props.selectedAgentId);
  }, [props.selectedAgentId]);

  const handleSelectAgent = (selectedOption) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url: `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/assign_agent?user_id=${selectedOption.value}`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.status === 204) {
        if (
          (state.selectedTab === "unassigned" &&
            selectedOption.value != null) ||
          (state.selectedTab === "me" &&
            selectedOption.value !== state.currentUserId)
        ) {
          removeFromConversations(state.selectedConversation.id);
        }
        setSelectedAgent(selectedOption);
      }
    });
  };

  return (
    <Select
      value={selectedAgent}
      options={agents}
      placeholder="Select Agent"
      onChange={handleSelectAgent}
    />
  );
};

export default AgentDropdown;
