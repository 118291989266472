import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { createConsumer } from "@rails/actioncable";
import ConversationsArea from "../conversations/ConversationsArea.jsx";
import MessageArea from "./MessageArea.js";
import NoMessage from "./NoMessage.jsx";
import PendingMessage from "./PendingMessage.jsx";
import EmptyConversation from "../shared/EmptyData.jsx";
import SearchBar from "./SearchBar.jsx";
import { DashboardProvider } from "./DashboardContext";
import axios from "axios";
import NovochatRing from "audios/novochat_ring2.mp3";
import { MSG_STATUS } from "../../constants/messages";
import ConversationButtons from "../conversations/ConversationButtons";
import { filter } from "lodash";
import ReactTooltip from "react-tooltip";

class Dashboard extends React.Component {
  state = {
    messageId: this.props.messageId,
    searchText: this.props.searchText,
    messagesStartPage: this.props.messagesStartPage,
    currentUserId: this.props.currentUserId,
    isOwner: this.props.isOwner,
    app_id: this.props.app_id,
    appName: this.props.appName,
    notifSound: this.props.notifSound,
    domain_url: this.props.domain_url,
    platforms: this.props.platforms,
    conversations: [],
    selectedConversation: null,
    messages: [],
    selectedTab: this.props.directTab || "me",
    directConversation: this.props.directConversation || null,
    totalCountsTab: { me: 0, all: 0, unassigned: 0, resolved: 0 },
    loadTotalCountsInSetMesssages: false,
    tabs: {
      me: "me",
      all: "all",
      unassigned: "unassigned",
      resolved: "resolved",
    },
    disabledSendButton: false,
    fetchingConversations: true,
    filterPlatforms: [],
    isChangedFilterPlatforms: false,
    directMessage: false
  };

  /*
   ** Lifecycle Methods Only
   */

  constructor(props) {
    super(props);

    // Setup ActionCable
    const cableAddress = `${this.props.domain_url}/cable`;
    this.cable = createConsumer(cableAddress);

    // Setup Channel
    this.conversationListChannel = {
      channel: "ConversationListChannel",
      app_id: this.props.app_id,
    };
  }

  componentDidMount() {
    // check url params for direct message
    this.checkUrlParams();
    // Subscribe to convo list channel
    this.conversationListChannel = this.cable.subscriptions.create(
      this.conversationListChannel,
      {
        disconnected() {
          console.log("disconecting");
          toastr.error("Oops! Error occured. Please refresh the page.");
        },
        received: (data) => {
          // Incoming Conversation Data
          if (
            data.conversation != undefined &&
            (this.state.directConversation == null ||
              this.state.directConversation === data.conversation_id)
          ) {
            this.setIncomingConversations(data.conversation);
            // Mark message as read
            if (
              this.state.selectedConversation != null &&
              this.state.selectedConversation.id === data.conversation_id
            ) {
              this.conversationListChannel.send({
                activeConversationId: this.state.selectedConversation.id,
                userId: this.state.currentUserId,
              });
            }
          }
          // Incoming Message Data
          if (data.message != undefined) {
            this.setMessagesForConversation(data.message);
          }

          if (data.updateMessage != undefined) {
            this.updateMessagesForConversation(data.updateMessage);
          }

          // Send notfication for error
          if (data.error_msg != undefined) {
            toastr.error(data.error_msg);
          }
        },
      }
    );
  }

  checkUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const directMessage = urlParams.get('direct_message');
    this.setState({
      directMessage: directMessage
    })
  }

  setConversation = (selectedConversation) => {
    if (
      this.state.selectedConversation == null ||
      this.state.selectedConversation != selectedConversation.id
    ) {
      selectedConversation.has_unread = false;
      this.setState({
        selectedConversation: selectedConversation,
        messages: [],
        loadTotalCountsInSetMesssages: false,
      });
    }
  };

  setAssignContact = (selectedConversation) => {
    this.setState({
      selectedConversation: selectedConversation,
      conversations: [],
    });
  };

  setConversations = (conversations, selectedTab) => {
    if (this.state.selectedTab === selectedTab) {
      this.setState({
        conversations: [...this.state.conversations, ...conversations],
        fetchingConversations: false,
      });
    }
  };

  resetConversations = () => {
    this.setState({
      conversations: [],
    });
  };

  setFetchingConversations = (value) => {
    this.setState({
      fetchingConversations: value,
    });
  };

  setIncomingConversations = (data) => {
    let incoming_conversation = JSON.parse(data).data;
    if (
      document.hidden &&
      incoming_conversation.attributes.status == "received" &&
      (incoming_conversation.attributes.assigned_agent_id ===
        this.state.currentUserId ||
        incoming_conversation.attributes.assigned_agent_id == null)
    ) {
      this.playNotificationSound();
    }
    let oldConversations = this.state.conversations;
    let newConversations = oldConversations.filter((convo) => {
      return convo.attributes.id != incoming_conversation.attributes.id;
    });
    // Check if conversation is in the correct tab
    const { selectedTab, tabs, filterPlatforms } = this.state;
    const conversation_status = this.getConversationStatus(
      incoming_conversation
    );
    incoming_conversation = this.setIncomingConversationReadStatus(
      incoming_conversation
    );
    // check if the conversation is same as in filter channels -> if exists
    if (
      (conversation_status == selectedTab || selectedTab == tabs.all) &&
      (filterPlatforms.length <= 0 ||
        filterPlatforms.findIndex(
          (x) => x === incoming_conversation.attributes.platform_id
        ) > -1)
    ) {
      // add new conversation
      newConversations.unshift(incoming_conversation);
      this.setState({
        conversations: newConversations,
      });
    }
    if (incoming_conversation.attributes.has_unread == true) {
      this.setTotalCountsTab();
    }
  };

  playNotificationSound = () => {
    let audio = new Audio(NovochatRing);
    if (this.state.notifSound) {
      audio.play();
    }
    document.title = "New message";
    setInterval(() => {
      document.title = "Novochat";
    }, 5000);
  };

  getConversationStatus = (conversation) => {
    const { currentUserId, tabs } = this.state;
    let conversation_status = tabs.all;
    if (
      conversation.attributes.assigned_agent_id == currentUserId &&
      conversation.attributes.conversation_status == "active"
    ) {
      conversation_status = tabs.me;
    } else if (conversation.attributes.conversation_status == "unassigned") {
      conversation_status = tabs.unassigned;
    } else if (conversation.attributes.conversation_status == "resolved") {
      conversation_status = tabs.resolved;
    }
    return conversation_status;
  };

  setIncomingConversationReadStatus = (incoming_conversation) => {
    const { selectedConversation } = this.state;
    if (selectedConversation != null) {
      if (selectedConversation.id != incoming_conversation.attributes.id) {
        incoming_conversation.attributes.has_unread = true;
      } else {
        // set conversation as read
        if (this.setConversationAsRead(incoming_conversation.attributes.id)) {
          incoming_conversation.attributes.has_unread = false;
        }
      }
    } else {
      incoming_conversation.attributes.has_unread = true;
    }
    return incoming_conversation;
  };

  setConversationAsRead = (conversation_id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url: `${this.state.domain_url}/apps/${this.state.app_id}/conversations/${conversation_id}/set_conversation_as_read`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      return response.status == 204 || response.status == 200;
    });
    return false;
  };

  // Message receive from Ajax
  setMessages = (
    messages,
    conversation_id,
    currentScrollHeightFromBottom = 0,
    scrollTopBottom = "top"
  ) => {
    if (this.state.selectedConversation.id === conversation_id) {
      const newMessages =
        scrollTopBottom === "bottom"
          ? [...this.state.messages, ...messages]
          : [...messages, ...this.state.messages];
      const uniqueMessages = newMessages.filter(
        (item, index) => newMessages.indexOf(item) === index
      );

      const sessionExpired = this.isSessionExpired(uniqueMessages);
      this.setState(
        {
          messages: uniqueMessages,
        },
        () => {
          if (!this.state.loadTotalCountsInSetMesssages) {
            this.setTotalCountsTab();
            this.setState({
              loadTotalCountsInSetMesssages: true,
              disabledSendButton: sessionExpired,
            });
          }

          if (scrollTopBottom !== "bottom") {
            setTimeout(() => {
              let elmnt = document.getElementById(`${messages[messages.length - 1].id}`);
              elmnt.scrollIntoView({
                behavior: "auto",
              });
            }, 500);
          }
        }
      );
    }
  };

  isSessionExpired = (messages) => {
    const { selectedConversation } = this.state;
    if (
      !(
        selectedConversation &&
        ["facebook", "whatsapp"].includes(selectedConversation.platform_service)
      )
    ) {
      return false;
    }
    let messages_reverse = messages.reverse();
    const last_incoming_message = messages_reverse.find(
      (m) => m.attributes.status == "received"
    );
    if (last_incoming_message == undefined) {
      return true;
    }
    messages = messages_reverse.reverse();
    const created_dt =
      new Date(last_incoming_message.attributes.created_at).getTime() +
      60 * 60 * 24 * 1000; // plus 24hr
    const current_dt = new Date().getTime(); // current time
    return current_dt > created_dt;
  };

  addNewMessage = (message) => {
    const currentMessages = [...this.state.messages, message];
    // remove duplicates of the first index
    currentMessages.splice(
      currentMessages.findIndex((a) => a.id === message.id),
      1
    );
    let uniqueMessages = [];
    uniqueMessages = currentMessages.filter(
      (m, index) => currentMessages.findIndex((a) => a.id === m.id) === index
    );
    const new_message = uniqueMessages[uniqueMessages.length - 1];
    if (
      new_message != undefined &&
      new_message.attributes.status == "received"
    ) {
      this.setTotalCountsTab();
    }
    this.setState({
      messages: uniqueMessages,
    });
    if ($(".message-list")[0]) {
      $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
    }
  };

  updateConversations = (conversation) => {
    let oldConversations = this.state.conversations;
    let newConversations = oldConversations.filter((convo) => {
      return convo.attributes.id != conversation.attributes.id;
    });
    newConversations.unshift(conversation);
    this.setState({
      conversations: newConversations,
    });
  };

  removeFromConversations = (conversation_id) => {
    let oldConversations = this.state.conversations;
    const conversation_removed_index = oldConversations.findIndex(
      (c) => c.attributes.id === conversation_id
    );
    if (conversation_removed_index >= 0) {
      oldConversations.splice(conversation_removed_index, 1);
      this.setState({
        conversations: oldConversations,
      });
    }
  };

  // Message received from ActionCable
  setMessagesForConversation = (data) => {
    const messageObj = JSON.parse(data).data;
    const newMessages = [...this.state.messages, messageObj];
    const uniqueMessages = newMessages.filter(
      (e, i) => newMessages.findIndex((a) => a["id"] === e["id"]) === i
    );
    if (
      this.state.selectedConversation != null &&
      this.state.selectedConversation.id ==
        messageObj.attributes.conversation_id
    ) {
      this.setState(
        {
          messages: uniqueMessages,
        },
        () => {
          $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
        }
      );
    }
  };

  updateMessagesForConversation = (data) => {
    const messageObj = JSON.parse(data).data;
    const newMessages = this.state.messages.map((m) => {
      if (m.id == messageObj.id) {
        return messageObj;
      }
      return m;
    });
    if (
      this.state.selectedConversation != null &&
      this.state.selectedConversation.id ==
        messageObj.attributes.conversation_id
    ) {
      this.setState({
        messages: newMessages,
      });
    }
  };

  setSelectedTab = (tab) => {
    if (this.state.selectedTab != tab) {
      this.setState({
        selectedTab: tab,
        fetchingConversations: true,
        directConversation: null,
      });
    }
  };

  setTotalCountsTab = () => {
    axios({
      method: "GET",
      url: `${this.state.domain_url}/apps/${this.state.app_id}/conversations/total_counts`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      this.setState({
        totalCountsTab: response.data,
      });
    });
  };

  goToChannels = () => {
    window.location.href = `${this.state.domain_url}/apps/${this.state.app_id}/platforms`;
  };

  // set filter platform ids
  setFilterPlatfom = (new_platform_id) => {
    if (new_platform_id) {
      let { filterPlatforms } = this.state;

      if (filterPlatforms.findIndex((x) => x === new_platform_id) > -1) {
        filterPlatforms = filterPlatforms.filter((x) => x != new_platform_id);
      } else {
        filterPlatforms = [...filterPlatforms, new_platform_id];
      }
      this.setState(
        {
          filterPlatforms: filterPlatforms,
          isChangedFilterPlatforms: true,
        },
        () => {
          this.setState({
            fetchingConversations: true,
          });
        }
      );
    }
  };

  handleIsChangedFilterPlatforms = (value = null) => {
    if (value != null) {
      this.setState({
        isChangedFilterPlatforms: value,
      });
    } else {
      this.setState({
        isChangedFilterPlatforms: !this.state.isChangedFilterPlatforms,
      });
    }
  };

  render() {
    const {
      state,
      setConversation,
      setConversations,
      setAssignContact,
      resetConversations,
      setMessages,
      addNewMessage,
      updateConversations,
      setSelectedTab,
      setTotalCountsTab,
      removeFromConversations,
      setFilterPlatfom,
      handleIsChangedFilterPlatforms,
    } = this;

    let messageArea;
    if (this.state.selectedConversation != null) {
      messageArea = <MessageArea key={this.state.selectedConversation.id} />;
    } else if (
      this.state.selectedConversation == null &&
      this.props.platforms.length < 1
    ) {
      messageArea = (
        <EmptyConversation
          title="Connect with your users!"
          subtitle="Connecting a channel lets you chat with your leads and customers in NovoChat’s Inbox"
          handleOnClick={this.goToChannels}
          buttonText="Connect Channel Now"
        />
      );
    } else {
      messageArea = <PendingMessage />;
    }

    return (
      <>
        <DashboardProvider
          value={{
            state,
            setConversation,
            setConversations,
            setAssignContact,
            resetConversations,
            setMessages,
            addNewMessage,
            updateConversations,
            setSelectedTab,
            setTotalCountsTab,
            removeFromConversations,
            setFilterPlatfom,
            handleIsChangedFilterPlatforms,
          }}
        >
          <div id="dashboard" className="container-fluid p-0">
            <div className="d-flex flex-row">
              <div className="col-xs-12 col-md-4 col-xl-3 px-0 border-right">
                <div id="workspace-name" className="px-3">
                  <h4 className="inbox-title mt-2">
                    {this.state.appName} Inbox
                  </h4>
                  <ConversationButtons />
                </div>
                <ConversationsArea />
              </div>
              {messageArea}
            </div>
            <ReactTooltip />
          </div>
        </DashboardProvider>
      </>
    );
  }
}

Dashboard.propTypes = {
  externalId: PropTypes.string,
  conversations: PropTypes.array,
  app_id: PropTypes.string,
  domain_url: PropTypes.string,
};

export default Dashboard;
