import React from 'react';
import CheckBoxSVG from './CheckBoxSVG';

const StepTwo = ({ 
  active = false,
  imageLogo = null,
  imageURL,
  companyName,
  buttonText,
  callToAction,
  greetingMessage,
  position = 'right',
  display = 'everywhere',
  handleChangeInput,
  isSubmit,
  showHeader = true,
  showLogoInput = false,
  file = null,
  removeFile = false,
  showBorderBottom = true
}) => {
  return(
    <div className={`section-item ${!showBorderBottom && 'no-border-bottom'}`}>
      
      <div className="header">
        { showHeader && (
          <div className="icon-checkbox">
            <CheckBoxSVG active={active} />
          </div>
        )}
        <div className="header-items">
          <div className={`item title ${ active ? 'active' : '' }`}>
            Step 2
          </div>

          <div className={`item subtitle ${ active ? 'active' : '' }`}>
            Customize your widget
          </div>
        </div>
      </div>

      <div className="content-data flex-column">

        <div className="col-10 col-sm-12 p-0 content-fill">
          <div className="form-group row">
            <label 
              htmlFor="company-logo" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Company logo</label>
            <div className="col-sm-8">
              { showLogoInput === false && (
                <input 
                  type="text" 
                  className="text-input" 
                  id="company-logo" 
                  placeholder="Image URL for logo"
                  disabled={!active || isSubmit}
                  defaultValue={imageURL}
                  onChange={(e) => handleChangeInput('image_url', e.target.value)} />
              )}

              { imageLogo && imageLogo !== '' && !removeFile && (
                <div className="d-block mx-auto">
                  <img src={imageLogo} className={`img-responsive image-circle small mb-3 ${active ? 'active' : 'disabled'}`} />
                  <button className="btn btn-no-border" type={'button'} title="Remove" onClick={() => handleChangeInput('remove_file', true)}>x</button>
                </div>
              )}
              
              { showLogoInput === true && (<div className="input-file-wrap">
                <div className="d-flex flex-row align-items-center">
                  <input
                    type="file"
                    id="file"
                    className="btn-file-input"
                    disabled={!active}
                    defaultValue={file}
                    onChange={(e) => handleChangeInput('file', e.target.files[0])} />

                  <label className={`btn mb-0 py-2 px-3 label-file btn-image ${!active ? 'disabled' : ''}`} htmlFor="file" id="label-file">Upload Image File</label>
                </div>
              </div>)}
            </div>
          </div>

          <div className="form-group row">
            <label 
              htmlFor="company-name" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Company name</label>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="text-input" 
                id="company-name" 
                placeholder="Your company name"
                disabled={!active || isSubmit}
                defaultValue={companyName}
                required={true}
                onChange={(e) => handleChangeInput('company_name', e.target.value)} />
            </div>
          </div>

          <div className="form-group row">
            <label 
              htmlFor="button-text" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Button text</label>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="text-input" 
                id="button-text" 
                placeholder="Enter button text"
                disabled={!active || isSubmit}
                defaultValue={buttonText}
                required={true}
                onChange={(e) => handleChangeInput('button_text', e.target.value)} />
            </div>
          </div>

          <div className="form-group row">
            <label 
              htmlFor="call-to-action" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Call to action</label>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="text-input" 
                id="call-to-action" 
                placeholder="Enter call to action"
                disabled={!active || isSubmit}
                defaultValue={callToAction}
                required={true}
                onChange={(e) => handleChangeInput('call_to_action', e.target.value)} />
            </div>
          </div>

          <div className="form-group row">
            <label 
              htmlFor="call-to-action" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Greeting message</label>
            <div className="col-sm-8">
              <textarea
                className="text-input"
                placeholder="Enter greeting message"
                rows={7}
                disabled={!active || isSubmit}
                defaultValue={greetingMessage}
                required={true}
                onChange={(e) => handleChangeInput('greeting_message', e.target.value)}></textarea>
            </div>
          </div>

          <div className="form-group row">
            <label 
              htmlFor="call-to-action" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Position</label>
            <div className="col-sm-8 checkbox-responsive mt-2">
              <div className="form-check form-check-inline">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  id="position-left" 
                  value="left"
                  name="position"
                  disabled={!active || isSubmit}
                  checked={position === 'left'}
                  onClick={(e) => handleChangeInput('position', e.target.value)} />
                <label className={`form-check-label font-weight-normal mb-0 ${ active ? 'active' : '' }`} htmlFor="position-left">Left</label>
              </div>
              <div className="form-check form-check-inline ml-lg-5 ml-md-5">
                <input 
                  className="form-check-input" 
                  type="radio"  
                  id="position-right" 
                  value="right"
                  name="position"
                  disabled={!active || isSubmit}
                  checked={position === 'right'}
                  onClick={(e) => handleChangeInput('position', e.target.value)} />
                <label className={`form-check-label font-weight-normal mb-0 ${ active ? 'active' : '' }`} htmlFor="position-right">Right</label>
              </div>
            </div>
          </div>
          
          <div className="form-group row">
            <label 
              htmlFor="call-to-action" 
              className={`col-sm-4 col-form-label semi-bold ${ active ? 'active' : '' }`}>Display</label>
            <div className="col-sm-8 checkbox-responsive mt-2">
              <div className="form-check form-check-inline mr-lg-4 mr-md-3 mr-sm-2 mt-md-2">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  id="display-everywhere" 
                  value="everywhere"
                  name="display"
                  disabled={!active || isSubmit}
                  checked={display === 'everywhere'}
                  onClick={(e) => handleChangeInput('display', e.target.value)} />
                <label className={`form-check-label font-weight-normal mb-0 ${ active ? 'active' : '' }`} htmlFor="display-everywhere">Everywhere</label>
              </div>
              <div className="form-check form-check-inline mr-lg-4 mr-md-3 mr-sm-2 mt-md-2">
                <input 
                  className="form-check-input" 
                  type="radio"  
                  id="display-desktop" 
                  value="desktop"
                  name="display"
                  disabled={!active || isSubmit}
                  checked={display === 'desktop'}
                  onClick={(e) => handleChangeInput('display', e.target.value)} />
                <label className={`form-check-label font-weight-normal mb-0 ${ active ? 'active' : '' }`} htmlFor="display-desktop">Desktop</label>
              </div>
              <div className="form-check form-check-inline mt-md-2">
                <input 
                  className="form-check-input" 
                  type="radio"  
                  id="display-mobile" 
                  value="mobile"
                  name="display"
                  disabled={!active || isSubmit}
                  checked={display === 'mobile'}
                  onClick={(e) => handleChangeInput('display', e.target.value)} />
                <label className={`form-check-label font-weight-normal mb-0 ${ active ? 'active' : '' }`} htmlFor="display-mobile">Mobile</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default StepTwo;