import React from 'react';

const TelegramSVG = ({ width = '20', height = '20' }) => {
  return(
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#039BE5"/>
      <path d="M4.57605 9.78326L14.2177 6.06576C14.6652 5.9041 15.0561 6.17493 14.9111 6.8516L14.9119 6.85076L13.2702 14.5849C13.1486 15.1333 12.8227 15.2666 12.3669 15.0083L9.86689 13.1658L8.66105 14.3274C8.52772 14.4608 8.41522 14.5733 8.15689 14.5733L8.33439 12.0291L12.9677 7.84326C13.1694 7.66576 12.9227 7.56576 12.6569 7.74243L6.93105 11.3474L4.46272 10.5774C3.92689 10.4074 3.91522 10.0416 4.57605 9.78326Z" fill="#F5F8FF"/>
    </svg>    
  );
};

export default TelegramSVG;