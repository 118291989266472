import React, { useEffect, useState, useRef } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import Axios from "axios";
import _ from "lodash";
import SVGCopyPath from "images/svgs/copy.svg";
import ToggleSwitch from "./ToggleSwitch";
import SVGGlobePath from "images/svgs/globe.svg";
import ShopifyImage from "images/svgs/icons/shopify.png";
import EasyStoreImage from "images/svgs/icons/easystore.png";
import $ from "jquery";
import preview_text from "../../../assets/images/builders/preview.png";
import preview_arrow from "../../../assets/images/builders/preview-arrow.png";

function ChatButton({ appId = "", baseURL = "", appName = "Novochat" }) {
  const [service, setService] = useState("whatsapp");
  const [serviceId, setServiceId] = useState(""); // external_id
  const [imageLogo, setImageLogo] = useState(null); // logo
  const [file, setFile] = useState(null);
  const [companyName, setCompanyName] = useState(appName);
  const [buttonText, setButtonText] = useState("Chat with us");
  const [callToAction, setCallToAction] = useState("Start Chat");
  const [greetingMessage, setGreetingMessage] = useState(
    "Hello there! How can I help you?"
  );
  const [position, setPosition] = useState("right");
  const [display, setDisplay] = useState("everywhere");
  const [isSubmit, setIsSubmit] = useState(false);
  const [tabActive, setTabActive] = useState("settings");
  const [isUpdate, setIsUpdate] = useState(false);
  const [chatButtonId, setChatButtonId] = useState(null);
  const [removeFile, setRemoveFile] = useState(false);
  const [code, setCode] = useState("");
  const [active, setActive] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [widthButton, setWidthButton] = useState(185);
  // let serviceRef, serviceIdRef, companyNameRef, imageLogoRef, buttonTextRef, callToActionRef, greetingMessageRef, positionRef, displayRef = useRef();
  let inputRef = useRef({});
  const size = useWindowSize();

  useEffect(() => {
    inputRef.current = {
      service: "whatsapp",
      serviceId: null,
      imageLogo: null,
      companyName: appName,
      buttonText: "Chat with us",
      callToAction: "Start Chat",
      greetingMessage: "Hello there! How can I help you?",
      position: "right",
      display: "everywhere",
      code: "",
      file: null,
    };
    document.body.classList.add('chat-buttons')
  }, []);

  useEffect(() => {
    if (appId && appId !== "" && baseURL !== "") {
      fetchData();
    }
  }, [appId]);

  useEffect(() => {
    if (removeFile) {
      // widget chat button
      var img = $('iframe.novochat-iframe').contents().find('#single_widget_page .novochat-widget-header .logo img');
      if (img.length > 0) {
        img.attr('src', '/assets/brand/logo-icon.png');
      }
    }
  },
  [removeFile]);

  const fetchData = () => {
    // Axios({
    //   method: 'get',
    //   url: `${baseURL}/apps/${appId}/chat_button`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     'Cache-Control': 'no-cache',
    //     'Pragma': 'no-cache',
    //     'Expires': '0',
    //   },
    //   responseType: 'json'
    // })
    //   .then(function (response) {
    //     if(response.status === 200 && response.data.status === 'success' && response.data.data.data !== null) {
    //       let response_data = response.data.data.data.attributes;
    //       inputRef.current['service'] = response_data.service;
    //       inputRef.current['serviceId'] = response_data.external_id;
    //       inputRef.current['companyName'] = response_data.company_name;
    //       inputRef.current['buttonText'] = response_data.button_text;
    //       inputRef.current['callToAction'] = response_data.call_to_action;
    //       inputRef.current['imageLogo'] = response_data.company_logo;
    //       inputRef.current['greetingMessage'] = response_data.greeting_message;
    //       inputRef.current['position'] = response_data.position;
    //       inputRef.current['display'] = response_data.display;
    //       inputRef.current['code'] = response_data.code;
    //       setActive(response_data.active);
    //       setServiceId(response_data.external_id);
    //       setService(response_data.service);
    //       setPosition(response_data.position);
    //       setDisplay(response_data.display);
    //       setIsUpdate(true);
    //       setChatButtonId(response_data.id);
    //     }
    //   })
    //   .catch((error) => {
    //     toastr.error(error.message);
    //   });

    $.ajax({
      method: "get",
      url: `${baseURL}/apps/${appId}/chat_button`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      cache: false,
      responseType: "json",
    })
      .done((response) => {
        if (response.status === "success" && response.data.data !== null) {
          let response_data = response.data.data.attributes;
          inputRef.current["service"] = response_data.service;
          inputRef.current["serviceId"] = response_data.external_id;
          inputRef.current["companyName"] = response_data.company_name;
          inputRef.current["buttonText"] = response_data.button_text;
          inputRef.current["callToAction"] = response_data.call_to_action;
          inputRef.current["imageLogo"] = response_data.company_logo;
          inputRef.current["greetingMessage"] = response_data.greeting_message;
          inputRef.current["position"] = response_data.position;
          inputRef.current["display"] = response_data.display;
          inputRef.current["code"] = response_data.code;
          setActive(response_data.active);
          setServiceId(response_data.external_id);
          setService(response_data.service);
          setPosition(response_data.position);
          setDisplay(response_data.display);
          setIsUpdate(true);
          setChatButtonId(response_data.id);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleChangeInput = _.debounce((data, value) => {
    if (data && active) {
      if (data === "service") {
        inputRef.current["service"] = value;
        setService(value);
      } else if (data === "service_id") {
        inputRef.current["serviceId"] = value;
        setServiceId(value);
      } else if (data === "file") {
        inputRef.current["file"] = value;
        if (value != null) {
          setPreviewImage();
        }
        setFile(value);
      } else if (data === "company_name") {
        inputRef.current["companyName"] = value;
        setCompanyName(value);
      } else if (data === "button_text") {
        inputRef.current["buttonText"] = value;
        setButtonText(value);
      } else if (data === "call_to_action") {
        inputRef.current["callToAction"] = value;
        setCallToAction(value);
      } else if (data === "greeting_message") {
        inputRef.current["greetingMessage"] = value;
        setGreetingMessage(value);
      } else if (data === "position") {
        inputRef.current["position"] = value;
        setPosition(value);
      } else if (data === "display") {
        inputRef.current["display"] = value;
        setDisplay(value);
      } else if (data === "remove_file") {
        setRemoveFile(value);
      }
    }
  }, 50);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let form_data = populateFormData();
    const token = document.querySelector('meta[name="csrf-token"]').content;

    Axios({
      method: isUpdate ? "put" : "post",
      url: `${baseURL}/apps/${appId}/chat_button/${
        isUpdate ? chatButtonId : ""
      }`,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    })
      .then(function (response) {
        if (response.status === 200 && response.data.status === "success") {
          if (inputRef.current["code"] === "") fetchData();

          setTabActive('installation');
          $('.nav-tabs a[href="#installation"]').tab('show');
          toastr.success(
            response.data.data.message ||
              "Single chat button has been updated successfully!"
          );
        }
        setIsSubmit(false);
      })
      .catch((error) => {
        toastr.error(error.message);
        setIsSubmit(false);
      });
  };

  const populateFormData = () => {
    let data = new FormData();
    data.append("chat_buttons[active]", active);
    data.append("chat_buttons[service]", inputRef.current["service"]);
    data.append("chat_buttons[external_id]", inputRef.current["serviceId"]);
    data.append("chat_buttons[company_name]", inputRef.current["companyName"]);
    if (removeFile) data.append("chat_buttons[remove_file]", removeFile);
    if (document.querySelector('input[type="file"]').value)
      data.append("chat_buttons[file]", inputRef.current["file"]);
    data.append("chat_buttons[button_text]", inputRef.current["buttonText"]);
    data.append(
      "chat_buttons[call_to_action]",
      inputRef.current["callToAction"]
    );
    data.append(
      "chat_buttons[greeting_message]",
      inputRef.current["greetingMessage"]
    );
    data.append("chat_buttons[position]", inputRef.current["position"]);
    data.append("chat_buttons[display]", inputRef.current["display"]);
    return data;
  };

  useEffect(() => {
    if (active && inputRef.current["serviceId"]) {
      const delayDebounceFn = setTimeout(() => {
        buttonBuilder();
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    active,
    service,
    serviceId,
    companyName,
    buttonText,
    callToAction,
    greetingMessage,
    position,
  ]);

  const removeButton = () => {
    document
      .getElementsByClassName("novochat-widget-window")
      .forEach(function (nChatWindow) {
        nChatWindow.remove();
      });
    document
      .getElementsByClassName("novochat-button")
      .forEach(function (nChatButton) {
        nChatButton.remove();
      });
    document
      .getElementsByClassName("novochat-widget-style")
      .forEach(function (style) {
        style.remove();
      });
    setWidthButton(185)
  };

  const buttonBuilder = () => {
    let buttonBuilderPromise = new Promise((resolve, reject) => {
      removeButton();
      window.nchatFields = {
        service: inputRef.current["service"],
        external_id: inputRef.current["serviceId"],
        company_name:
          inputRef.current["companyName"] !== ""
            ? inputRef.current["companyName"]
            : "Novochat",
        button_text: inputRef.current["buttonText"],
        call_to_action: inputRef.current["callToAction"],
        greeting_message: inputRef.current["greetingMessage"],
        position: inputRef.current["position"],
        display: inputRef.current["display"],
        active: active,
        custom_left: inputRef.current["position"] == 'left' ? true : false
      };
      var d = document,
        h = d.getElementsByTagName("head")[0],
        s = d.createElement("script");
      s.type = "text/javascript";
      s.async = !0;
      s.src = `${baseURL}/api/v1/widgets/single`;
      h.appendChild(s);
      
      resolve();
      reject();
    });
    buttonBuilderPromise.then(async () => {
      setShowPreview(true);
      await updatePreviewButton();
    });
  };

  useEffect(() => {
    if (active && showPreview && (serviceId || !/^\s*$/.test(serviceId))) {
      // setActive(true);
      setShowPreview(true);
    } else {
      // setActive(false);
      setShowPreview(false);
      removeButton();
    }
  }, [serviceId, active, showPreview]);

  const updatePreviewButton = async () => {
    if (showPreview) {
      const delayDebounceFn = setTimeout(() => {
        let element = document.querySelector(
          '[data-novochat-id="AppWidgetButton-single"]'
        );
        if (element) {
          setWidthButton(element.offsetWidth);
          // update image if exists
          if (inputRef.current['file']) {
            setTimeout(() => setPreviewImage(), 500);
          }
        } else {
          buttonBuilder();
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  };

  useEffect(() => {
    if (showPreview) updatePreviewButton();
    else removeButton();
  }, [showPreview]);

  useEffect(() => {
    var preview_button = document.getElementById("preview-button");
    if (preview_button) {
      // preview_button.style.bottom = "8rem";

      if (position === "left") {
        preview_button.style.left = `${widthButton + 70}px`;
      }
    }
  },
  [widthButton]);

  useEffect(() => {
    if (tabActive === "installation") {
      setShowPreview(false);
      removeButton();
      $('.container-fixed-full').css({ minHeight: 'auto', maxHeight: 'unset' })
    } else if (tabActive === "settings" && active && serviceId) {
      setShowPreview(true);
      $('.container-fixed-full').css({ minHeight: '', maxHeight: '' })
    }
  }, [tabActive, active, serviceId]);

  async function setPreviewImage() {
    if (inputRef.current['file']) {
      var img = $('iframe.novochat-iframe').contents().find('#single_widget_page .novochat-widget-header .logo img');
      if (img.length > 0) {
        var url = URL.createObjectURL(inputRef.current['file']);
        img.attr('src', url);
      }
    }
  }

  function installation_select(id) {
    if (id) {
      $('.collapse-installation').slideUp();
      if($(`#${id}`).is(":hidden")) {
        $(`#${id}`).slideDown();
      }
    }
  }

  return (
    <div className="single-buttons container-fixed-full">
      <div className="chat-buttons container-margin content-start">

        <div className="component-padding">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                href="#settings"
                className="nav-link active"
                data-toggle="tab"
                onClick={() => setTabActive("settings")}
              >
                Settings
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#installation"
                className="nav-link"
                data-toggle="tab"
                onClick={() => setTabActive("installation")}
              >
                Installation
              </a>
            </li>
          </ul>
        </div>

        <form onSubmit={(e) => handleFormSubmit(e)}>
          <div className="tab-content">
            <div className="tab-pane active" id="settings">
              <div className="container p-0">
                <div className="container-fixed h-auto">
                  <div className="content-wrap p-0">
                    <div className="activation component-padding">
                      <div className="sub-header-title component-padding">
                        Activation
                      </div>

                      <div className="d-flex flex-row align-items-center activate-button">
                        <div className="title">Activate single chat button</div>

                        <div className="switch">
                          {/* <ToggleSwitch
                            id="switch-active"
                            checked={active}
                            small={true}
                            onChange={(active) => setActive(active)}
                          /> */}
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="switch-active"
                              defaultValue={active}
                              checked={active}
                              onChange={(e) => setActive(e.target.checked)} />
                            <label className="custom-control-label" htmlFor="switch-active"></label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="content-items px-0 pt-0 component-padding">
                      <>
                        <div className="sub-header-title component-padding">Design</div>

                        <div className="mb-3">
                          <StepOne
                            active={active && tabActive === "settings"}
                            showHeader={false}
                            handleChangeInput={handleChangeInput}
                            service={inputRef.current["service"]}
                            serviceId={inputRef.current["serviceId"]}
                            isSubmit={isSubmit}
                          />
                        </div>

                        <StepTwo
                          showHeader={false}
                          active={
                            active && tabActive === "settings" && serviceId
                          }
                          handleChangeInput={handleChangeInput}
                          isSubmit={isSubmit}
                          imageLogo={inputRef.current["imageLogo"]}
                          companyName={inputRef.current["companyName"]}
                          buttonText={inputRef.current["buttonText"]}
                          callToAction={inputRef.current["callToAction"]}
                          greetingMessage={inputRef.current["greetingMessage"]}
                          position={inputRef.current["position"]}
                          display={inputRef.current["display"]}
                          removeFile={removeFile}
                          showLogoInput={true}
                          showBorderBottom={false}
                        />
                      </>
                    </div>
                  </div>

                  {showPreview && (
                    <div
                      className="preview-button"
                      id="preview-button"
                      style={{
                        right: showPreview
                          ? position === "left"
                            ? "unset"
                            : `${widthButton}px`
                          : "12%",
                        left:
                          position === "left" ? `${widthButton + 70}px` : "unset",
                      }}
                    >
                      <img
                        src={preview_text}
                        height={35}
                        width={116}
                        className="mb-2"
                      />
                      <img
                        src={preview_arrow}
                        width={45}
                        height={40}
                        style={{
                          transform:
                            position === "left" ? "scaleX(-1)" : "unset",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="action-buttons-bottom">
                <button
                  className="btn btn-primary float-right"
                  disabled={isSubmit || !inputRef.current["serviceId"]}
                >
                  Save
                </button>
              </div>
            </div>

            <div className="tab-pane show" id="installation">
              <div className="container p-0">
                <div className="container-fixed h-auto">
                  <div className="content-wrap p-0">
                    <div className="content-items p-0">
                      <div className="form-inline justify-content-start component-padding installation">
                        <div className="col-md-12 col-sm-12 d-flex flex-column align-items-start px-0 component-padding">
                          <small className="form-text text-muted info-installation">
                            Select where you want to install the button and click to show installation guide.
                          </small>
                        </div>

                        <div className="col-md-12 col-sm-12 px-0 component-padding">
                          <div id="accordion">
                            <div className="card">
                              <div className="card-header accordion-single-chat" id="heading-installing-on-website">
                                <div className="mb-0">
                                  <button 
                                    type="button" 
                                    className="btn btn-no-border text-left w-100" 
                                    onClick={() => installation_select('installing-on-website')}>
                                    <div className="d-flex flex-row align-items-center">
                                      <img src={SVGGlobePath} width={24} height={24} />
                                      <span className="semi-bold ml-2">Installing on a website</span>
                                    </div>
                                  </button>
                                </div>
                              </div>

                              <div 
                                id="installing-on-website" 
                                className="collapse-installation"
                                style={{ display: 'none' }}>
                                <div className="card-body">
                                  <ol className="list-numbered">
                                    <li className="component-padding">Copy the Javascript code below.</li>
                                    <div className="component-padding">
                                      <div className="code-container">
                                        <div className="d-flex flex-row">
                                          <textarea
                                            id="widgetJSText"
                                            className="widget-code-box"
                                            readOnly
                                            wrap="off"
                                            value={inputRef.current["code"] || ""}
                                          ></textarea>
                                          <a
                                            id="widgetJSCopy"
                                            className="code-copy-button"
                                            data-placement="top"
                                            data-toggle="tooltip"
                                            title="Copy to clipboard"
                                            onClick={() => copy_to_clipboard("widgetJS")}
                                          >
                                            <img
                                              src={SVGCopyPath}
                                              alt={"copy"}
                                              width={20}
                                              height={20}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <li className="component-padding">{"Paste the code inside the <body> tag of your website."}</li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                            {/* -------------- SHOPIFY ---------------- */}
                            <div className="card">
                              <div className="card-header accordion-single-chat" id="heading-installing-on-shopify">
                                <div className="mb-0">
                                  <button
                                    type="button" 
                                    className="btn btn-no-border text-left w-100"
                                    onClick={() => installation_select('installing-on-shopify')} >
                                    <div className="d-flex flex-row align-items-center">
                                      <img src={ShopifyImage} width={24} height={24} />
                                      <span className="semi-bold ml-2">Installing on a Shopify Store</span>
                                    </div>
                                  </button>
                                </div>
                              </div>

                              <div 
                                id="installing-on-shopify" 
                                className="collapse-installation" 
                                style={{ display: 'none' }}>
                                <div className="card-body">
                                  <div className="component-padding">
                                    <ol className="list-numbered">
                                      <li className="component-padding">{"From your Shopify admin, go to Online Store > Theme > Customize."}</li>
                                      <li className="component-padding">{"In the Theme editor page, open Theme Settings."}</li>
                                      <li className="component-padding">{"Go to the 'App embeds' tab, and turn the Novochat Widget toggle on. The chat button will be added to the page preview."}</li>
                                      <li className="component-padding">{"Check if the chat button appears in your shop's live pages."}</li>
                                      <li className="component-padding">{"Done!"}</li>
                                    </ol>
                                  </div>
                                  <a 
                                    href="https://help.novochat.co/How-Do-I-Install-Chat-Button-In-My-Store-e6b2702c9bda4ee2848ba4c5f1cce9fe" 
                                    className="bold link"
                                    target="_blank">
                                      {"See a more detailed walk-through >"}
                                  </a>
                                </div>
                              </div>
                            </div>

                            {/* -------------- EASYSTORE ---------------- */}
                            <div className="card">
                              <div className="card-header accordion-single-chat" id="heading-installing-on-easystore">
                                <div className="mb-0">
                                  <button 
                                    type="button" 
                                    className="btn btn-no-border text-left w-100"
                                    onClick={() => installation_select('installing-on-easystore')} >
                                    <div className="d-flex flex-row align-items-center">
                                      <img src={EasyStoreImage} width={24} height={24} />
                                      <span className="semi-bold ml-2">Installing on an EasyStore Store</span>
                                    </div>
                                  </button>
                                </div>
                              </div>

                              <div 
                                id="installing-on-easystore" 
                                className="collapse-installation"
                                style={{ display: 'none' }} >
                                <div className="card-body pl-0">
                                  <div className="component-padding">
                                    Your widget was automatically installed once you saved the chat button configuration! 
                                  </div>
                                  <div className="component-padding">
                                    Check if the chat button appears in your shop’s live pages.
                                  </div>
                                  <a 
                                    href="https://help.novochat.co/Easystore-Integration-6bee69cdbbec4ca19dec62d3f11de1d7" 
                                    className="bold link ml-0"
                                    target="_blank">
                                      {"See a more detailed walk-through >"}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default ChatButton;
