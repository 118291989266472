import React from 'react';

const FacebookSVG = ({ width = '20', height = '20' }) => {
  return(
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="url(#paint0_linear)"/>
      <path d="M16.5697 7.23783C16.4458 7.07166 16.2185 7.02194 16.0364 7.12115L11.7031 9.48365L9.00729 7.17197C8.84197 7.03026 8.59541 7.03967 8.44147 7.19365L3.44147 12.1937C3.27924 12.3569 3.28002 12.6207 3.44326 12.7829C3.57377 12.9126 3.77389 12.9414 3.93565 12.8537L8.26897 10.4912L10.9673 12.8037C11.1326 12.9454 11.3792 12.936 11.5331 12.782L16.5331 7.78197C16.6788 7.6349 16.6944 7.40315 16.5697 7.23783Z" fill="#FAFAFA"/>
      
      <defs>
        <linearGradient id="paint0_linear" x1="2.95652" y1="18.4348" x2="20" y2="1.13044" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1181FF"/>
          <stop offset="0.463541" stopColor="#A334FA"/>
          <stop offset="1" stopColor="#FF656E"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FacebookSVG;