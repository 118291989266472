import React from "react";
import axios from "axios";
import { Table, Button, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import TagsContact from "./TagsContact";
import LoadingPage from "../shared/LoadingPage";

class ContactDetail extends React.Component {
  _LOADING = {};

  constructor(props) {
    super(props);

    this.state = {
      base_url: "/contacts",
      app_id: this.props.app_id,
      domain_url: this.props.domain_url,
      id: this.props.id,

      show_modal: false,
      confirmation_modal: false,
      contact: {
        name: "",
        email: "",
        phone: "",
      },
      conversations: [],
      tags: [],
      suggestions: [],
      all_errors: [],
      country: "us",
      disabled_button_contact: false,
      is_loading: true,
    };

    this.reactTags = React.createRef();
  }

  componentDidMount = () => {
    this._getCountryCode();

    this._getContact();

    this._LOADING = setInterval(() => {
      this.setState({ is_loading: false });
    }, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this._LOADING);
  };

  _getCountryCode = () => {
    let _this = this;
    axios({
      method: "GET",
      url: "https://extreme-ip-lookup.com/json/",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
    })
      .then((response) => {
        if (response.status == 200) {
          _this.setState({
            country: response.data.countryCode.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        _this.setState({
          country: "us",
        });
      });
  };

  _getContact = () => {
    let _this = this;
    const { base_url, domain_url, app_id, id } = this.state;
    if (id) {
      const url = domain_url + "/apps/" + app_id + base_url + "/" + id;
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        data: {
          id: id,
          app_id: app_id,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == "success") {
              var contact_response = response.data.data.data.attributes;
              // console.log('contact_response', contact_response)
              if (contact_response) {
                // Set contact tags
                let contact_tags_temp = contact_response.tags.data;
                let contact_tag_suggestions = contact_response.tag_suggestions;
                let contact_tags = [];
                contact_tags_temp.map((tag) => {
                  contact_tags.push(tag.attributes);
                  if (contact_tag_suggestions.length) {
                    contact_tag_suggestions.splice(
                      contact_tag_suggestions.findIndex((x) => x.id == tag.id),
                      1
                    );
                  }
                });

                var state = {
                  contact: {
                    name: contact_response.name,
                    email: contact_response.email,
                    phone: contact_response.phone,
                  },
                  conversations: contact_response.conversations.data,
                  tags: contact_tags,
                  suggestions: contact_tag_suggestions,
                  disabled_add_contact: false,
                  confirmation_modal: false,
                  show_modal: false,
                };
                _this.setState(state);
              }
            } else {
              if (response.data.message) {
                toastr.error(response.data.message);
              }
            }
          }
        })
        .catch((error) => {
          toastr.error("There was an error");
        });
    }
  };

  _setState = (state) => {
    this.setState(state);
  };

  _handleChangeState = (input, value) => {
    let contact_value = this.state.contact;
    if (input == "phone") {
      contact_value[input] = "+" + value;
    } else if (input == "tags") {
      contact_value[input] = value;
    } else {
      contact_value[input] = value.target.value;
    }

    this.setState({
      contact: contact_value,
    });
  };

  _handleSubmitContact = () => {
    console.log("submit update contact");
    this.setState({ disabled_button_contact: true });
    let _this = this;
    const { contact, base_url, domain_url, app_id, id } = this.state;
    const url = domain_url + "/apps/" + app_id + base_url + "/" + id;
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "PUT",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        contact: contact,
        app_id: app_id,
        id: id,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          // console.log('response', response)

          if (response.data.status == "success") {
            var state = {
              confirmation_modal: false,
              show_modal: false,
              all_errors: [],
            };
            _this._setState(state);
            toastr.success(response.data.data.message);
          } else {
            if (response.data.errors) {
              _this.setState({ all_errors: response.data.errors });
            }

            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
        _this.setState({ disabled_button_contact: false });
      })
      .catch((error) => {
        toastr.error("There was an error");
      });
  };

  _formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  _handleBackContact = () => {
    window.location = `/apps/${this.state.app_id}/contacts`;
  }

  render = () => {
    const {
      is_loading,
      app_id,
      id,
      domain_url,
      base_url,
      tags,
      suggestions,
      all_errors,
      country,
      contact,
      conversations,
      disabled_button_contact,
    } = this.state;
    if (is_loading) {
      return <LoadingPage />;
    }

    return (
      <div className="contacts container-margin">
        <div className="row">
          <div className="col-md-6">
            <h3 className="header-title-padding header-sticky">Contact Detail</h3>
            <div className="content-start">
              <Form>
                <Form.Group controlId="contactForm.ControlInputName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={
                      all_errors.find((e) => e.type === "name") ? true : false
                    }
                    value={contact["name"]}
                    onChange={(value) => this._handleChangeState("name", value)}
                    placeholder="Enter name"
                  />
                  {all_errors.find((e) => e.type === "name") && (
                    <div className="errors">
                      {all_errors.find((e) => e.type === "name").message}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="contactForm.ControlInputEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    isInvalid={
                      all_errors.find((e) => e.type === "email") ? true : false
                    }
                    value={contact["email"]}
                    onChange={(value) => this._handleChangeState("email", value)}
                    placeholder="Enter email address"
                  />
                  {all_errors.find((e) => e.type === "email") && (
                    <div className="errors">
                      {all_errors.find((e) => e.type === "email").message}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="contactForm.ControlInputNumber">
                  <Form.Label>Number</Form.Label>
                  <PhoneInput
                    inputProps={{
                      required: true,
                    }}
                    isValid={
                      all_errors.find((e) => e.type === "phone") ? false : true
                    }
                    inputStyle={{ width: "100%" }}
                    country={country}
                    value={contact["phone"]}
                    placeholder={"Enter Phone Number"}
                    onChange={(phone) => this._handleChangeState("phone", phone)}
                  />

                  {all_errors.find((e) => e.type === "phone") && (
                    <div className="errors">
                      {all_errors.find((e) => e.type === "phone").message}
                    </div>
                  )}
                </Form.Group>
              </Form>

              <Button
                disabled={disabled_button_contact}
                variant="default"
                className="mt-3 mr-2"
                onClick={() => this._handleBackContact()}
              >
                Back
              </Button>

              <Button
                disabled={disabled_button_contact}
                variant="primary"
                className="mt-3"
                onClick={() => this._handleSubmitContact()}
              >
                Submit
              </Button>

              <TagsContact
                id={id}
                app_id={app_id}
                domain_url={domain_url}
                base_url={base_url}
                tags={tags}
                suggestions={suggestions}
              />
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="header-title-padding header-sticky">Conversations</h3>
            <div className="content-start">
              {conversations.length ? (
                <Table
                  responsive
                  borderless
                  hover
                  className="conversations-table"
                >
                  <thead></thead>
                  <tbody>
                    <ConversationRow
                      dataList={conversations}
                      formatAMPM={this._formatAMPM}
                      domain_url={domain_url}
                      app_id={app_id}
                    />
                  </tbody>
                </Table>
              ) : (
                <span>No conversation.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const ConversationRow = ({ dataList, formatAMPM, domain_url, app_id }) => {
  var list = [];
  dataList.map((data, index) => {
    data = data.attributes;
    var directConversationUrl = `${domain_url}/apps/${app_id}/conversations?tab=all&by_id=${data.id}`;
    var platform_service = "";
    if (data.platform_service == "line") {
      platform_service = <img src={SvgLineIconPath} width={25} height={25} />;
    } else if (data.platform_service == "telegram") {
      platform_service = (
        <img src={SvgTelegramIconPath} width={25} height={25} />
      );
    } else if (data.platform_service == "live_chat") {
      platform_service = (
        <img src={SvgLiveChatIconPath} width={25} height={25} />
      );
    } else if (data.platform_service == "facebook") {
      platform_service = (
        <img src={SvgFacebookIconPath} width={25} height={25} />
      );
    } else if (data.platform_service == "instagram") {
      platform_service = (
        <img src={SvgInstagramIconPath} width={25} height={25} />
      );
    } else if (
      data.platform_service == "whatsapp" ||
      data.platform_service == "whatsapp_chatapi" ||
      data.platform_service == "whatsapp_business"
    ) {
      platform_service = (
        <img src={SvgWhatsappIconPath} width={25} height={25} />
      );
    }

    var updated_at = new Date(data.updated_at);
    var time = formatAMPM(updated_at);
    updated_at =
      monthNames[updated_at.getMonth()] +
      " " +
      updated_at.getDate() +
      ", " +
      updated_at.getFullYear() +
      " " +
      time;

    list.push(
      <tr key={index}>        
        <td>
          <a
            href={directConversationUrl}
            target="_blank"
            className="text-decoration-none d-flex w-100 align-items-center" >
            {platform_service}
            <div className="detail-wrap ml-3">
              <span className="conversation-name">{data.name}</span>
              <br />
              <small className="sm-text">{updated_at}</small>
            </div>
          </a>
        </td>
        <td className="icon">
          <a
            href={directConversationUrl}
            target="_blank"
            className="text-decoration-none align-items-center" >
            <div className="icon-wrap my-auto">
              <i className="fa fa-caret-right icon-detail-right" />
            </div>
          </a>
        </td>
      </tr>
    );
  });
  return list;
};

export default ContactDetail;
