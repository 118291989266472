const { debounce } = require("lodash");

window.broadcast_scheduled_options = (el) => {
  if (el.value === "At scheduled time") {
    $("#broadcast_scheduled_time").show();
  } else {
    $("#broadcast_scheduled_time").hide();
    $("#broadcast_scheduled_time").val("");
  }
};

// change the broadcast channel
window.broadcast_channel_options = (el) => {
  var value = el.value;
  $("#channel-options").show();
  $(".channel-options-item").each(function (index, data) {
    if ($(data).attr("data-platform-service") === value) {
      $(data).css("display", "flex");
    } else {
      $(data).hide();
    }
  });
};

window.broadcast_platform_options = (el) => {
  platformService = $(el).find(":selected").data("platform-service");
  if (platformService == "whatsapp_chatapi") {
    $(".recipient_options").show();
  } else {
    $("#new_recipients_field").hide();
    $("#broadcast_recipient_type").val(1);
    $(".broadcast-message-elements").show();
  }
};

window.new_broadcast_recipients = (el) => {
  if (el.value == "existing_recipient") {
    $("#new_recipients_field").hide();
    $(".broadcast-message-elements").show();
  } else if (el.value == "new_recipient") {
    $("#new_recipients_field").show();
    $(".broadcast-message-elements").hide();
  }
};

window.editable_message = (el) => {
  var button = $(el);
  var appId = button.data("app-id");
  var broadcastId = button.data("broadcast-id");
  var recipientId = button.data("recipient-id");
  var recipientRow = $("#broadcast_recipient_" + recipientId);
  var messageElement = recipientRow.find(".editable_message");
  var currentText = messageElement.text();
  var inputElement = $("<textarea>", { row: 7 }).text(currentText);

  $(button).text(function (_, val) {
    if (val == "Edit") {
      if (recipientRow.find("textarea").length > 0) {
        return;
      }
      messageElement.after(inputElement);
      messageElement.hide();
    } else {
      var newTextElement = recipientRow.find("textarea");

      $.ajax({
        type: "PATCH",
        url: `/apps/${appId}/broadcasts/${broadcastId}/broadcast_recipients/${recipientId}.js`,
        data: { broadcast_recipient: { message: newTextElement.val() } },
      });
    }
    return val == "Save" ? "Edit" : "Save";
  });
};

window.filter_contact_broadcast = () => {
  $(".search-filter-tags-wrap").show();
  $("#filter-contact-broadcast").addClass("active");
};

const generate_rows_broadcast_recipient = (data, index) => {
  let tr = document.createElement('tr');
  tr.setAttribute('class', 'table-search-available border-0')
  tr.setAttribute('style', `background-color: ${(index+1) % 2 === 0 ? '#F9F9F9' : '#FFFFFF'}`)

  let td = document.createElement('td');
  td.setAttribute('class',  'border-0 pt-3 pl-1 d-flex align-items-center justify-content-center');
  let input_check = document.createElement('input');
  input_check.setAttribute('name', 'recipient[]');
  input_check.setAttribute('id', `recipient_${data.id}`);
  input_check.setAttribute('value', data.id || null);
  input_check.setAttribute('class', 'm-0 check-input existing-recipient-checkbox');
  td.append(input_check);
  tr.append(td);

  td = document.createElement('td'); // number or counter
  td.setAttribute('class', 'pl-0 border-0');
  td.append((index+1));
  tr.append(td);

  td = document.createElement('td'); // name
  td.setAttribute('class', 'border-0');
  td.append(data.attributes.name);
  tr.append(td);

  td = document.createElement('td'); // phone
  td.setAttribute('class', 'border-0');
  td.append(data.attributes.phone);
  tr.append(td);

  td = document.createElement('td'); // email
  td.setAttribute('class', 'border-0');
  td.append(data.attributes.email);
  tr.append(td);
};

function paginateShowPage(whichPage, numberOfItems) {
  var limitPerPage = 10;
  var totalPages = Math.ceil(numberOfItems / limitPerPage);
  var paginationSize = 7;
  var currentPage;

  if (whichPage < 1 || whichPage > totalPages) return false;
  currentPage = whichPage;
  $("#broadcast-contacts-new-recipients tbody tr.show-active").hide()
      .slice((currentPage-1) * limitPerPage,
              currentPage * limitPerPage).show();
  table_pagination(totalPages, currentPage, paginationSize);
  return true;
}

window.search_broadcast = debounce(() => {
  function isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
  // Declare variables
  var input, filter, table, tbody, current_page, tr, td, i, txtValue;
  input = document.getElementById("search-broadcast-contact");
  filter = input.value.toLowerCase() || '';
  table = document.getElementById("broadcast-contacts-new-recipients");
  tbody = table.querySelector('tbody');
  // current_page = document.getElementById('broadcast_current_page').value;

  // if(filter !== '') {
  //   $.ajax({
  //     url: `/apps/${app_id}/broadcasts/${broadcast_id}/broadcast_recipients/search_broadcast_recipient`,
  //     type: "GET",
  //     headers: {
  //       "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
  //     },
  //     data: {
  //       by_keyword: filter
  //     }
  //   })
  //     .then((response) => {
  //       console.log('response',response)

  //       if(response.data.length > 0) {
  //         response.data.forEach((value, index) => {


  //         })
  //       }
  //     })
  // }


  tr = table.querySelectorAll("tbody > tr.table-search-available");
  var tags_filter = document.querySelectorAll(
    "div#tags-filter-selected > div.tag-search"
  );

  if (isBlank(filter) && tags_filter.length <= 0) {
    Array.from(tr).map((elem, i) => {
      if (i < 10) {
        elem.style.display = "";
        elem.classList.add('show-active');
      }
      else {
        elem.style.display = "none";
        elem.classList.remove('show-active');
      }
    });
    paginateShowPage(1, $('#broadcast-contacts-new-recipients tbody tr').length);
  } else if (isBlank(filter) && tags_filter.length > 0) {
    filter_tags();
  } else {
    // Loop through all table rows, and hide those who don't match the search query
    let counter = 0;
    for (i = 0; i < tr.length; i++) {
      tr[i].style.display = 'table-row'
      td_elements = tr[i].getElementsByTagName("td");
      var flag_show = false;
      for (x = 1; x < td_elements.length && !flag_show; x++) {
        txtValue = td_elements[x].textContent || td_elements[x].innerText;

        if (
          txtValue.toLowerCase().indexOf(filter) > -1 &&
          (tr[i].style.display === "" || tr[i].style.display === "table-row")
        ) {
          flag_show = true;
          counter += 1;
        }
      }

      if (flag_show) {
        tr[i].style.display = "";
        tr[i].classList.add('show-active');
        tr[i].querySelector('td.counter-table').textContent = counter;
      } else {
        tr[i].style.display = "none";
        tr[i].classList.remove('show-active');
      }
    }
    //$('#pagination-table-new-recipients').hide();
    paginateShowPage(1, $('#broadcast-contacts-new-recipients tbody tr.show-active').length);
  }
}, 300);

// -------------- FILTER TAGS FUNCTION ------------- //

function filter_tags() {
  // var tags_all = document.querySelectorAll('div.col-tag > div.tag-search');
  var tags_filter = document.querySelectorAll(
    "div#tags-filter-selected > div.tag-search"
  );
  var table = document.getElementById("broadcast-contacts");
  var tr = table.querySelectorAll("tbody > tr.table-search-available");

  // per row
  for (row = 0; row < tr.length; row++) {
    var flag_show = false;
    // get td of tags element
    var td = tr[row].querySelector("td.tags-elements");
    var tags_all = td.querySelectorAll("div.col-tag > div.tag-search");

    for (i = 0; i < tags_all.length && !flag_show; i++) {
      if (tags_filter.length <= 0) {
        document
          .getElementById("filter-contact-broadcast")
          .classList.remove("active");
        flag_show = true;
      } else {
        for (y = 0; y < tags_filter.length; y++) {
          var txtValue = tags_all[i].textContent || "";
          if (
            txtValue.toUpperCase() ===
            tags_filter[y].querySelector("span").textContent.toUpperCase()
          ) {
            flag_show = true;
          }
        }
      }
    }

    if (flag_show) {
      tr[row].style.display = "";
    } else {
      tr[row].style.display = "none";
    }
  }
}

function addActive(x, currentFocus) {
  /*a function to classify an item as "active":*/
  if (!x) return false;
  /*start by removing the "active" class on all items:*/
  removeActive(x);
  if (currentFocus >= x.length) currentFocus = 0;
  if (currentFocus < 0) currentFocus = x.length - 1;
  /*add class "autocomplete-active":*/
  if (x && x[currentFocus]) {
    x[currentFocus].classList.add("autocomplete-active");
  }
}

function removeActive(x) {
  /*a function to remove the "active" class from all autocomplete items:*/
  for (var i = 0; i < x.length; i++) {
    x[i].classList.remove("autocomplete-active");
  }
}

function closeAllLists(elmnt) {
  var inp = document.getElementById("search-filter-tags");
  /*close all autocomplete lists in the document,
  except the one passed as an argument:*/
  var x = document.getElementsByClassName("autocomplete-items");
  for (var i = 0; i < x.length; i++) {
    if (elmnt != x[i] && elmnt != inp) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}

window.on_input_filter_tags = () => {
  var inp = document.getElementById("search-filter-tags");
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function (e) {
    var td_elements = document.querySelectorAll("td.tags-elements");
    var tags_filter = Array.from(
      document.querySelectorAll("div#tags-filter-selected > div.tag-search")
    ).map((elem) => elem.querySelector("span").textContent);
    var arr = [];
    for (counter = 0; counter < td_elements.length; counter++) {
      var current_tags =
        td_elements[counter].querySelectorAll("div.tag-search");
      for (t = 0; t < current_tags.length; t++) {
        arr.push(current_tags[t].textContent);
      }
    }
    // remove duplicates tags
    arr =
      Array.from(arr).filter((item, pos, self) => self.indexOf(item) == pos) ||
      [];
    // remove tags that already in filter
    arr = arr.filter((data) => !tags_filter.includes(data));
    var a,
      b,
      i,
      val = this.value;
    var flag_create_element_list = false;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement("DIV");
    a.setAttribute("id", this.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items border");

    /*for each item in the array...*/
    for (i = 0; i < arr.length; i++) {
      /*check if the item starts with the same letters as the text field value:*/
      if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
        /*create a DIV element for each matching element:*/
        flag_create_element_list = true;
        b = document.createElement("DIV");
        /*make the matching letters bold:*/
        b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
        b.innerHTML += arr[i].substr(val.length);
        /*insert a input field that will hold the current array item's value:*/
        b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
        /*execute a function when someone clicks on the item value (DIV element):*/
        b.addEventListener("click", function (e) {
          /*insert the value for the autocomplete tags selected*/
          var tag_value = this.getElementsByTagName("input")[0].value;
          var element_to_append = document.getElementById(
            "tags-filter-selected"
          );
          var new_tag = document.createElement("DIV");
          var remove_tag = document.createElement("DIV");
          remove_tag.setAttribute("class", "float-right p-1 ml-2 remove-tag");
          remove_tag.setAttribute("id", "remove-tag-" + tag_value);
          remove_tag.setAttribute("data-id", tag_value);
          remove_tag.textContent = "x";
          new_tag.setAttribute(
            "class",
            "tag-search border mb-2 py-1 px-2 mr-2 d-flex flex-row align-items-center"
          );

          var span_text = document.createElement("SPAN");
          span_text.textContent = tag_value;

          var input_hidden = document.createElement("INPUT");
          input_hidden.setAttribute("type", "hidden");
          input_hidden.value = tag_value;
          input_hidden.setAttribute("id", "remove-hidden-tag-id-" + tag_value);
          input_hidden.setAttribute("class", "remove-hidden-tag");

          remove_tag.append(input_hidden);
          new_tag.appendChild(span_text);
          new_tag.appendChild(remove_tag);

          new_tag.addEventListener("click", function (e) {
            e.target
              .querySelector(".remove-hidden-tag")
              .closest("div.tag-search")
              .remove();
            filter_tags(e.target.querySelector(".remove-hidden-tag").value);
          });

          element_to_append.appendChild(new_tag);
          inp.value = "";
          /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
          closeAllLists();
          filter_tags(tag_value);
        });
        a.appendChild(b);
      }
    }
    if (flag_create_element_list) {
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
    }
  });
};

window.on_keydown_filter_tags = () => {
  var inp = document.getElementById("search-filter-tags");
  var currentFocus = -1;
  inp.addEventListener("keydown", function (e) {
    var x = document.getElementById(this.id + "autocomplete-list");
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode == 40) {
      /*If the arrow DOWN key is pressed,
      increase the currentFocus variable:*/
      currentFocus++;
      /*and and make the current item more visible:*/
      addActive(x, currentFocus);
    } else if (e.keyCode == 38) {
      //up
      /*If the arrow UP key is pressed,
      decrease the currentFocus variable:*/
      currentFocus--;
      /*and and make the current item more visible:*/
      addActive(x, currentFocus);
    } else if (e.keyCode == 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) x[currentFocus].click();
      }
    }
  });
};

/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
  closeAllLists(e.target);
});

// -------------- END OF FILTER TAGS FUNCTION ------------- //

// window.filter_tags = () => {
//   var input = document.getElementById('search-filter-tags').value.toUpperCase();
//   var tags_selected = document.querySelectorAll('div.tag-search');
//   var arr = [];

//   arr = Array.from(tags_selected).map((elem) => elem.textContent);
//   // var flag_show = false;

//   // for(i = 0; i < tags_selected.length; i++) {
//   //   var txtValue = tags_selected[i].textContent || '';
//   //   if(txtValue.toUpperCase().indexOf(input) > -1) {
//   //     flag_show = true;
//   //   }

//   //   if(flag_show) {
//   //     $(tags_selected[i].closest('tr')).css('display', 'table-row');
//   //   }
//   //   else {
//   //     $(tags_selected[i].closest('tr')).css('display', 'none');
//   //   }
//   // }

// };

window.broadcast_remove_file = () => {
  var input_files = document.getElementsByClassName("btn-file-input");
  for (i = 0; i < input_files.length; i++) {
    input_files[i].value = "";
  }

  document.getElementById("label-file").textContent = "Upload Image File";
  document.getElementById("remove-file-input").remove();

  let btn_remove_current_file = document.getElementById("button-remove-current-file");
  if(btn_remove_current_file) {
    btn_remove_current_file.style.display = "";
  }

  // widget chat button
  var img = $('iframe.novochat-iframe').contents().find('#single_widget_page .novochat-widget-header .logo img');
  if (img.length > 0) {
    img.attr('src', '/assets/brand/logo-icon.png');
  }

  // widget preview
  var widgets = $('.novochat-widget-header');
  if (widgets.length > 0) {
    widgets.find('img').attr('src', '');
    widgets.html(`<div class="novochat-text-logo">${$('#workspace-name').val()}</div>`);
  }
};

window.broadcast_remove_current_file = () => {
  var input = document.getElementById("broadcast-remove-file");
  if (input) {
    input.value = "true";
    document.getElementById("preview-image").style.display = "none";
  }
};

window.change_template_categories = (el) => {
  var select = $(el);
  var value = select.val();
  if(value.length > 0) {
    $('.template-item').hide();
    $('.template-item').each((index, element) => {
      if($(element).hasClass(`c-${value}`)) {
        $(element).show();
      }
    });
  }
  else {
    $('.template-item').show();
  }
};



// check box clicked for mapping field
window.field_map_check = (el) => {
  let checkbox_checked = $(el);
  let count = document.querySelectorAll('div.fields-mapping input[type="checkbox"].check-box-field:checked').length; // count how many checked checkbox
  var checkboxes = document.getElementsByClassName("check-box-field"),
      len = checkboxes !== null ? checkboxes.length : 0,
      i = 0;
  var select = checkbox_checked.closest('thead').nextAll('tfoot').find('select');
  if (checkbox_checked.is(":checked")) {
    // remove disabled on select map to
    select.removeAttr('disabled');
    select.attr('required', 'required');
    var select_values = get_all_select_values();
    if (select_values.length > 0) {
      for (let j = 0; j < select_values.length; j++) {
        select.find('option[value="'+select_values[j]+'"]').attr('disabled', 'disabled')
      }
    }
  }
  else {
    // add disabled to select
    select.attr('disabled', 'disabled');
    select.val('');
  }

  if (count < (checkbox_checked.attr('data-map') === 'contact' ? 4 : 2)) {
    // removed disabled check boxes
    for (i; i < len; i++) {
      checkboxes[i].removeAttribute('disabled');
    }
  }
  else {
    // add disabled check boxes
    for (i; i < len; i++) {
      if (!checkboxes[i].checked) {
        checkboxes[i].setAttribute('disabled', 'disabled');
      }
    }
  }
};

window.disabled_all_field_map_checkbox = (map = 'broadcast') => {
  var checkboxes = document.getElementsByClassName("check-box-field"),
      len = checkboxes !== null ? checkboxes.length : 0,
      i = 0;
  var values = get_all_select_values();
  let select_not_disabled = document.querySelectorAll('div.fields-mapping select.map-to-select:not(:disabled)');
  for (i; i < len; i++) {
    if (!checkboxes[i].checked && select_not_disabled.length === (map === 'contact' ? 3 : 2)) {
      checkboxes[i].setAttribute('disabled', 'disabled');
    }

     // disabled option
     for (let j = 0; j < values.length; j++) {
      if (select_not_disabled[i]) {
        let option = select_not_disabled[i].querySelector('option[value="'+values[j]+'"]')
        if (option && option.value !== select_not_disabled[i].value) {
          option.setAttribute('disabled', 'disabled');
        }
      }
    }
  }
};

window.select_map_to_change = () => {
  let values = get_all_select_values();
  $('div.fields-mapping select.map-to-select:not(:disabled) option').removeAttr('disabled');
  let select_not_disabled = document.querySelectorAll('div.fields-mapping select.map-to-select:not(:disabled)');
  if (select_not_disabled.length > 0) {
    for (let i = 0; i < select_not_disabled.length; i++) {
      if (!values.find(x => x == select_not_disabled[i].value) && select_not_disabled[i].value !== "") {
        values.push(select_not_disabled[i].value);
      }

      // disabled option
      for (let j = 0; j < values.length; j++) {
        let option = select_not_disabled[i].querySelector('option[value="'+values[j]+'"]')
        if (option.value !== select_not_disabled[i].value) {
          option.setAttribute('disabled', 'disabled');
        }
      }
    }
  }
}

function get_all_select_values () {
  let values = [];
  let select_not_disabled = document.querySelectorAll('div.fields-mapping select.map-to-select:not(:disabled)');
  if (select_not_disabled.length > 0) {
    for (let i = 0; i < select_not_disabled.length; i++) {
      if (!values.find(x => x == select_not_disabled[i].value) && select_not_disabled[i].value !== "") {
        values.push(select_not_disabled[i].value);
      }
    }
  }
  return values;
}

window.back_from_edit_recipients = (stage, app_id = null) => {
  var submit_button = document.getElementById('submit-form-button');

  if (stage == 'review') {
    var review = document.getElementById('review-mapping-fields');
    var mapping_fields = document.getElementById('mapping-fields');
    if (review && mapping_fields) {
      review.style.display = 'none';
      mapping_fields.style.display = 'block';
    }

    var review_hidden_field = document.getElementById('hidden-field-review');
    if (review_hidden_field) {
       review_hidden_field.value = null;
    }

    // update header
    var header_review = document.getElementById('recipient-status-review-fields');
    var header_map = document.getElementById('recipient-status-map-fields');
    if (header_review && header_map) {
      header_review.classList.remove('active');
      header_review.classList.add('disabled');
      header_review.querySelector('.title').classList.add('disabled');
      header_review.querySelector('.subtitle').classList.add('disabled');
      header_map.classList.add('active');
      header_map.querySelector('svg.header-icon').classList.remove('active');
    }

    submit_button.value = 'Review CSV';
    submit_button.removeAttribute('disabled');

    var button_back = document.getElementById('back-button');
    if (button_back) {
      button_back.blur();
      if (app_id) {
        // this is import contacts
        button_back.setAttribute('onclick', 'back_from_edit_recipients("contact", "'+app_id+'")')
      }
      else {
        // this is broadcast
        button_back.setAttribute('onclick', 'back_from_edit_recipients("setup")')
      }
    }
  }
  else if (stage == 'setup') {
    window.location = 'edit';
  }
  else if (stage == 'contact') {
    window.location = `/apps/${app_id}/contacts`;
  }

};

window.cancel_import_recipients = (map = 'broadcast', app_id = null) => {
  $('.import-recipients').hide();
  $('.select_recipients').hide();
  $('.update-recipients-new-existing').show();
  $('.recipients-select-import').show();
  $('.header-icon').removeClass('active');
  $('#recipient-status-add-leads').addClass('active');
  $('#recipient-status-map-fields').removeClass('active').addClass('disabled');
  $('#recipient-status-review-fields').removeClass('active').addClass('disabled');
  // $('#back-button').attr('onclick', 'back_from_edit_recipients("setup")');

  $('#back-button').attr('onclick', 'back_from_edit_recipients("setup")');

  $('#submit-form-button').val('Next');
  $('.map-to-select').attr('disabled', 'disabled');
  $('#hidden-field-review').attr('disabled', 'disabled');
  $('#upload-new-recipients').hide();
  $('#import-file-box').hide();
  $('.option-content [data-id="upload-csv"]').removeClass('active');
  $('#modal-confirmation-reupload').modal('hide').hide();
  $('.modal-backdrop').hide();
};

function getPageList(totalPages, page, maxLength) {
  if (maxLength < 5) throw "maxLength must be at least 5";

  function range(start, end) {
    return Array.from(Array(end - start + 1), (_, i) => i + start);
  }

  var sideWidth = maxLength < 9 ? 1 : 2;
  var leftWidth = (maxLength - sideWidth*2 - 3) >> 1;
  var rightWidth = (maxLength - sideWidth*2 - 2) >> 1;
  if (totalPages <= maxLength) {
      // no breaks in list
      return range(1, totalPages);
  }
  if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // no break on left of page
      return range(1, maxLength - sideWidth - 1)
          .concat(0, range(totalPages - sideWidth + 1, totalPages));
  }
  if (page >= totalPages - sideWidth - 1 - rightWidth) {
      // no break on right of page
      return range(1, sideWidth)
          .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
  }
  // Breaks on both sides
  return range(1, sideWidth)
      .concat(0, range(page - leftWidth, page + rightWidth),
              0, range(totalPages - sideWidth + 1, totalPages));
}

function table_pagination(totalPages, currentPage, paginationSize) {
  // Replace the navigation items (not prev/next):
  $("#pagination-table-new-recipients li").slice(2, -2).remove();
  getPageList(totalPages, currentPage, paginationSize).forEach( item => {
      $("<li>").addClass("page-item")
              .addClass(item ? "current-page" : "disabled")
              .toggleClass("active", item === currentPage).append(
          $("<a>").addClass("page-link").attr({
              href: "javascript:void(0)"}).text(item || "...")
      ).insertBefore("#pagination-next-page");
  });
  // Disable prev/next when at first/last page:
  $("#pagination-first-page").toggleClass("disabled", currentPage === 1);
  $("#pagination-previous-page").toggleClass("disabled", currentPage === 1);
  $("#pagination-next-page").toggleClass("disabled", currentPage === totalPages);
  $("#pagination-last-page").toggleClass("disabled", currentPage === totalPages);
}



function proceed_table_pagination(numberOfItems = 0) {
  var limitPerPage = 10;
  // Total pages rounded upwards
  var totalPages = Math.ceil(numberOfItems / limitPerPage);
  // Number of buttons at the top, not counting prev/next,
  // but including the dotted buttons.
  // Must be at least 5:
  var paginationSize = 7;
  var currentPage = 1;

  function showPage(whichPage) {
      if (whichPage < 1 || whichPage > totalPages) return false;
      currentPage = whichPage;
      $("#broadcast-contacts-new-recipients tbody tr").removeClass('show-active').hide()
          .slice((currentPage-1) * limitPerPage,
                  currentPage * limitPerPage).addClass('show-active').show();
      table_pagination(totalPages, currentPage, paginationSize);
      // // Replace the navigation items (not prev/next):
      // $("#pagination-table-new-recipients li").slice(2, -2).remove();
      // getPageList(totalPages, currentPage, paginationSize).forEach( item => {
      //     $("<li>").addClass("page-item")
      //              .addClass(item ? "current-page" : "disabled")
      //              .toggleClass("active", item === currentPage).append(
      //         $("<a>").addClass("page-link").attr({
      //             href: "javascript:void(0)"}).text(item || "...")
      //     ).insertBefore("#next-page");
      // });
      // // Disable prev/next when at first/last page:
      // $("#first-page").toggleClass("disabled", currentPage === 1);
      // $("#previous-page").toggleClass("disabled", currentPage === 1);
      // $("#next-page").toggleClass("disabled", currentPage === totalPages);
      // $("#last-page").toggleClass("disabled", currentPage === totalPages);
      return true;
  }

  // Include the prev/next buttons:
  $("#pagination-table-new-recipients").append(
      $("<li>").addClass("page-item").attr({ id: "pagination-first-page" }).append(
        $("<a>").addClass("page-link").attr({
            href: "javascript:void(0)", title: 'First', 'data-toggle': 'tooltip'}).text("First")
      ),
      $("<li>").addClass("page-item").attr({ id: "pagination-previous-page" }).append(
          $("<a>").addClass("page-link").attr({
              href: "javascript:void(0)", title: 'Previous', 'data-toggle': 'tooltip'}).text("Prev")
      ),
      $("<li>").addClass("page-item").attr({ id: "pagination-next-page" }).append(
          $("<a>").addClass("page-link").attr({
              href: "javascript:void(0)", title: 'Next', 'data-toggle': 'tooltip'}).text("Next")
      ),
      $("<li>").addClass("page-item").attr({ id: "pagination-last-page" }).append(
        $("<a>").addClass("page-link").attr({
            href: "javascript:void(0)", title: 'Last', 'data-toggle': 'tooltip'}).text("Last")
      )
  );
  // Show the page links
  $("#broadcast-contacts-new-recipients tbody tr.show-active").show();
  showPage(1, numberOfItems);

  // Use event delegation, as these items are recreated later
  $(document).on("click", "#pagination-table-new-recipients li.current-page:not(.active)", function () {
      return showPage(+$(this).text(), numberOfItems);
  });
  $("#pagination-next-page").on("click", function (e) {
    return showPage(currentPage+1, numberOfItems);
  });
  $("#pagination-previous-page").on("click", function () {
    return showPage(currentPage-1, numberOfItems);
  });
  $("#pagination-first-page").on("click", function () {
    return showPage(1, numberOfItems);
  });
  $("#pagination-last-page").on("click", function () {
    return showPage(totalPages, numberOfItems);
  });
}

$(document).ready(function(){
  $('#broadcast-contacts-new-recipients').after('<div id="pagination-table-new-recipients" class="pagination table-pagination" style="display: flex;"></div>');
  // Number of items and limits the number of items per page
  var numberOfItems = $("#broadcast-contacts-new-recipients tbody tr").length;
  proceed_table_pagination(numberOfItems);
});

window.confirm_reupload = () => {
  $('#modal-confirmation-reupload').modal('show');
};

window.confirm_before_import = (invalid, errors = []) => {
  $('#modal-loading').modal('hide');
  $('#modal-confirmation-before-upload').modal('show');
  $('#body-confirm').html('');
  $('#body-confirm').append(`<p>We have found <span class="errors">${invalid} invalid data</span></p>`);
  var text_errors = '<ul>';
  errors.map(error => {
    text_errors += `<li>${error}</li>`;
  });
  text_errors += '</ul>';
  $('#body-confirm').append(`<p>Errors:</p> <div class="mx-3 errors">${text_errors}</div>`);
}

window.continue_import = () => {
  $("#form-submit").submit();
}
