import React, { useState, useEffect } from 'react';
import CheckBoxSVG from './CheckBoxSVG';
import CheckBoxItemSVG from './CheckBoxItemSVG';
import { FacebookSVG, WhatsAppSVG, TelegramSVG, LineSVG } from '../svg/services';

const StepOne = ({ active = true, isSubmit, service = 'whatsapp', serviceId = '', handleChangeInput, showHeader = true }) => {
  const [ placeholder, setPlaceholder ] = useState('Facebook Page ID');
  const [ exampleText, setExampleText ] = useState('131457810966226');

  useEffect(() => {
    if(service) {
      setPlaceholder(placeholderService(service));
      setExampleText(exampleDetailService(service));
    }
  }, [service]);

  const changeService = (service = null) => {
    if(service) {
      handleChangeInput('service', service);
    }
  };

  const placeholderService = (service = null) => {
    if(service){
      if(service === 'facebook') {
        return 'Facebook Page ID';
      }
      else if(service === 'whatsapp') {
        return 'Enter Phone Number';
      }
      else if(service === 'telegram') {
        return 'Enter username';
      }
      else if(service === 'line') {
        return 'QR Code URL';
      }
    }
    else {
      return '';
    }
  };


  const exampleDetailService = (service = null) => {
    if(service){
      if(service === 'facebook') {
        return '580097572666574';
      }
      else if(service === 'whatsapp') {
        return '+1(800) 123-45-67';
      }
      else if(service === 'telegram') {
        return 'Novochatbot';
      }
      else if(service === 'line') {
        return 'http://line.me/ti/p/2Y-7aApB8d';
      }
    }
    else {
      return '';
    }
  };
  return(
    <div className="section-item">

    
      <div className="header">
        { showHeader && (
          <div className="icon-checkbox">
            <CheckBoxSVG active={true} />
          </div>
        )}

        <div className="header-items">
          <div className={`item title ${active === true ? 'active' : ''}`}>
            Step 1
          </div>

          <div className={`item subtitle ${active === true ? 'active' : ''}`}>
            Choose your messaging app
          </div>
        </div>
      </div>

      <div className="content-data">

        <div className="col-10 col-sm-12 p-0 d-flex flex-row align-items-center first-step content-fill">
          <div className="list col-md-5 col-sm-4 pl-0">

            <div className={`item ${active ? 'active' : 'disabled'}`} onClick={() =>changeService('whatsapp')}>
              <div className="checkbox-input d-flex">
                <input 
                  className="form-check-input ml-0 position-relative" 
                  type="radio"  
                  id="service-whatsapp" 
                  value="whatsapp"
                  name="service"
                  disabled={!active || isSubmit}
                  checked={service === 'whatsapp'}
                  onChange={(e) => handleChangeInput('service', e.target.value)} />

                  <div className={`form-check-label d-flex ${ active ? 'active' : '' }`} htmlFor="service-whatsapp">
                    <div className={`icon-service ml-3 ${!active && 'disabled'}`}>
                      <WhatsAppSVG />
                    </div>
                    <div className={`name ${active === true ? 'active' : ''}`}>
                      WhatsApp
                    </div>
                </div>
              </div>
            </div>

            <div className={`item ${active ? 'active' : 'disabled'}`} onClick={() =>changeService('facebook')}>
              <div className="checkbox-input d-flex">
                <input 
                  className="form-check-input ml-0 position-relative" 
                  type="radio"  
                  id="service-facebook" 
                  value="facebook"
                  name="service"
                  disabled={!active || isSubmit}
                  checked={service === 'facebook'}
                  onChange={(e) => handleChangeInput('service', e.target.value)} />

                  <div className={`form-check-label d-flex ${ active ? 'active' : '' }`} htmlFor="service-facebook">
                    <div className={`icon-service ml-3 ${!active && 'disabled'}`}>
                      <FacebookSVG />
                    </div>
                    <div className={`name ${active === true ? 'active' : ''}`}>
                      Facebook Messenger
                    </div>
                </div>
              </div>
            </div>

            <div className={`item ${active ? 'active' : 'disabled'}`} onClick={() =>changeService('telegram')}>
              <div className="checkbox-input d-flex">
                <input 
                  className="form-check-input ml-0 position-relative" 
                  type="radio"  
                  id="service-telegram" 
                  value="telegram"
                  name="service"
                  disabled={!active || isSubmit}
                  checked={service === 'telegram'}
                  onChange={(e) => handleChangeInput('service', e.target.value)} />

                  <div className={`form-check-label d-flex ${ active ? 'active' : '' }`} htmlFor="service-telegram">
                    <div className={`icon-service ml-3 ${!active && 'disabled'}`}>
                      <TelegramSVG />
                    </div>
                    <div className={`name ${active === true ? 'active' : ''}`}>
                      Telegram
                    </div>
                </div>
              </div>
            </div>

            <div className={`item ${active ? 'active' : 'disabled'}`} onClick={() =>changeService('line')}>
              <div className="checkbox-input d-flex">
                <input 
                  className="form-check-input ml-0 position-relative" 
                  type="radio"  
                  id="service-line" 
                  value="line"
                  name="service"
                  disabled={!active || isSubmit}
                  checked={service === 'line'}
                  onChange={(e) => handleChangeInput('service', e.target.value)} />

                  <div className={`form-check-label d-flex ${ active ? 'active' : '' }`} htmlFor="service-line">
                    <div className={`icon-service ml-3 ${!active && 'disabled'}`}>
                      <LineSVG />
                    </div>
                    <div className={`name ${active === true ? 'active' : ''}`}>
                      Line
                    </div>
                </div>
              </div>
            </div>

          </div>

          <div className="details d-flex justify-content-start col-md-7 col-sm-8 p-0">
            <div className={`detail-wrap ${!active && 'disabled'}`}>
              <div className="top">
                <div className="icon">
                  { service === 'facebook' && (
                    <FacebookSVG />
                  )}
                  { service === 'whatsapp' && (
                    <WhatsAppSVG />
                  )}
                  { service === 'telegram' && (
                    <TelegramSVG />
                  )}
                  { service === 'line' && (
                    <LineSVG />
                  )}
                </div>
                <div className="title">
                  { service === 'facebook' && (
                    <>
                      Facebook Messenger
                    </>
                  )}
                  { service === 'whatsapp' && (
                    <>
                      WhatsApp
                    </>
                  )}
                  { service === 'telegram' && (
                    <>
                      Telegram
                    </>
                  )}
                  { service === 'line' && (
                    <>
                      Line
                    </>
                  )}
                </div>
              </div>

              <div className="content-form">
                <input 
                  type="text" 
                  className="text-input" 
                  placeholder={placeholder}
                  defaultValue={serviceId}
                  required={true}
                  disabled={!active || isSubmit}
                  onChange={(e) => handleChangeInput('service_id', e.target.value)} />

                <div className="example-wrap">
                  <div className="example">
                    Example: { exampleText }
                  </div>

                  { service === 'facebook' && (
                    <div className="link">
                      <a href={"https://www.facebook.com/help/1503421039731588/"} className="link-anchor" target="_blank">Get my page ID</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
