import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import SvgSearchIconPath from "images/svgs/search.svg";
import SvgFilterIconPath from "images/svgs/filter.svg";
import SvgCloseIconPath from "images/svgs/close.svg";
import FilterTags from './FilterTags';
import TableBroadcastRecipients from './TableBroadcastRecipients';

const ExistingRecipients = ({ appId, broadcastId, isBroadcastSent, currentRecipients = [], domainURL = '' }) => {
  const [ searchText, setSearchText ] = useState('');
  const [ showFilter, setShowFilter ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const tableRef = useRef();
  const tagsRef = useRef();

  useEffect(() => {
    var table = $('.table-responsive');
    
    if (table) {
      if (showFilter) {
        table.css({ position: 'relative', bottom: '8.5rem' });
      }
      else {
        table.css({ position: '', bottom: '' });
      }
    }
  },
  [showFilter])

  const handleSearchTextChange = _.debounce((value) => {
    setSearchText(value);
  }, 300);


  const handleChangeShowFilter = (value = null) => {
    if(value !== null) {
      setShowFilter(value);
    }
    else setShowFilter(!showFilter);
  };

  const _handleSetContactData = (data) => {
    tableRef.current.handleFilterTags(data.data, data.meta);
  };
  
  if(!appId || !broadcastId) return null;
  return(
    <>
      <div className="d-flex flex-column align-items-center w-100 pl-0 py-2 mb-2">
        <div className="d-flex flex-row align-items-center w-100">
          {/* <img src={SvgSearchIconPath} width={16} height={16} />
          <input 
            type="text"
            className="w-100 mx-2 search-input no-border"
            defaultValue={searchText}
            placeholder={'Search contact here'}
            onChange={(e) => handleSearchTextChange(e.target.value)} /> */}

            <div className="d-flex" style={{ flex: 1, position: 'relative', left: '-1.5rem' }}>
              <div className="search-icon-svg"></div>
                <input
                  type="text"
                  className="w-100 pl-5 search-input no-border"
                  defaultValue={searchText}
                  placeholder={'Search contact here'}
                  onChange={(e) => handleSearchTextChange(e.target.value)} />
            </div>

        
          <button type="button" className="button-icon p-2" onClick={() => handleChangeShowFilter(true)}>
            <img src={SvgFilterIconPath} width={16} height={16} className="svg-stroke" />
          </button>
        </div>

        { showFilter && (
          <div className="w-100 d-flex justify-content-end" id="search-filter-tags-wrap">
            <div className="flex-column border search-filter-tags-wrap">
              <div className="d-flex align-items-end justify-content-end m-3 p-1 close-icon-button" id="close-icon-button" >
                <button type="button" className="button-icon p-0" onClick={() => handleChangeShowFilter(false)}>
                  <img src={SvgCloseIconPath} className="svg-stroke" width={9} height={9} />
                </button>
              </div>

              <FilterTags
                ref={tagsRef}
                appId={appId} 
                broadcastId={broadcastId} 
                domainUrl={domainURL} 
                handleSetContactData={(data) =>_handleSetContactData(data)}
                searchText={searchText} />
            </div>
          </div>
        ) }
        
      </div>

      <TableBroadcastRecipients
        ref={tableRef}
        tagsRef={tagsRef}
        isSearchLoading={isLoading}
        searchText={searchText}
        isBroadcastSent={isBroadcastSent}
        currentRecipients={currentRecipients}
        domainURL={domainURL}
        appId={appId}
        broadcastId={broadcastId} />
    </>
  );
};

export default ExistingRecipients;