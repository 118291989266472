import React, { useEffect, useState } from "react";
import Toast from "./Toast";

const Notifications = ({ messages }) => {
  // const messages = JSON.parse(messagesText);
  const [list, setList] = useState([]);

  useEffect(() => {
    const messagesObj = JSON.parse(messages);
    setList([...list, ...messagesObj]);
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        deleteToast(list[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(2000);
    };
  }, [list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    setList([...list]);
  };

  return (
    <div id="notifications">
      {list.map((obj, index) => {
        {
          const key = Object.keys(obj)[0];
          const value = Object.values(obj)[0];
          return (
            <>
              <Toast
                id={index}
                notificationType={key}
                message={value}
                clickHandler={deleteToast}
              />
            </>
          );
        }
      })}
    </div>
  );
};

export default Notifications;
