import React from "react";
import PropTypes from "prop-types";
import dateFormat from "dateformat";
import Gallery from "react-grid-gallery";
import ModalImage from "react-modal-image";
import { Twemoji } from 'react-emoji-render';

export const NoteMessage = ({ message, locale }) => {
  let imgFile = false;
  let file = null;
  let agent_name = message.agent_name;

  if(message.status == 'sent' && agent_name == null){
    agent_name = 'Message Bot';
  }

  if(message.attachments.length){
    file = message.attachments[0];
    imgFile = file.type.split('/')[0]=='image';
  }

  const date = new Date(message.created_at); // Replace this with your actual date, e.g., created_at

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "Asian/Singapore",
    locale,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);

  return (
    <div className="row-fluid" id={message.id}>
      <div className="col-12 message">
        <div className="sent_message bg-warning text-black-50">
          <span className="mb-2 d-block"><strong>{agent_name}</strong> <small>left a note</small></span>
          {message.content && <Twemoji svg text={message.content} onlyEmojiClassName="novochat-emoji"/>}

            {file && (
              <span className={`file-attachment-message-preview ${!imgFile ? "file-border" : ""}`}>
                {imgFile ? (
                  <ModalImage
                    className="img-preview-file-attached"
                    small={file.url}
                    medium={file.url}
                    large={file.url}
                    alt={message.content}
                    hideDownload={true}
                    hideZoom={true}
                    showRotate={false}
                  />
                ) : (
                  <a target="_blank" href={file.url}>Show file attachment &gt;</a>
                )}

              </span>
            )}
        </div>

        <span className="message-datetime">
          Only visible to your team, {formattedDate}
        </span>
      </div>
    </div>
  );
};

NoteMessage.defaultProps = {
  message: PropTypes.shape({
    content: "",
    file: "",
    created_at: "",
    status: "",
  }),
};

NoteMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    file: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default NoteMessage;
