function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

function checkbox_channel() {
  if($("input[type='checkbox']:checked.channel-list").length <= 0){
    $("form#form-widget-channels").attr("disabled", "disabled");
    $("#button-submit-form").attr("disabled", "disabled");
  }
}

window.add_timing_element = (el)=> {
  var newId = Date.now();
  var newTimingField = $(el).data("fields");

  $("#timing_form_content").append(newTimingField)
  var newRow = $(".new-timing").last();
  newRow.find(".start-time").attr("data-target", `#start-timepicker-${newId}`);
  newRow.find(".start-time").attr("id", `start-timepicker-${newId}`);
  newRow.find(".start-time-input label").attr("for", `start-timepicker-${newId}`);
  newRow.find(".start-time-input label").attr("data-target", `#start-timepicker-${newId}`);
  newRow.find(".end-time").attr("data-target", `#end-timepicker-${newId}`);
  newRow.find(".end-time").attr("id", `end-timepicker-${newId}`);
  newRow.find(".end-time-input label").attr("for", `end-timepicker-${newId}`);
  newRow.find(".end-time-input label").attr("data-target", `#end-timepicker-${newId}`);
  set_time_datetimepicker();
}

window.remove_timing_element = (el)=> {
  $(el).closest('.row').remove();
}

window.set_time_datetimepicker = (el)=> {
  $('.time-input-text').flatpickr({
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
  });
};

window.copy_to_clipboard = (title)=> {
  var copyText = $("#" + title +"Text");
  var value = copyText.val();
  var tempInput = document.createElement("input");
  tempInput.style = "position: absolute; left: -1000px; top: -1000px";
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.focus();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  window.jquery("#"+ title + "Copy").tooltip('hide')
          .attr('data-original-title', "Copied")
          .tooltip('show')
          .on("mouseleave", (function() {
         	 	window.jquery(this).attr('data-original-title', "Copy to clipboard")
         	}));
}

function live_preview_color(e) {
	if ($(e).hasClass("primary-color")) {
		$('body').find(".novochat-primary-color").css("background-color", $(e).val());
	}
}


$(window).on('load', function(){
 
  $('body').on('keydown','.time-input-text', function(e){
    return false
  });
	// Initialize bootstrap tooltip
	window.jquery($('body').find('[data-toggle="tooltip"]')).tooltip();

	 $('body').on('click', "input[name$='[display_pages]']", function(event){
    var radioValue = $("input[name$='[display_pages]']:checked").val();
    if(radioValue == 'all_pages'){
      $('.custom-page-table').hide()
    } else {
      $('.custom-page-table').show()
    }
  });

  $('.custom-page-table').on('click', '.remove_fields', function(event) {
    $(this).prev('input[name$="[_destroy]"]').val('1');
    $(this).closest('tr').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(".custom-page-table table tbody").append($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $('body').on('keyup', '#widget_setting_tagline', function(e){
    var value = $(this).val();
    $('body').find(".novochat-widget-tagline").text(value);
  })

  $('body').on('keyup', '#widget_setting_title', function(e){
    var value = $(this).val();
    $('body').find(".novochat-widget-title").text(value);
  })

  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        if ($('.novochat-text-logo').length > 0) {
          $('.novochat-text-logo').replaceWith(`<img src="${e.target.result}" class="novochat-image-logo">`)

          return
        }

        $('.novochat-image-logo').attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  $('body').on('click', "#multi-channel-wrap #button-submit-form", function() {
    var tab = $("#tab-active").val();
    var form = [];
    if (tab === 'channels') {
      var input = $("input[type='checkbox'].channel-list");
      if(input.is(":checked")){
        form = $('#form-widget-channels');
      }
    }
    else if (tab === 'settings') {
      form = $('#form-widget-settings');
    }
    if (form.length > 0) form[0].submit();
  });

  $('body').on('change', "#widget_setting_company_logo", function() {
    readURL(this);
  });

  $('body').on('keyup', '.color-text-input', function(e){
    var value = $(this).val();
    if(/^#([A-Fa-f0-9]{6}$)/.test(value)){
      // it's valid color hex
      $(this).next().find($("input[type=color]")).val(value);
      $(this).removeClass("is-invalid");
      $(this).parent(".color-picker").find("div.invalid-feedback").remove();
      $("#button-submit-form").removeAttr("disabled");
      $("form#form-widget-settings").removeAttr("disabled");
			live_preview_color(this);
    }
    else{
      $(this).addClass("is-invalid");
      $(this).parent(".color-picker").find("span.error-wrap").html("<div class='invalid-feedback' style='display: block;'>Please enter the correct hex color code.</div>")
      $("#button-submit-form").attr("disabled", "disabled");
      $("form#form-widget-settings").attr("disabled", "disabled");
    }
  });

  $('body').on('change', '.btn-color-picker',function(){
    $(this).parents("div.color-picker").find("input[type=text].color-text-input").val($(this).val())
    $(this).parents("div.color-picker").find("input[type=text].color-text-input").removeClass("is-invalid")
		live_preview_color(this);
  });

  $('body').on('click', '.btn-position',function(){
    var value = $(this).attr("data-value");
    $("input[type=hidden]#widget-settings-position").val(value);
    $(".btn-position").removeClass("btn-active");
    $(this).addClass("btn-active");
  });


  $('body').on('submit', '#form-widget-settings', function(e){
    var file = $("input[type=file].image").val();
    if(file && file.length && !isImage(file)){
      e.preventDefault();
      $("span#error-message-file-input").html("<div class='invalid-feedback' style='display: block;'>Please choose an image file.</div>");
    }
    else{
      $("span#error-message-file-input").html("");
    }
  });

  $('body').on('change', 'input[type=file].image', function(event) {
    var input = $(event.currentTarget);
    var file= input[0].files[0];
    if(!isImage(file.name)){
      $("input[type=submit]").attr("disabled", "disabled");
      $("form#form-widget-settings").attr("disabled", "disabled");
      $('.widgetLogoPreview').html("");
      $("span#error-message-file-input").html("<div class='invalid-feedback' style='display: block;'>Please choose an image file.</div>");
    }
    else{
      $("input[type=submit]").removeAttr("disabled");
      $("form#form-widget-settings").removeAttr("disabled");
      $("span#error-message-file-input").html("");
      var reader = new FileReader();
      reader.onload = function(e){
        var img = new Image();
        img.src = e.target.result;
        // $('.widgetLogoPreview').append('<a href="' + img.src + '" class="fancybox" id="fancybox" data-fancybox="images" data-height="66" ><img alt="company-logo" src="'+img.src+'" class="image-logo" /></a>');
        $('.widgetLogoPreview').html('<img alt="company-logo" class="image-logo" src="'+img.src+'" />');
      };
      reader.readAsDataURL(file);
    }
  });

  $("body").on('change', "input[type='checkbox'].channel-list", function(event){
    var input = $("input[type='checkbox'].channel-list");
    if(input.is(":checked")){
      input[0].setCustomValidity("")
      $("form#form-widget-channels").removeAttr("disabled");
      $("#button-submit-form").removeAttr("disabled");
    }
    else{
      input[0].setCustomValidity("Please select one of these options.");
      $("form#form-widget-channels").attr("disabled", "disabled");
      $("#button-submit-form").attr("disabled", "disabled");
    }
  });

  buttonRequire = window.document.getElementById("buttonPlatformRequirement")
  if (buttonRequire != null) {
    window.document.getElementById("buttonPlatformRequirement").click();
  }

  $("body").on('click', '.hide-widget-preview', function () {
    $("body").find(".novochat-widget-preview").addClass('d-none');
  });

  $("body").on('click', 'a[data-target="#widget"]', function () {
    if ($("body").find(".show-widget-preview").hasClass('active')) {
      $("body").find(".novochat-widget-preview").removeClass('d-none');
    }
  });

  $("body").on('click', '.show-widget-preview', function () {
    $("body").find(".novochat-widget-preview").removeClass('d-none');
  });

  $("body").on('click', "input[type='checkbox'].channel-list", function (e) {
    var channelList = $("input[type='checkbox']:checked.channel-list");
    var channelIds = $.map(channelList, function(e) {
      return {
        id: e.value,
        service: e.getAttribute('data-service'),
        service_name: e.getAttribute('data-service-name'),
        external_id: e.getAttribute('data-external-id')
      }
    });
    createChannelList(channelIds);
	// 	$.ajax({
	// 		type: "GET",
	// 		url: `/apps/${appId}/edit.js`,
	// 		data: { channels_preview: channelIds }
	// 	});
  });
  
  const telegramIcon = require('../../assets/images/svgs/icons/telegram.svg');
  const liveChatIcon = require('../../assets/images/svgs/icons/live_chat.svg');
  const facebookIcon = require('../../assets/images/svgs/icons/facebook.svg');
  const lineIcon = require('../../assets/images/svgs/icons/line.svg');
  const whatsappIcon = require('../../assets/images/svgs/icons/whatsapp.svg');
  const instagramIcon = require('../../assets/images/svgs/icons/instagram.svg');
  const chevronRightIcon = require('../../assets/images/svgs/icons/chevron_right.svg');

  function createChannelList (channelIds) {
    var wrap = $('.novochat-widget-list');

    if (wrap.length > 0) {
      wrap.html('');

      if (channelIds.length > 0) {
        var new_list = [];

        channelIds.map(data => {
          var link = "";
          var icon = "";
          if (data.service == 'whatsapp' || data.service == 'whatsapp_chatapi') {
            link = `https://wa.me/${data.external_id.replace( /^\D+/g, '')}`;
            icon = whatsappIcon.default;
          }
          else if (data.service == 'whatsapp_business') {
            link = `https://wa.me/${data.account_name}`;
            icon = lineIcon.default;
          }
          else if (data.service == 'line') {
            link = `http://line.me/ti/p/~${data.external_id}`;
            icon = lineIcon.default;
          }
          else if (data.service == 'telegram') {
            link = `https://t.me/${data.external_id}`;
            icon = telegramIcon.default;
          }
          else if (data.service == 'facebook') {
            link = `https://m.me/${data.external_id}`;
            icon = facebookIcon.default;
          }
          else if (data.service == 'live_chat') {
            link = `https://m.me/${data.external_id}`;
            icon = liveChatIcon.default;
          }
          else if (data.service == 'instagram') {
            link = ``;
            icon = instagramIcon.default;
          }

          new_list.push(`<li class="novochat-widget-list-item"> 
                        <a href="${link}" target="_blank"> 
                          <div class="widget-container">
                            <img src="${icon}" width="35" height="35" />
                            <span class="text-platform">${data.service_name}</span>
                            <img src="${chevronRightIcon.default}" class="novochat-chevron-right" />
                          </div>
                        </a> 
                      </li>`);

        })
      }

      wrap.append(new_list);
    }
  }

})
