import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import ContactRow from "./ContactRow";
import LoadingPage from "../shared/LoadingPage";
import axios from "axios";

export const ContactList = (props) => {
  const { is_loading, contact_list, current_page, per_page, app_id, domain_url, base_url, exportContacts, handleDelete, selectedContacts, handleSelectContacts, handleContactList } = props;
  const request_url = domain_url+'/apps/'+app_id+'/contacts'

  const [ checkedAll, setCheckedAll ] = useState(false);
  const [show, setShow] = useState(false); // modal confirm to delete selected
  const [showLoading, setShowLoading] = useState(false); // modal loading

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseLoading = () => setShowLoading(false);
  const handleShowLoading = () => setShowLoading(true);


  useEffect(() => {
    if (selectedContacts.length === contact_list.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  },[selectedContacts]);

  function _handleSelectedAll (e) {
    let updatedValue = e.target.checked;
    setCheckedAll(updatedValue);
    if (updatedValue) {
      handleSelectContacts(null, true);
    } else {
      handleSelectContacts(null, false);
    }
  }

  function _confirmDelete (e) {
    handleShow();
    e.currentTarget.blur();
  }
  
  
  function _deleteSelectedContacts (e) {
    handleClose();
    handleShowLoading();
    if (selectedContacts.length > 0) {
      const url = domain_url + "/apps/" + app_id + base_url + "/delete_multiple";
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "DELETE",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache"
        },
        data: {
          selected_contacts: selectedContacts
        }
      })
        .then((response) => {
          let response_data = response.data;
          if (response_data) {
            if (response_data.status == 'error') {
              toastr.error(response_data.message || 'Oops! Error occured.');
            } else {
              handleContactList();
              handleSelectContacts(null, false, true);
              toastr.success(response_data.message || 'Delete selected contacts successfully.');
            }
          }
          handleCloseLoading();
        })
        .catch((error) => {
          toastr.error(error.message);
          handleCloseLoading();
        });
    }
  }


  useEffect(() => {
    if (contact_list.length == 0 && !is_loading) {
      if (current_page > 1) {
        handleContactList(current_page-1);
      }
    } else {
      let current_contact_list = contact_list.map((data) => data.attributes.id);
      let selected = selectedContacts.filter(item => current_contact_list.includes(item));
      if (contact_list.length > 0 && selected.length == contact_list.length) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    }
  },
  [contact_list, selectedContacts]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Selected Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Are you sure want to delete
            <span className="semi-bold">{` ${selectedContacts.length}`}</span>
            {` selected contact${selectedContacts.length > 1 ? 's' : ''}?`}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={_deleteSelectedContacts}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoading} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="component-padding">
              <div className="spinner-border text-primary-color" role='status' style={{ width: "3rem", height: "3rem", color: "#161B7F" }} />
            </div>
            <div className="text-center">
              <div className="semi-bold">Please wait a moment..</div>
              We are currently processing your data, do not close your browser
            </div>
          </div>
        </Modal.Body>
      </Modal>

      { selectedContacts.length > 0 && (
        <div className="component-padding">
          <DeleteSelectedContacts count={selectedContacts.length} deleteSelectedContacts={_confirmDelete} />
          <ExportSelectedContacts count={selectedContacts.length} exportSelectedContacts={exportContacts} />
        </div>
      )}

      <div className="component-padding">
        <div className="table-responsive">
          <table className="table table-hover contacts-table">
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    // value={checkedAll}
                    checked={checkedAll}
                    onChange={(e) => _handleSelectedAll(e)} />
                </th>
                <th scope="col">Name</th>
                <th scope="col">Channels</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Tags</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody id="contact-list">
              {is_loading ? ( <EmptyRow message="Loading"/> ) :
                contact_list && contact_list.length > 0 ?
                  ( <ContactRow
                      dataList={contact_list}
                      currentPage={current_page}
                      handleDelete={handleDelete}
                      requestUrl={request_url}
                      perPage={per_page}
                      selectedContacts={selectedContacts}
                      handleSelectContacts={handleSelectContacts}
                      checkedAll={checkedAll} /> )
                : ( <EmptyRow message="No data"/> )
              }
            </tbody>
          </table>
        </div>
      </div>

      { selectedContacts.length > 0 && (window.innerHeight < 696 && contact_list.length >= 8) && (
        <>
          <DeleteSelectedContacts count={selectedContacts.length} deleteSelectedContacts={_confirmDelete} />
          <ExportSelectedContacts count={selectedContacts.length} exportSelectedContacts={exportContacts} />
        </>
      )}
    </>
  );
};

const EmptyRow = ({message}) => {
  return(
    <tr scope="row">
      <td colSpan={7}>
        <p className="center">{message}</p>
      </td>
    </tr>
  )
}

const DeleteSelectedContacts = ({ count = 0, deleteSelectedContacts }) => {
  return (
    <button
        type="button"
        className="btn btn-danger"
        onClick={(e) => deleteSelectedContacts(e)}>
        {`Delete Selected (${count})`}
    </button>
  );
}

const ExportSelectedContacts = ({ count = 0, exportSelectedContacts }) => {
  return (
    <button
        type="button"
        className="btn btn-default btn-table-export"
        onClick={(e) => exportSelectedContacts(e)}
        data-export="false">
        {`Export Selected (${count})`}
    </button>
  );
}

export default ContactList;
