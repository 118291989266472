import React from "react";
import {
  Modal,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Table,
} from "react-bootstrap";
import _ from "lodash";
import axios from "axios";
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import InfiniteScroll from "react-infinite-scroll-component";

class SearchConversation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      app_id: this.props.app_id,
      domain_url: this.props.domain_url,
      base_url: "/contacts",
      search_messages_url: "/conversations/search/messages",
      current_contact_page: 1,
      current_messages_page: 1,
      cursor: 0,
      is_loading: true,
      show_modal: false,
      fetch_contact: false,
      search_text: "",
      contact_list: [],
      empty_contact_list: true,
      conversation_list: [],
      show_conversation_list: false,
      is_loading_conversations: false,
      message_list: []
    };

    this._findContact = _.debounce(this._findContact, 1000);
  }

  componentDidMount = () => {
    const { showModal } = this.props;

    this.setState({
      is_loading: false,
      show_modal: showModal,
    });

    document.addEventListener('click', this.handleClickOutside);

    // cleanup
    return () => {
      document.removeEventListener('click', this.handleClickOutside);
    };
  };

  handleClickOutside = (e) => {
    if (!e.target.closest('.contact-list')) { // check if element or parents has class
      this.setState({
        show_contact_list: false,
      });
    }
  }

  _handleClose = () => {
    const { changeModalSearchConversation } = this.props;

    this.setState(
      {
        show_modal: false,
        is_loading: false,
        search_text: "",
      },
      () => {
        changeModalSearchConversation();
      }
    );
  };

  _handleSubmit = () => {};

  _handleClearSearchText = () => {
    this.setState({
      search_text: "",
      is_loading: false,
      show_contact_list: false,
    });
  };

  _handleChangeSearchText = (event) => {
    const value = event.target.value;

    this.setState(
      {
        search_text: value,
        show_contact_list: false,
        show_conversation_list: false,
      },
      () => {
        const { search_text } = this.state;

        if (!!search_text) {
          // search contact
          this.setState(
            {
              fetch_contact: true,
            },
            () => {
              this._findContact();
            }
          );
        } else {
          this.setState({
            fetch_contact: false,
            contact_list: [],
            empty_contact_list: false,
            show_conversation_list: false,
          });
        }
      }
    );
  };

  _findContact = () => {

    this.setState({
      message_list: [],
    });

    const _this = this;
    const {
      base_url,
      search_messages_url,
      domain_url,
      app_id,
      current_contact_page,
      search_text,
    } = this.state;

    const url = `${domain_url}/apps/${ app_id + base_url }?by_keyword=${search_text}`;

    const url_messages = `${domain_url}/apps/${ app_id + search_messages_url }?by_keyword=${search_text}`;

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "GET",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: current_contact_page,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == "success") {
            var response_data = response.data.data;
            if (response_data) {
              var state = {
                show_contact_list: true,
                contact_list: response_data.data,
                empty_contact_list:
                  response_data.data.length > 0 ? false : true,
              };
              _this._setState(state);
            }
          } else {
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        } else {
          toastr.error("Error occured!");
        }
      })
      .catch((error) => {
        toastr.error("There was an error while finding the contact.");
      });
    
    if (search_text.length > 3) {
      axios({
        method: "GET",
        url: url_messages,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        this.setState({
          message_list: response.data.data,
        });
      })
    }

    this.setState({
      fetch_contact: false,
    });
  };

  _loadMessages = () => {
    let page = this.state.current_messages_page;
    const _this = this;
    const {
      search_messages_url,
      domain_url,
      app_id,
      search_text,
    } = this.state;
    const url_messages = `${domain_url}/apps/${ app_id + search_messages_url }?by_keyword=${search_text}&page=${page}`;
    axios({
      method: "GET",
      url: url_messages,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {

        this.setState({
          message_list: this.state.message_list.concat(response.data.data),
          current_messages_page: this.state.current_messages_page + 1
        });

      })
  }

  _findConversations = (id) => {
    this.setState({
      is_loading_conversations: true,
      show_conversation_list: false
    }, () => {
      const _this = this;
      const {
        base_url,
        domain_url,
        app_id,
      } = this.state;
      const url = `${domain_url}/apps/${app_id}/conversations?by_contact_id=${id}`;
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

      axios({
        method: "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      })
        .then((response) => {
          if (response.status == 200) {
            var response_data = response.data.data;
            if (response_data) {
              var state = {
                conversation_list: response_data,
                show_conversation_list: true,
                is_loading_conversations: false
              };
              _this._setState(state);
            } else {
              if (response.data.message) {
                toastr.error(response.data.message);
              }
            }
          } else {
            toastr.error("Error occured!");
          }
        })
        .catch((error) => {
          toastr.error("There was an error while finding the contact.");
        });
    })
  }

  _setState = (state) => {
    this.setState(state);
  };

  _handleSelectContact = (contact) => {
    this.setState({
      show_contact_list: false,
      search_text: contact.name,
      contact_list: [],
      fetch_contact: false,
      empty_contact_list: true,
      // conversation_list: contact.conversations.data,
      // show_conversation_list: true,
    }, () => {
      this._findConversations(contact.id);
    });
  };

  _formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  _goToConversation = (url) => {
    window.location = url;
  };

  render = () => {
    const {
      is_loading,
      show_modal,
      fetch_contact,
      search_text,
      contact_list,
      show_contact_list,
      empty_contact_list,
      conversation_list,
      domain_url,
      app_id,
      show_conversation_list,
      cursor,
      is_loading_conversations,
      message_list
    } = this.state;

    return (
      <Modal
        dialogClassName="search-conversations-modal"
        show={show_modal}
        onHide={() => this._handleClose()}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h3>Search</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-search"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <input
              type="text"
              aria-label="Search"
              className="form-control search-input"
              placeholder="Search"
              value={search_text}
              onChange={(event) => this._handleChangeSearchText(event)}
              onKeyPress={(event) => event.key==='Enter' && this.setState({ show_contact_list: false }) }
            />
            {fetch_contact && (
              <div className="spinner-loading">
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            )}
            <InputGroup.Append className="clear-search-text">
              <InputGroup.Text className="clear-text">
                <a className="btn btn-no-border" onClick={() => this._handleClearSearchText()}>Clear</a>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {show_contact_list && (
            <div className="contact-list">
              <ContactList
                dataList={contact_list}
                emptyContactList={empty_contact_list}
                handleSelectContact={this._handleSelectContact}
                cursor={cursor}
              />
            </div>
          )}

          {conversation_list && (
            <ConversationList
              showConversationList={show_conversation_list}
              dataList={conversation_list}
              domainUrl={domain_url}
              appId={app_id}
              formatAMPM={this._formatAMPM}
              goToConversation={this._goToConversation}
              isLoadingConversations={is_loading_conversations}
            />
          )}

          <div className="conversation-list">
            <p className="title">MESSAGES</p>
            <hr className="border" />
            <div
              id="scrollableDiv"
              style={{
                height: 500,
                overflow: 'auto',
              }}
            >
              <InfiniteScroll
                height={500}
                dataLength={message_list.length}
                next={this._loadMessages}
                threshold={150}
                loadMore={this._loadMessages}
                hasMore={true}
                scrollableTarget="scrollableDiv"
              >
                <Table responsive borderless hover className="conversations-table">
                  <thead></thead>
                  <tbody>
                    <MessagesRow
                      messages={message_list}
                      domain_url={domain_url}
                      app_id={app_id}
                      goToConversation={this._goToConversation}
                      formatAMPM={this._formatAMPM}
                      searchText={search_text}
                    />
                </tbody>
              </Table>
              </InfiniteScroll>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
}

const ContactList = ({
  dataList,
  handleSelectContact,
  emptyContactList,
  cursor,
}) => {
  var list = [];
  if (emptyContactList) {
    return <p className="no-contact">No contact found.</p>;
  }

  dataList.map((data, index) => {
    list.push(
      <a
        className={`contact-item ${index > 0 ? "border-top" : ""}`}
        key={data.attributes.id}
        onClick={() => handleSelectContact(data.attributes)}
      >
        {data.attributes.name}
        <br />
        <small className="text-muted">{data.attributes.email}</small>
      </a>
    );
  });
  return list;
};

const ConversationList = ({
  showConversationList,
  dataList,
  formatAMPM,
  domainUrl,
  appId,
  goToConversation,
  isLoadingConversations
}) => {
  return (
    <div className="conversation-list">
      <p className="title">CONVERSATIONS</p>
      <hr className="border" />
      <div className="conversation-list-wrap">
        { !showConversationList && isLoadingConversations && (
          <div className="spinner-loading border-0">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading ...
          </div>
        )}
        {showConversationList ? (
          dataList.length > 0 ? (
            <Table responsive borderless hover className="conversations-table">
              <thead></thead>
              <tbody>
                <ConversationRow
                  dataList={dataList}
                  formatAMPM={formatAMPM}
                  domain_url={domainUrl}
                  app_id={appId}
                  goToConversation={goToConversation}
                />
              </tbody>
            </Table>
          ) : (
            <p>No conversation.</p>
          )
        ) : null}
      </div>
    </div>
  );
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MessagesRow = ({
  messages,
  formatAMPM,
  domain_url,
  app_id,
  goToConversation,
  searchText
}) => {
  var list = [];
  messages.map((data, index) => {

    var tab = data.attributes.is_resolved ? 'resolved' : 'all';
    var directConversationUrl = `${domain_url}/apps/${app_id}/conversations?tab=${tab}&by_id=${data.attributes.conversation_id}&message_id=${data.attributes.id}&search_text=${searchText}&on_page=${data.attributes.on_page}`;
    var platform_service = "";
    if (data.attributes.platform_service == "line") {
      platform_service = <img src={SvgLineIconPath} width={25} height={25} />;
    } else if (data.attributes.platform_service == "telegram") {
      platform_service = (
        <img src={SvgTelegramIconPath} width={25} height={25} />
      );
    } else if (data.attributes.platform_service == "live_chat") {
      platform_service = (
        <img src={SvgLiveChatIconPath} width={25} height={25} />
      );
    } else if (data.attributes.platform_service == "facebook") {
      platform_service = (
        <img src={SvgFacebookIconPath} width={25} height={25} />
      );
    } else if (data.attributes.platform_service == "instagram") {
      platform_service = (
        <img src={SvgInstagramIconPath} width={25} height={25} />
      );
    } else if (
      data.attributes.platform_service == "whatsapp" ||
      data.attributes.platform_service == "whatsapp_chatapi" ||
      data.attributes.platform_service == "whatsapp_business"
    ) {
      platform_service = (
        <img src={SvgWhatsappIconPath} width={25} height={25} />
      );
    }

    var created_at = new Date(data.attributes.created_at);
    var time = formatAMPM(created_at);
    created_at =
      monthNames[created_at.getMonth()] +
      " " +
      created_at.getDate() +
      ", " +
      created_at.getFullYear() +
      " " +
      time;

    list.push(
        <tr key={index} onClick={() => goToConversation(directConversationUrl)}>
          <td className="detail">
            {platform_service}
            <div className="detail-wrap">
              <span className="conversation-name">{data.attributes.agent_name ? data.attributes.agent_name : data.attributes.name}</span>
              <br />
              <span className="conversation-last-message-content">
                { data.attributes.content }
              </span>
              <br />
              <span className="conversation-last-updated">{created_at}</span>
            </div>
          </td>
          <td className="icon">
            <div className="icon-wrap">
              <i className="fa fa-caret-right icon-detail-right" />
            </div>
          </td>
        </tr>

    )
  })
  return list;
}

const ConversationRow = ({
  dataList,
  formatAMPM,
  domain_url,
  app_id,
  goToConversation,
}) => {
  var list = [];
  dataList.map((data, index) => {
    data = data.attributes;
    var tab = data.is_resolved ? 'resolved' : 'all';
    var directConversationUrl = `${domain_url}/apps/${app_id}/conversations?tab=${tab}&by_id=${data.id}`;
    var platform_service = "";
    if (data.platform_service == "line") {
      platform_service = <img src={SvgLineIconPath} width={25} height={25} />;
    } else if (data.platform_service == "telegram") {
      platform_service = (
        <img src={SvgTelegramIconPath} width={25} height={25} />
      );
    } else if (data.platform_service == "facebook") {
      platform_service = (
        <img src={SvgFacebookIconPath} width={25} height={25} />
      );
    } else if (data.platform_service == "instagram") {
      platform_service = (
        <img src={SvgInstagramIconPath} width={25} height={25} />
      );
    } else if (
      data.platform_service == "whatsapp" ||
      data.platform_service == "whatsapp_chatapi" ||
      data.platform_service == "whatsapp_business"
    ) {
      platform_service = (
        <img src={SvgWhatsappIconPath} width={25} height={25} />
      );
    }

    var updated_at = new Date(data.updated_at);
    var time = formatAMPM(updated_at);
    updated_at =
      monthNames[updated_at.getMonth()] +
      " " +
      updated_at.getDate() +
      ", " +
      updated_at.getFullYear() +
      " " +
      time;

    list.push(
      <tr key={index} onClick={() => goToConversation(directConversationUrl)}>
        <td className="detail">
          {platform_service}
          <div className="detail-wrap">
            <span className="conversation-name">{data.name}</span>
            <br />
            <span className="conversation-last-message-content">
              {data.last_message_content}
            </span>
            <br />
            <span className="conversation-last-updated">{updated_at}</span>
          </div>
        </td>
        <td className="icon">
          <div className="icon-wrap">
            <i className="fa fa-caret-right icon-detail-right" />
          </div>
        </td>
      </tr>
    );
  });
  return list;
};

export default SearchConversation;
