import React, { useRef, useState, useEffect  } from 'react';
import logo from '../../../assets/images/brand/logo-color.png';
import background_circle from '../../../assets/images/builders/background-circle-bottom.png';
import preview_text from '../../../assets/images/builders/preview.png';
import preview_arrow from '../../../assets/images/builders/preview-arrow.png';
import background_circle_fill from '../../../assets/images/builders/background-circle-fill-2.png';
import header from '../../../assets/images/builders/header.png';
import { Button } from 'react-bootstrap';
import TickGreen from '../../../assets/images/svgs/tick-circle-green.svg';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Axios from 'axios';
import _ from 'lodash';

const Dashboard = ({ userId = null, baseURL = 'https://app.novochat.co' }) => {
  const [ service, setService ] = useState('whatsapp');
  const [ serviceId, setServiceId ] = useState(''); // external_id
  const [ imageURL, setImageURL ] = useState(''); // logo
  const [ companyName, setCompanyName ] = useState('');
  const [ buttonText, setButtonText ] = useState('Chat with us');
  const [ callToAction, setCallToAction ] = useState('Start Chat');
  const [ greetingMessage, setGreetingMessage ] = useState('Hello there! How can I help you?');
  const [ position, setPosition ] = useState('right');
  const [ display, setDisplay ] = useState('everywhere');
  const [ email, setEmail ] = useState('');
  const [ nextContentActive, setNextContentActive ] = useState(false);
  const [ isSubmit, setIsSubmit ] = useState(false);
  const [ resetInputValue, setResetInputValue ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showPreview, setShowPreview ] = useState(false);
  const [ widthButton, setWidthButton ] = useState(185);
  const contentRef = useRef();

  useEffect(() => {
    if(serviceId || !(/^\s*$/.test(serviceId))) {
      setNextContentActive(true);
    }
    else {
      setNextContentActive(false);
      setShowPreview(false);
      let elements = document.querySelectorAll('[data-novochat-id="AppWidgetButton-single"]');
      if(elements.length > 0) {
        elements.forEach(x => x.remove());
      }
    }
  }, [serviceId]);

  useEffect(() => {
    if(isSubmit) {
      Axios({
        method: 'post',
        url: `${baseURL}/get_builder_code`,
        headers: {'Access-Control-Allow-Origin': '*'},
        data: formInputData()
      })
        .then(function (response) {
          if(response.data && response.data.status === 'success') {
            toastr.success(response.data.message);
          }
          setIsSubmit(false);
          setResetInputValue(true);
          setShowDetail(true);
          scrollToBottom();
        })
        .catch((error) => {
          toastr.error(error.message);
          setIsSubmit(false);
          setShowDetail(false);
        });
    }
  },
  [isSubmit]);

  useEffect(() => {
    if(resetInputValue) {
      setService('whatsapp');
      setServiceId('');
      setImageURL('');
      setCompanyName('');
      setButtonText('Chat with us');
      setCallToAction('Start Chat');
      setGreetingMessage('Hello there! How can I help you?');
      setPosition('right');
      setDisplay('everywhere');
      setEmail('');
      setShowPreview(false);
    }
  },
  [resetInputValue]);

  const handleClickCreateAccount = () => {
    window.location.href = '/users/sign_up';
  };

  const handleClickStarted = () => {
    if(contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  const handleChangeInput = _.debounce(( data, value ) => {
    if(data) {
      if(data === 'service') {
        setService(value);
      }
      else if(data === 'service_id') {
        setServiceId(value);
      }
      else if(data === 'image_url') {
        setImageURL(value);
      }
      else if(data === 'company_name') {
        setCompanyName(value);
      }
      else if(data === 'button_text') {
        setButtonText(value);
      }      
      else if(data === 'call_to_action') {
        setCallToAction(value);
      }
      else if(data === 'greeting_message') {
        setGreetingMessage(value);
      }
      else if(data === 'position') {
        setPosition(value);
      }
      else if(data === 'display') {
        setDisplay(value);
      }
      else if(data === 'email') {
        setEmail(value);
      }
    }
  }, 50);

  useEffect(() => {
    if (serviceId) {
      const delayDebounceFn = setTimeout(() => {
        setShowPreview(false);
        buttonBuilder();
      }, 1500)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [service, serviceId, imageURL, companyName, buttonText, callToAction, greetingMessage, position]);

  const handleClickGetCode = (e) => {
    e.preventDefault();
    setIsSubmit(true);
  };

  const formInputData = () => {
    return {
      single_buttons: {
        service: service,
        external_id: serviceId.replace(/[^a-z0-9]/gi, ""),
        image_url: imageURL,
        company_name: companyName,
        button_text: buttonText,
        call_to_action: callToAction,
        greeting_message: greetingMessage,
        position: position,
        display: display,
        email: email
      }
    }
  };

  const buttonBuilder = () => {
    let buttonBuilderPromise = new Promise((resolve, reject) => {
      document.getElementsByClassName('novochat-widget-window').forEach(function(nChatWindow){
        nChatWindow.remove();
      })
      document.getElementsByClassName('novochat-button').forEach(function(nChatButton){
        nChatButton.remove();
      })
      document.getElementsByClassName('novochat-widget-style').forEach(function(style){
        style.remove();
      })
      window.nchatFields = {
        service: service,
        external_id: serviceId,
        image_url: imageURL,
        company_name: companyName !== '' ? companyName : 'Novochat',
        button_text: buttonText,
        call_to_action: callToAction,
        greeting_message: greetingMessage,
        position: position,
        display: display,
        active: true
      }
      var d=document,h=d.getElementsByTagName('head')[0],s=d.createElement('script');
      s.type='text/javascript';
      s.async=!0;
      s.src=`${baseURL}/api/v1/widgets/single`;
      h.appendChild(s);
      resolve();
      reject();
    })
    buttonBuilderPromise.then(() => {
      setShowPreview(true);
      updatePreviewButton();
    });
    
  };

  const updatePreviewButton = () => {
    if(showPreview) {
      const delayDebounceFn = setTimeout(() => {
        let element = document.querySelector('[data-novochat-id="AppWidgetButton-single"]');
        if(element) {
          setWidthButton(element.offsetWidth);
        }
      }, 300)
  
      return () => clearTimeout(delayDebounceFn);
    }
  }

  useEffect(() => {
    updatePreviewButton();
  }, [showPreview]);

  const scrollToBottom = () => {
    var scrollingElement = (document.getElementById("container") || document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };

  return(
    <div className="single-buttons">
      <div className="container" id="container">

        <div className="container-fixed">
          <div className="section-top">
            <div className="content-top">

              <div className="nav-bar">
                <div className="icon">
                  <img src={logo} className="logo" />
                </div>

                <div className="actions">
                  <Button 
                    className="btn btn-primary"
                    onClick={() => handleClickCreateAccount()}>
                    Create an account
                  </Button>
                </div>
              </div>

              <div className="header">
                <div className="content">
                  <div className="right">
                    <div className="title">
                      Communicate with your customers on popular messenger apps
                    </div>

                    <div className="subtitle">
                      Engage with your website visitors and boost your sales!
                    </div>

                    <div className="details">
                      <div className="item">
                        <img src={TickGreen} width={24} height={24} className="icon-item" />

                        <div className="text-item">No coding required</div>
                      </div>

                      <div className="item m-left">
                        <img src={TickGreen} width={24} height={24} className="icon-item" />

                        <div className="text-item">Free</div>
                      </div>
                    </div>

                    <div className="actions">
                      <Button 
                        className="button button-primary secondary"
                        onClick={() => handleClickStarted()}>
                          Get your button now
                      </Button>
                    </div>
                  </div>

                  <div className="left">
                    <img className="image" src={header} /> 
                  </div>
                </div>

                <div className="background-section bottom-left">
                  <img src={background_circle} className="background-cirlce" />
                  <img src={background_circle_fill} className="background-circle-fill" />
                </div>
              </div>
            </div>
          </div>


          <div className="content-wrap" ref={contentRef}>
            <div className="title">
              Set up your button in just 3 steps
            </div>

            <div className="content-items">
              <form onSubmit={(e) => handleClickGetCode(e)}>
                <StepOne
                  service={service}
                  serviceId={serviceId}
                  handleChangeInput={handleChangeInput}
                  isSubmit={isSubmit} />

                <StepTwo
                  active={nextContentActive}
                  imageURL={imageURL}
                  companyName={companyName}
                  buttonText={buttonText}
                  callToAction={callToAction}
                  greetingMessage={greetingMessage}
                  display={display}
                  position={position}
                  handleChangeInput={handleChangeInput}
                  isSubmit={isSubmit} />

                <StepThree
                  active={nextContentActive}
                  userId={userId}
                  email={email}
                  handleChangeInput={handleChangeInput}
                  handleClickGetCode={handleClickGetCode}
                  isSubmit={isSubmit}
                  showDetail={showDetail} />

              </form>
            </div>

            { showPreview && (
              <div className="preview-button" id="preview-button" style={{ right: showPreview ? (position === 'left' ? 'unset' : `${widthButton}px`) : '12%', left: position === 'left' ? `${widthButton}px` : 'unset' }}>
                <img src={preview_text} height={35} width={116} className="mb-2" />
                <img src={preview_arrow} width={45} height={40} style={{ transform:  position === 'left' ? "scaleX(-1)" : 'unset' }} />
              </div>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
