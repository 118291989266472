import React from "react";

export const ContactDetails = () => {
  return (
    <div className="col-3">
      <h1>Test</h1>
    </div>
  );
};

export default ContactDetails;
