function tab_active() {
  var action = window.location.pathname.split("/").pop();
  if (action == "edit") {
    var id = window.location.hash;
    var second_hash = null;
    var third_hash = null;

    if (id) {
      id = window.location.hash.substring(1);
      var temp = id.split("#");
      if (temp.length > 1) {
        id = temp[0];
        if (temp[1]) {
          second_hash = temp[1];
        }
        if (temp[2]) {
          third_hash = temp[2];
        }
      }
    } else {
      id = "general";
      window.location.hash = "#general";
    }

    if (
      (id == "templates" && second_hash == null) ||
      (id == "integrations" && second_hash == null && third_hash == null)
    ) {
      $("#" + id + "-tab")[0].click();
    } else if (id == "integrations" && second_hash != null) {
      if (third_hash == null) {
        if (second_hash == "easystore"){
          $.get(
            "/apps/" + get_app_id() + "/integrations/" + second_hash
          );

        }
        else{
          $.get(
            "/apps/" + get_app_id() + "/integrations/" + second_hash + "_webhooks"
          );

        }

      }
    } else {
      $("div.tab-content div.tab-pane.settings-tab-content").removeClass(
        "active"
      );

      $("div.tab-content div.tab-pane#" + id).addClass("active");
    }

    $("#app-settings-tab li.nav-item, #app-settings-tab li.nav-item a.settings-tab-sidemenu").removeClass(
      "active"
    );
    $("#app-settings-tab li.nav-item a#" + id + "-tab").addClass("active");
    $("#app-settings-tab li.nav-item a#" + id + "-tab").closest("li").addClass("active");
  }
}

function get_app_id() {
  var url = window.location.pathname.substring(1).split("/");
  var index = url.indexOf("apps") + 1;
  return url[index];
}

window.onhashchange = function () {
  tab_active();
};

$(window).on("load", function () {
  // tab active
  tab_active();

  $("body").on("click", "#app-settings-tab li.nav-item a", function () {
    var target = $(this).attr("data-target");
    window.location.hash = target;
  });

  $("body").on(
    "change",
    "input[type='checkbox'].webhook-trigger-list",
    function (event) {
      var input = $("input[type='checkbox'].webhook-trigger-list");
      if (input.is(":checked")) {
        input[0].setCustomValidity("");
        $("form#form-webhook").removeAttr("disabled");
      } else {
        event.preventDefault();
        input[0].setCustomValidity("Please select one of these options.");
        $("form#form-webhook").attr("disabled", "disabled");
      }
    }
  );

  $("body").on("submit", "form#form-webhook", function (event) {
    $("input[type=submit]").attr("disabled", "disabled");
  });

  $("body").on("click", "div.integration-wrap a.integration-item", function () {
    var hash = "#" + $(this).attr("data-target");
    window.location.hash = "#integrations" + hash;
  });

  $("body").on(
    "click",
    "div.slack-integration-settings a#btn-new-slack-webhook",
    function () {
      window.location.hash = "#integrations#slack#new";
    }
  );

  $("body").on(
    "click",
    "div.slack-integration-settings a#edit-slack-webhook",
    function () {
      window.location.hash = "#integrations#slack#edit";
    }
  );

  // -------------- WEBHOOK ----------------- //
  handle_general_webhook();

  // Sidebar Popover
  $(function() {
    window.jquery('[data-toggle="hover-menu-popover"]').popover({
      html: true,
      sanitize: false,
      trigger: 'manual',
      container: 'body',
      content: function() {
        id = $(this).data('popoverId');
        return $(`#${id}-popover-content`).html();
      }
    }).on("mouseenter", function() {
      var _this = this;
      window.jquery(this).popover("show");
      $(".popover").on("mouseleave", function() {
        window.jquery(_this).popover('hide');
      });
    }).on("mouseleave", function() {
      var _this = this;
      setTimeout(function() {
        if (!$(".popover:hover").length) {
          window.jquery(_this).popover("hide");
          $('#sidebar').css({ 'width': '' });
          $('#sidebar a.sidebar-logo span').css({ 'opacity': '' });
          $('#sidebar small.subscription-end').css({ 'display': '' });
          $('#sidebar a.sidebar-item span').css({ 'opacity': '' });
        }
      }, 50);
    }).on("hidden.bs.popover", ()=>{
        $('#sidebar').css({ 'width': '' });
        $('#sidebar a.sidebar-logo span').css({ 'opacity': '' });
        $('#sidebar small.subscription-end').css({ 'display': '' });
        $('#sidebar a.sidebar-item span').css({ 'opacity': '' });
    }).on("shown.bs.popover", ()=>{
      $('#sidebar').css({ 'width': '285px' });
      $('#sidebar a.sidebar-logo span').css({ 'opacity': 1 });
      $('#sidebar small.subscription-end').css({ 'display': 'block' });
      $('#sidebar a.sidebar-item span').css({ 'opacity': 1 });
    });
  });

});

function handle_general_webhook() {
  $("body").on(
    "click",
    "div.slack-integration-settings a#btn-new-general-webhook",
    function () {
      window.location.hash = "#integrations#general#new";
    }
  );

  $("body").on(
    "click",
    "div.slack-integration-settings a#edit-general-webhook",
    function () {
      window.location.hash = "#integrations#general#edit";
    }
  );
}
