import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ContactPagination = (props) => {
  const { contact_list, current_page, per_page, total_data, total_pages, changePage, tags} = props;

  return (
    <>
      <div className="col-md-12 no-padding row-inline">
        {/* <div className="full">
          {'Showing ' + (contact_list.length ? (current_page == 1 ? '1' : (per_page + (current_page-1))) : '0') + ' to ' + (current_page*per_page <= total_data ? current_page*per_page : total_data) + ' of ' + total_data + ' entries'}
        </div> */}
        { total_pages > 0 && (
          <Pagination totalPages={total_pages} currentPage={current_page} changePage={changePage} />
        )}
      </div>
    </>
  );
};

const PaginationList = ({totalPages, currentPage, changePage}) => {
  var list = []
  if (totalPages <= 8){
    for (let index = 1; index <= totalPages; index++) {
      list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
    }

  }

  else if (totalPages > 7){

    if (currentPage !=1){
      list.push(<li key={1} className={"page-item"+(currentPage == 1 ? ' active' : '')}><a className="page-link" onClick={() => changePage(1)}>{1}</a></li>)
    }

    if (currentPage > 4){
      list.push(<li key="...." className="page-item" ><a className="page-link" >...</a></li>)
    }


    if (currentPage == 4){
      for (let index = currentPage - 2; index < currentPage ; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage == totalPages -2 ){
      for (let index = currentPage - 2; index < currentPage ; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage == totalPages -1 ){
      for (let index = currentPage - 3; index < currentPage ; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage == totalPages){
      for (let index = totalPages - 4; index < totalPages ; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage >2){
      for (let index = currentPage - 1; index < currentPage ; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }

    }

    list.push(<li key={currentPage} className="page-item  active"><a className="page-link" onClick={() => changePage(currentPage)}>{currentPage}</a></li>)
    if (currentPage == 1){
      for (let index = currentPage + 1; index <= currentPage + 4; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }

    }
    else if (currentPage == 2){
      for (let index = currentPage + 1; index <= currentPage + 3; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage == 3){
      for (let index = currentPage + 1; index <= currentPage + 2; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }
    else if (currentPage != totalPages){

      for (let index = currentPage + 1; index <= currentPage + 1; index++) {
        list.push(<li key={index} className={"page-item"+(currentPage == index ? ' active' : '')}><a className="page-link" onClick={() => changePage(index)}>{index}</a></li>)
      }
    }


    if (currentPage - totalPages <=-4 ){
      list.push(<li key="..." className="page-item" ><a className="page-link" >...</a></li>)

    }
    if (totalPages - currentPage == 3){
      list.push(<li key={totalPages -1 } className={"page-item"+(currentPage == totalPages -1 ? ' active' : '')}><a className="page-link" onClick={() => changePage(totalPages -1)}>{totalPages -1}</a></li>)
    }
    if (currentPage - totalPages <= -2){
      list.push(<li key={totalPages} className={"page-item"+(currentPage == totalPages ? ' active' : '')}><a className="page-link" onClick={() => changePage(totalPages)}>{totalPages}</a></li>)
    }



  }


  return list
};

const Pagination = ({totalPages, currentPage, changePage}) => {
  return(
    <div className="full">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={"page-item"+ (currentPage == 1 ? ' disabled' : '')}>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"tooltip"+(currentPage == 1 ? '-disabled' : '')}>Previous</Tooltip>}>
              <span className="d-inline-block">
                <a className="page-link" onClick={() => changePage(currentPage - 1)} tabIndex="-1">&lt;</a>
              </span>
            </OverlayTrigger>
          </li>
          <PaginationList totalPages={totalPages} currentPage={currentPage} changePage={changePage} />
          {/* <li key={i} className="page-item"><a className="page-link" href="#">{i}</a></li> */}
          <li className={"page-item"+ (totalPages == currentPage ? ' disabled' : '')}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={"tooltip"+(totalPages == currentPage ? '-disabled' : '')}>Next</Tooltip>}>
              <span className="d-inline-block">
                <a className="page-link" onClick={() => changePage(currentPage + 1)}>&gt;</a>
              </span>
            </OverlayTrigger>
          </li>
        </ul>
      </nav>
    </div>
  )
};

export default ContactPagination;
