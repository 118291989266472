import React from "react";
import Ripple from "./LoadingAnimation";

export const LoadingPage = () => {
  return (
    <div id="dashboard" className="container p-20">
      <div className="flex row p-20" style={{height: "100vh"}}>
        <div className="d-flex flex-column w-100 justify-content-center align-items-center text-center">
          <div className="spinner-grow text-primary" style={{width: "3rem", height: "3rem"}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <h1>Loading</h1>
          <p>Please wait.</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
