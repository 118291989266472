import React, { useEffect, Component } from "react";
import IncomingMessage from "../messages/IncomingMessage.jsx";
import OutgoingMessage from "../messages/OutgoingMessage.jsx";
import ConversationSendForm from "./ConversationSendForm.js";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import DashboardContext from "../DashboardContext";
import { MSG_STATUS } from "../../../constants/messages";
import ContactsPane from "../../contacts/ContactsPane";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

class MessageArea extends Component {
  static contextType = DashboardContext;

  state = {
    hasMoreItems: false,
    current: null,
    next: null,
    prev: null,
    last: null,
    isLoading: false,
    toggleContactInfo: false,
    selectedAgentId: null,
    selectedMessage: null,
    isLoadingNewMessages: false,
    goToMessage: false
  };

  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
  }
  handler(message) {
    this.setState({
      selectedMessage: message,
    });
  }

  componentDidMount() {
    // Load context
    const { state } = this.context;
    const { isLoadingNewMessages } = this.state;
    let url = `${state.domain_url}/api/live_chat/v1/messages?platform_id=${state.platformId}&conversation_id=${state.selectedConversation.id}&external_id=${state.externalId}&page_visit_url=${state.pageVisitUrl}`;
    if (state.messagesStartPage !== null) {
      url = url + `&message_id=${state.messageId}&page=${state.messagesStartPage}&prev_page=${state.messagesStartPage}`
    }

    this.setState({
      current: url,
      next: url,
      hasMoreItems: true,
    });
  }

  loadItems = () => {
    this.setState({ isLoading: true }, () => {});
    setTimeout(() => {
      // Load context
      const { setMessages, set_conversation_id_and_subscribe, state } = this.context;

      if (this.state.current != this.state.last) {
        this.setState({ current: this.state.next });

        const currentScrollHeightFromBottom = $(".message-list")[0].scrollHeight - $(".message-list").scrollTop();
        axios.get(this.state.next).then((response) => {
          let next = response.data.links.next;
          let prev = response.data.links.prev;
          let last = response.data.links.last;
          setMessages(
            response.data.data.reverse(),
            state.selectedConversation.id,
            currentScrollHeightFromBottom
          );

          if (this.state.next == this.state.current) {
            $(".message-list").scrollTop($(".message-list")[0].scrollHeight);
            if ((state.messageId !== null) && !this.state.goToMessage && state.messageId !== undefined) {
              setTimeout(() => {
                var elmnt = document.getElementById(`${state.messageId}`);
                elmnt.scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
                let text = $(`#${state.messageId}  > div > div > .text-content-message, #${state.messageId}  > div > div > div > .text-content-message`).text()
                $(`#${state.messageId} > div > div > .text-content-message, #${state.messageId}  > div > div > div > .text-content-message`).html(`<mark>${text}</mark>`);
                this.setState({ goToMessage: true });
              }, 1000);
            }
          }

          if (next != undefined || prev != undefined) {
            this.setState({
              hasMoreItems: true,
              next: next,
              prev: prev,
              last: last,
              isLoading: false,
            });
          }
        });
      } else {
        this.setState(
          {
            hasMoreItems: false,
          },
          () => {}
        );
      }
    }, 100);
  };

  toggleContactInfo = () => {
    this.setState({ toggleContactInfo: !this.state.toggleContactInfo });
  };

  setSelectedAgentId = (agentId) => {
    if (agentId) {
      this.setState({
        selectedAgentId: agentId,
      });
    }
  };

  handleScroll = () => {
    const { setMessages, state } = this.context;

    let setLoadNewMessages = $(".message-list")[0].scrollHeight-1738 < $(".message-list").scrollTop()

    if (setLoadNewMessages) {
      if (!this.state.isLoadingNewMessages) {
        this.loadNewMessages()

        this.setState({
          isLoadingNewMessages: true
        });
      }
    }
  }

  loadNewMessages = () => {
    const { setMessages, state } = this.context;
    const currentScrollHeightFromBottom = $(".message-list")[0].scrollHeight;
    if (this.state.prev) {
      axios.get(this.state.prev).then((response) => {
        let prev = response.data.links.prev;

        setTimeout(() => {
          this.setState({
            prev: prev,
            isLoadingNewMessages: false
          });
        }, 1000);

        setMessages(
          response.data.data.reverse(),
          state.selectedConversation.id,
          currentScrollHeightFromBottom,
          'bottom'
        );
        
        if (response.data.data[0]) {
          set_conversation_id_and_subscribe(response.data.data[0].conversation_id)
        }
      });
    }
  }

  render() {

    // Load React Context
    const { state } = this.context;

    // Adjust size of Area based on toggleContactInfo
    const adjustedSize = this.state.toggleContactInfo
      ? "col-xs-12 col-md-8 col-xl-6 p-0"
      : "col-xs-12 col-md-8 col-xl-9 p-0";

    let display_messages = [];
    state.messages.map((message_obj, i) => {
      let position = { first: false };
      let message = message_obj.attributes;

      // Set first message of group
      if (
        i == 0 ||
        message.status !== state.messages[i - 1].attributes.status ||
        message.name !== state.messages[i - 1].attributes.name ||
        message.agent_id !== state.messages[i - 1].attributes.agent_id
      ) {
        position.first = true;
      }

      if (message.status != MSG_STATUS.RECEIVED) {
        display_messages.push(
          <IncomingMessage
            key={i}
            isWidget={true}
            message={message}
            position={position}
            handler={this.handler}
            locale={state.locale}
            toggleContactInfo={this.toggleContactInfo}
          />
        );
      } else if (message.message_type == "log") {
      } else if (message.message_type == "note") {
      } else {
        display_messages.push(
          <OutgoingMessage
            key={i}
            isWidget={true}
            message={message}
            position={position}
            handler={this.handler}
            primaryColor={state.primaryColor}
            locale={state.locale}
            replyText={
              this.state.selectedMessage
                ? this.state.selectedMessage.content
                : null
            }
          />
        );
      }
    });

    return (
      <>
        <div id="message-area" className={adjustedSize}>
          <div
            className="message-list"
            onScroll={(ref) => (this.scrollParentRef = ref, this.handleScroll())}
          >
            <InfiniteScroll
              isReverse={true}
              pageStart={0}
              loadMore={this.loadItems}
              hasMore={this.state.hasMoreItems && !this.state.isLoading}
              threshold={50}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              useWindow={false}
            >
              {display_messages}
            </InfiniteScroll>
          </div>
          {this.state.selectedMessage ? (
            <div className="reply-message">
              <div className="reply-message-content">
                <div style={{ fontWeight: "700" }}>
                  {this.state.selectedMessage.agent_name
                    ? this.state.selectedMessage.agent_name
                    : this.state.selectedMessage.sender_name}
                </div>
                <div>{this.state.selectedMessage.content}</div>
              </div>
              <div
                onClick={() => this.handler(null)}
                className="cancel-btn"
              ></div>
            </div>
          ) : null}

          <ConversationSendForm
            key={state.selectedConversation.id}
            setSelectedAgentId={(agentId) => this.setSelectedAgentId(agentId)}
            quotedMessage={this.state.selectedMessage}
            handler={this.handler}
          />
        </div>

        {this.state.toggleContactInfo && <ContactsPane />}
      </>
    );
  }
}
const dropdownIcon = React.forwardRef(({ children, onClick }, ref) => (
  <div className="conversation-icon">
    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id="button-tooltip-2">More</Tooltip>}
    >
      <Button
        variant="light"
        className="btn btn-no-border icon"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <i className="fa fa-ellipsis-v" />
      </Button>
    </OverlayTrigger>
  </div>
));
export default MessageArea;
