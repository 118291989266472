import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import DashboardContext from "../dashboard/DashboardContext";
import UserAvatar from "../dashboard/UserAvatar";
import TagsContact from "./TagsContact";
import AssignContact from "./AssignContact";
import ConversationContacts from "./ConversationContacts";

export const ContactsPane = () => {
  const { state } = useContext(DashboardContext);

  const [contact, setContact] = useState({
    id: null,
    name: null,
    email: null,
    phone: null,
  });

  const [tags, setTags] = useState([]);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [loadTags, setLoadTags] = useState(false);


  useEffect(() => {
    if (state.selectedConversation.contact_id) {
      axios({
        method: "GET",
        url: `${state.domain_url}/apps/${state.app_id}/contacts/${state.selectedConversation.contact_id}`,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const responseData = response.data.data.data.attributes;
            // console.log('response in Contacts Pane', response_data)

            // Set contact tags
            const contactTagsTemp = responseData.tags.data;
            const contactTagSuggestions = responseData.tag_suggestions;
            const contactTags = [];
            contactTagsTemp.map((tag) => {
              contactTags.push(tag.attributes);
              contactTagSuggestions.splice(
                contactTagSuggestions.findIndex((x) => x.id === tag.id),
                1
              );
            });

            setContact({
              name: responseData.name,
              email: responseData.email,
              phone: responseData.phone,
              id: responseData.id,
            });

            setTags(contactTags);
            setTagSuggestions(contactTagSuggestions);
            setLoadTags(true);
          } else if (response.data.message) {
            toastr.error(response.data.message);
          } else {
            toastr.error("There was an error");
          }
        } else {
          toastr.error("There was an error");
        }
      });
    }
  }, []);

  return (
    <div className="col-xl-3 pr-0 contacts">
      <div className="preview-contact">
        <div className="conversation-title shadow-sm row align-items-center">
          <div className="col no-padding">
            <h3 className="no-margin">Profile</h3>
          </div>
        </div>
        <div className="preview-contact-wrap">
          <div className="detail-contact">
            <UserAvatar name={contact.name} />
            <div className="name">
              <p>{contact.name}</p>
            </div>

            <div className="contact-content text-center">
              {contact.email && <p>{contact.email}</p>}
              {contact.phone && <p>{contact.phone}</p>}
            </div>

            <div>
              <a
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
                href={`/apps/${state.app_id}/contacts/${contact.id}`}
              >
                Edit Contact
              </a>
            </div>

            <div>
              {loadTags && (
                <TagsContact
                  id={contact.id}
                  app_id={state.app_id}
                  domain_url={state.domain_url}
                  tags={tags}
                  suggestions={tagSuggestions}
                />
              )}
            </div>
            <div>
              {state.selectedConversation.conversation_contacts.length > 1 &&
                <>
                  <ConversationContacts
                    selectedConversation={state.selectedConversation}
                    app_id={state.app_id}
                    domain_url={state.domain_url}
                  />
                </>
              }
              <AssignContact
                app_id={state.app_id}
                domain_url={state.domain_url}
                selectedConversation={state.selectedConversation}
                contact={contact}
                setContact={setContact}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPane;
