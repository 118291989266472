// For REACT
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "@fortawesome/fontawesome-free/js/all";
import "bootstrap";
import "./src/widget.scss";
import "./src/widget_settings.scss";
import "./src/widget_transitions.scss";

// For Toastr notifications
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  positionClass: "toast-bottom-left",
  onclick: null,
  showDuration: "300",
  hideDuration: "1500",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
window.toastr = toastr;

window.novochatGoToSection = function (sectionName) {
  const sections = window.document.getElementsByClassName("novochat-section");
  // Hide current section
  for (let i = 0; i < sections.length; i++) {
    sections[i].classList.remove("novochat-current-section");
  }
  // Show next section
  const newSection = window.document.getElementsByClassName(
    `novochat-${sectionName}-section`
  )[0];
  newSection.classList.add("novochat-current-section");
};
