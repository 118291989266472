import React from "react";

const Toast = (props) => {
  const { id, message, notificationType, clickHandler } = props;

  const NOTIFICATION_COLORS = {
    success: "green",
    error: "red",
    notice: "yellow",
  };

  const capitalize = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  const role = () => {
    if (notificationType == "success") {
      return "success";
    } else if (notificationType == "error") {
      return "alert";
    }
  };
  return (
    <div
      className="toast fade show"
      role={role()}
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <svg
          className="bd-placeholder-img rounded mr-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect
            width="100%"
            height="100%"
            fill={NOTIFICATION_COLORS[notificationType]}
          ></rect>
        </svg>
        <strong className="mr-auto">{capitalize(notificationType)}</strong>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={() => clickHandler(id)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div
        className="toast-body"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export default Toast;
