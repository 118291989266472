import React, { useContext, useEffect, useState } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Form,
  FormControl,
  Badge,
} from "react-bootstrap";
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import EditIconPath from "images/svgs/edit.svg";
import DeleteIconPath from "images/svgs/trash.svg";

export const ContactRow = (props) => {
  const { dataList, currentPage, requestUrl, perPage, handleDelete, checkedAll, selectedContacts, handleSelectContacts } = props;


  function _selectContact (id) {
    handleSelectContacts(id);
  }

  var list = [];
  dataList.map((data, index) => {
    var checked_contact = selectedContacts.includes(data.attributes.id);
    list.push(
      <tr key={data.attributes.id}>
        {/* <td>{(currentPage - 1) * perPage + (index + 1)}</td> */}
        <td>
          <input
            type="checkbox"
            value={data.attributes.id}
            checked={checked_contact}
            onChange={() => _selectContact(data.attributes.id)} />
        </td>
        <td>{data.attributes.name}</td>
        <td>
          {data.attributes.channels.length ? (
            <div>
              <Channel channels={data.attributes.channels} />
            </div>
          ) : (
            "-"
          )}
        </td>
        <td>{data.attributes.phone}</td>
        <td>{data.attributes.email}</td>
        <td>
          <div className="d-flex flex-wrap" style={{ maxWidth: '20rem' }}>
            {data.attributes.tags.data.length ? (
              <Tags dataList={data.attributes.tags.data} />
            ) : (
              "-"
            )}
          </div>
        </td>
        <td>
          <div className="actions d-flex flex-md-row flex-column">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}
            >
              <a
                className="icons btn-icon mr-md-3 mr-0 mb-md-0 mb-3"
                href={requestUrl + "/" + data.attributes.id}
              >
                <EditIcon />
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}
            >
              <a
                className="icons btn-icon"
                onClick={() => handleDelete(data.attributes.id)}
              >
                <DeleteIcon />
              </a>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
    );
  });
  return list;
};

const Channel = ({ channels }) => {
  var list = [];

  const channelsUniqueByService = [
    ...new Map(channels.map((item) => [item["service"], item])).values(),
  ];

  channelsUniqueByService.map((data, index) => {
    if (data.service == "line") {
      list.push(
        <img
          key={index}
          src={SvgLineIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    } else if (data.service == "telegram") {
      list.push(
        <img
          key={index}
          src={SvgTelegramIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    } else if (data.service == "live_chat") {
      list.push(
        <img
          key={index}
          src={SvgLiveChatIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    } else if (data.service == "facebook") {
      list.push(
        <img
          key={index}
          src={SvgFacebookIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    } else if (data.service == "instagram") {
      list.push(
        <img
          key={index}
          src={SvgInstagramIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    } else if (data.service == "whatsapp" || "whatsapp_chatapi" || "whatsapp_business") {
      list.push(
        <img
          key={index}
          src={SvgWhatsappIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
        />
      );
    }
  });
  return list;
};

const Tags = ({ dataList }) => {
  var list = [];
  dataList.map((data, index) => {
    list.push(
      <div className="tag-search mr-2 mb-1" key={index}>
        {data.attributes.name}
      </div>
    );
  });
  return list;
};

const EditIcon = () => {
  return(
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.082 3.66675H3.66536C3.17913 3.66675 2.71282 3.8599 2.369 4.20372C2.02519 4.54754 1.83203 5.01385 1.83203 5.50008V18.3334C1.83203 18.8196 2.02519 19.286 2.369 19.6298C2.71282 19.9736 3.17913 20.1667 3.66536 20.1667H16.4987C16.9849 20.1667 17.4512 19.9736 17.7951 19.6298C18.1389 19.286 18.332 18.8196 18.332 18.3334V11.9167" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.957 2.29171C17.3217 1.92704 17.8163 1.72217 18.332 1.72217C18.8478 1.72217 19.3424 1.92704 19.707 2.29171C20.0717 2.65638 20.2766 3.15099 20.2766 3.66671C20.2766 4.18244 20.0717 4.67704 19.707 5.04171L10.9987 13.75L7.33203 14.6667L8.2487 11L16.957 2.29171Z" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

const DeleteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6H5H21" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 11V17" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 11V17" stroke="#C2C4D0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ContactRow;
