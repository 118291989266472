import React from 'react';

const CheckBoxSVG = ({ active = false }) => {
  if(active === true) {
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="12" fill="white" stroke="#161B7F" strokeWidth="8"/>
      </svg>
    );
  }

  return(
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="white"/>
      <circle cx="16" cy="16" r="12" stroke={'#161B7F'} strokeOpacity="0.2" strokeWidth="8"/>
    </svg>
  );
};

export default CheckBoxSVG;
