import React from 'react';
import  { Button } from 'react-bootstrap';

const Detail = () => {
  const handleClick = () => {
    window.location.href = '/users/sign_up';
  };

  return(
    <div className="detail-after-submit mt-5">
      <div className="title text-md-left text-sm-center mb-3">
        Want to add more superpowers to your chat button?
      </div>

      <div className="text-content text-md-left text-sm-center mb-4">
        Use your chat apps for multi-user customer support, or personalised WhatsApp broadcast messages to reach your customers!
      </div>

      <div className="title inside text-md-left text-sm-center mb-2">
        NovoChat's key features:
      </div>

      <div className="text-content text-md-left text-sm-left">
        <ul>
          <li>Broadcast messages on WhatsApp Business</li>
          <li>Multi-user inbox for all chat channels</li>
          <li>Personalize broadcast messages with custom placeholders</li>
          <li>Send abandoned cart recovery messages for e-commerce (via Shopify app)</li>
        </ul>
      </div>

      <div className="mt-5 d-flex flex-row align-items-center justify-content-center">
        <Button 
          className="button button-primary secondary"
          onClick={() => handleClick()}>
            Start Your Free 7-day Trial Today!
        </Button>
      </div>
    </div>
  );
};

export default Detail;