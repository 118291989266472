import React from "react";

export const Avatar = ({ props, workspaceName, onClick }) => {
  const boxStyle = {
    width: "45px",
    height: "45px",
    background: "#3586F6",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    borderRadius: "8px !important",
    fontSize: "14px",
    fontWeight: "500",
    boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.25)",
    textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  };

  const arrowDown = {
    borderColor: "white",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: "5px solid white",
    width: "0",
    height: "0",
    marginTop: "1px",
    marginLeft: "2px",
  };

  const workspaceInitials = workspaceName
    .replace(/(^[\s]+|[\s]+$)/g, '')
    .split(" ")
    .slice(0, 2)
    .map((letter) => letter[0].toUpperCase())
    .join("");

  // const arrowDown = {
  //   border: "solid white",
  //   borderWidth: "0 2px 2px 0",
  //   display: "inline-block",
  //   padding: "2px",
  //   marginLeft: "4px",
  //   transform: "rotate(45deg)",
  //   "-webkit-transform": "rotate(45deg)",
  // };

  return (
    <button type="button" className="btn" style={boxStyle} onClick={onClick}>
      <span>{workspaceInitials}</span>
      <i style={arrowDown} />
    </button>
  );
};

export default Avatar;
