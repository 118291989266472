import React from "react";
import { debounce } from "lodash";
import axios from "axios";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import ContactList from "./ContactList";
import ContactPagination from "./ContactPagination";
import ImportContact from "./modals/ImportContact";
import AddContact from "./modals/AddContact";
import LoadingPage from "../shared/LoadingPage";
import ConfirmationModal from "../shared/ConfirmationModal";
import { ContactTagFilter } from "./ContactTagFilter";

class Contact extends React.Component {
  _LOADING = {};

  constructor(props) {
    super(props);

    this.state = {
      base_url: "/contacts",
      by_keyword: "",
      app_id: this.props.app_id,
      domain_url: this.props.domain_url,
      contact_list: [],
      current_page: 1,
      total_data: 0,
      total_pages: 0,
      per_page: 0,
      show_modal: false,
      confirmation_modal: false,
      add_contact: {},
      all_errors_add_contact: [],
      country: "us",
      disabled_add_contact: false,
      tags_suggestion: [{ id: "test", text: "test" }],
      is_loading: true,

      import_file: null,
      show_modal_import: false,
      errorFile: false,
      app_type: "",

      // multiselect
      selectedContacts: [],

      tags: []
    };
    this._handleExportContacts = this._handleExportContacts.bind(this);
    this.reactTags = React.createRef();
  }

  componentDidMount = () => {
    this._getContactList();
  };

  componentWillUnmount = () => {
    clearInterval(this._LOADING);
  };

  _getCountryCode = () => {
    let _this = this;
    axios({
      method: "GET",
      url: "https://extreme-ip-lookup.com/json/",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          _this.setState({
            country: response.data.countryCode.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        _this.setState({
          country: "us",
        });
      });
  };
  _setIsLoading = (value) => {
    this.setState({ is_loading: value });
  };

  _setErrors = (value) => {
    this.setState({ all_errors_add_contact: value });
  };
  _setContactData = (response_data) => {
    var state = {
      contact_list: response_data.data,
      current_page: response_data.meta.current_page,
      total_data: response_data.meta.total_data,
      total_pages: response_data.meta.total_pages,
      per_page: response_data.meta.per_page,
      disabled_add_contact: false,
      confirmation_modal: false,
      show_modal: false,
      all_errors_add_contact: [],
      is_loading: false,
      app_type: response_data.meta.app_type,
    };
    this.setState(state);
  };

  _getContactList = () => {
    this.setState({
      is_loading: true
    }, () => {
      let _this = this;
      const { base_url, by_keyword, domain_url, app_id, current_page, tags } =
      this.state;
      let url = ''
      if (tags.length > 0) {
        url = domain_url + "/apps/" + app_id + "/contacts" + "/filter_contacts"
      }
      else {
        url = domain_url + "/apps/" + app_id + base_url + by_keyword;
      }
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

      axios({
        method: (tags.length > 0) ? "POST" : "GET",
        url: url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          page: current_page,
          tags: tags,
          app_id: app_id,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status == "success") {
              var response_data = response.data.data;
              if (response_data) {
                _this._setContactData(response_data);
              }
            } else {
              if (response.data.errors) {
                _this.setState({ all_errors_add_contact: response.data.errors });
              }
              if (response.data.message) {
                toastr.error(response.data.message);
              }
            }
          }
          _this._setIsLoading(false);
        })
        .catch((error) => {
          // console.log(error);
          toastr.error("There was an error");
        });
    });



    // this._LOADING = setInterval(() => {
    //   this.setState({ is_loading: false });
    // }, 1000);
  };

  _handleSubmitImport = () => {
    let _this = this;
    const { base_url, domain_url, app_id, import_file } = this.state;
    const url = domain_url + "/apps/" + app_id + base_url + "/import";
    const token = document.querySelector("[name=csrf-token]").content;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    toastr.success("Please wait. Uploading contacts...");

    // Create an object of formData
    const formData = new FormData();
    formData.append("contacts[file]", import_file, import_file.name);

    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == "success") {
            toastr.success("Successfully import contacts");
            // toastr.success(response.data.data.message);
            // _this.setState({all_errors_add_contact: [], show_modal: false})
            this._getContactList();
            this.setState({ show_modal_import: false });
          } else {
            if (response.data.errors) {
              _this.setState({ all_errors_add_contact: response.data.errors });
            }
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });
    // this.setState({disabled_add_contact: false})
  };

  _handleChangeState = (input, value) => {
    let add_contact_value = this.state.add_contact;
    if (input == "phone") {
      add_contact_value[input] = "+" + value;
    } else if (input == "tags") {
      add_contact_value[input] = value;
    } else {
      add_contact_value[input] = value.target.value;
    }

    this.setState({
      add_contact: add_contact_value,
    });
  };

  _handleChangeImportFile = (event) => {
    let file = event.target.files[0];
    // Validate file
    if (
      file &&
      file.size / 1024 / 1024 < 20 &&
      this.validateFileType(file.name)
    ) {
      // Update the state
      this.setState({ import_file: file, errorFile: false });
    } else {
      this.setState({ import_file: null, errorFile: true });
    }
  };

  validateFileType = (fileName) => {
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "csv" || extFile == "xls" || extFile == "xlsx") {
      return true;
    } else {
      toastr.error("Invalid file type");
      return false;
    }
  };

  _handleSubmitContact = () => {
    this.setState({ disabled_add_contact: true });
    let _this = this;
    const { base_url, domain_url, app_id, add_contact } = this.state;
    const url = domain_url + "/apps/" + app_id + base_url;
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "POST",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        contact: add_contact,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          // console.log('response', response)
          if (response.data.status == "success") {
            toastr.success(response.data.data.message);
            _this.setState({ all_errors_add_contact: [], show_modal: false });
            this._getContactList();
          } else {
            if (response.data.errors) {
              _this.setState({ all_errors_add_contact: response.data.errors });
            }
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });

    this.setState({ disabled_add_contact: false });
  };

  _handleSubmitDelete = (id) => {
    const { delete_confirm_id, base_url, domain_url, app_id } = this.state;
    let _this = this;
    const url =
      domain_url + "/apps/" + app_id + base_url + "/" + delete_confirm_id;
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "DELETE",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          // console.log('response', response)
          if (response.data.status == "success") {
            toastr.success(response.data.data.message);
            _this.setState({ confirmation_modal: false });
            this._getContactList();
          } else {
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });
  };

  _setState = (state) => {
    this.setState(state);
  };

  _searchByKeyword = debounce((value) => {
    this.debounceSearch(value);
  }, 500);

  debounceSearch = (value) => {
    if (value.length > 2 || value == "") {
      this.setState(
        {
          by_keyword: `?by_keyword=${value}`,
          current_page: 1,
        },
        () => {
          this._getContactList();
        }
      );
    }
  };

  _handleDelete = (id) => {
    this.setState({
      confirmation_modal: true,
      show_modal: false,
      delete_confirm_id: id,
    });
  };

  _changePage = (page) => {
    this.setState(
      {
        current_page: page,
      },
      () => {
        this._getContactList();
      }
    );
  };

  _addContact = (e) => {
    e.currentTarget.blur();
    this.setState({
      confirmation_modal: false,
      show_modal: true,
      disabled_add_contact: false,
      add_contact: {},
    });
  };

  _importContact = () => {
    window.location = 'contacts/import_contacts'
    // this.setState({
    //   show_modal_import: true,
    // });
  };

  _importEasystoreContact = () => {
    this.setState({
      is_loading: true
    }, () => {

    this.setState({ disabled_add_contact: true });
    let _this = this;
    const { base_url, domain_url, app_id, add_contact } = this.state;
    const url = domain_url + "/apps/" + app_id + base_url + '/import_easystore_contacts';
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "GET",
      url: url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    })
      .then((response) => {
        if (response.status == 200) {
          // console.log('response', response)
          if (response.data.status == "success") {
            alert(response.data.data.message);
            this._getContactList();
          } else {
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        }
      })
      .catch((error) => {
        toastr.error("There was an error");
      });

    this.setState({ disabled_add_contact: false });






      console.log("loading..")
    })
  };

  _closeConfirmDelete = () => {
    this.setState({
      confirmation_modal: false,
      show_modal: false,
      delete_confirm_id: null,
    });
  };

  _closeImportContact = () => {
    this.setState({
      show_modal_import: false,
    });
  };

  _closeAddContact = () => {
    this.setState({
      confirmation_modal: false,
      show_modal: false,
      disabled_add_contact: false,
      add_contact: {},
    });
  };

  _handleSelectContacts = (id = null, all = false, clear = false) => {
    if (!all && id) {
      let selected = [...this.state.selectedContacts];
      if (selected.includes(id)) {
        selected = selected.filter(function(item) { return item !== id});
      } else {
        selected = [...selected, id];
        selected = selected.filter(function(item, index){
          return selected.indexOf(item) == index;
        })
      }

      if (this.state.selectedContacts.includes('all')) {
        this.setState({
          selectedContacts: selected.slice(1)
        });
      }
      else {
        this.setState({
          selectedContacts: selected
        });
      }
    }
    else if (all) {
      let current_contact_list = this.state.contact_list.map((data) => data.attributes.id);
      let selected = this.state.selectedContacts.concat(current_contact_list);
      selected = selected.filter((item, index) => selected.indexOf(item) == index)
      this.setState({
        selectedContacts: selected
      });
    }
    else if (clear) {
      this.setState({
        selectedContacts: []
      })
    }
    else {
      // tick unselected all
      let current_contact_list = this.state.contact_list.map((data) => data.attributes.id);
      let selected = this.state.selectedContacts.filter(item => !current_contact_list.includes(item));
      this.setState({
        selectedContacts: selected
      });
    }
  }

  _handleContactList = (current_page = null) => {
    if (current_page) {
      this.setState({
        current_page: current_page
      }, () => {
        this._getContactList();
      })
    }
    else {
      this._getContactList();
    }
  }

  _handleExportContacts = (e) => {
    const all = e.target.dataset.export === 'true';
    e.target.blur();
    const { base_url, by_keyword, domain_url, app_id, tags, selectedContacts } = this.state;
    const url = domain_url + "/apps/" + app_id + base_url + by_keyword;
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      method: "GET",
      url: url,
      responseType: "blob",
      headers: {
        "Content-Type": "text/csv",
        Accept: "text/csv",
      },
      params: {
        tags: tags,
        selected_contacts: all ? null : selectedContacts
      }
    })
      .then((response) => {
        saveFile(`novochat-contacts-${new Date().getTime()}.csv`, "data:attachment/text", response.data);

        function saveFile (name, type, data) {
          if (data !== null && navigator.msSaveBlob)
              return navigator.msSaveBlob(new Blob([data], { type: type }), name);
          var a = $("<a style='display: none;'/>");
          var url = window.URL.createObjectURL(new Blob([data], {type: type}));
          a.attr("href", url);
          a.attr("download", name);
          $("body").append(a);
          a[0].click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      })
      .catch((error) => {
        toastr.error(`Error: ${error.message}`);
      });
  };

  _setTags = (value) => {
    this.setState({
      tags: value
    })
  }

  render = () => {
    const {
      is_loading,
      app_id,
      id,
      domain_url,
      base_url,
      by_keyword,
      contact_list,
      current_page,
      per_page,
      total_data,
      total_pages,
      show_modal,
      show_modal_import,
      confirmation_modal,
      country,
      all_errors_add_contact,
      disabled_add_contact,
      selectedContacts,
      tags,
      app_type
    } = this.state;

    return (
      <>
        <div className="contacts">
          <div className="container-margin">
            <h3 className="header-title-padding header-sticky">Contacts</h3>
            <div className="content-start">
              <div className="d-inline-block w-100">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip-import">Import contacts</Tooltip>} >
                  <Button
                    variant="default"
                    className="m-2"
                    onClick={() => this._importContact()} >
                    <i className="fa fa-download"></i> Import contacts
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip-export">Export all contacts listed in the table</Tooltip>} >
                  <Button
                    variant="default"
                    className="btn-export m-2"
                    onClick={this._handleExportContacts}
                    data-export="true" >
                    <i className="fa fa-file-export"></i> Export contacts
                  </Button>
                </OverlayTrigger>

                {
                  app_type === 'easystore' &&

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-import">Import easystore contacts</Tooltip>} >
                    <Button
                      variant="default"
                      className="m-2"
                      onClick={() => this._importEasystoreContact()} >
                      <i className="fa fa-download"></i> Import easystore contacts
                    </Button>
                  </OverlayTrigger>
                }

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip-2">Add</Tooltip>} >
                  <Button
                    className="btn btn-primary float-md-right m-2"
                    onClick={(e) => this._addContact(e)} >
                    <div className="add-icon"></div> New Contact
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="d-flex w-100 mt-3 mb-4">
                <form className="d-flex" style={{ flex: 1, position: 'relative', left: '-1.5rem' }}>
                  <div className="search-icon-svg"></div>
                  <input
                    className="form-control pl-5 no-border"
                    type="search"
                    placeholder="Search contact here"
                    aria-label="Search"
                    onChange={(e) => this._searchByKeyword(e.target.value)}
                  ></input>
                </form>

                <ContactTagFilter
                  domain_url={domain_url}
                  app_id={app_id}
                  setContactData={this._setContactData}
                  getContactList={this._getContactList}
                  setIsLoading={this._setIsLoading}
                  setErrors={this._setErrors}
                  tags={tags}
                  current_page={current_page}
                  setTags={this._setTags}
                />
              </div>

              <div id="contact-list-table">
                <ContactList
                  id={id}
                  app_id={app_id}
                  per_page={per_page}
                  domain_url={domain_url}
                  is_loading={is_loading}
                  by_keyword={by_keyword}
                  contact_list={contact_list}
                  current_page={current_page}
                  total_data={total_data}
                  base_url={base_url}
                  handleDelete={this._handleDelete}
                  selectedContacts={selectedContacts}
                  handleContactList={this._handleContactList}
                  handleSelectContacts={this._handleSelectContacts}
                  exportContacts={this._handleExportContacts}
                />
              </div>

              <div id="contact-pagination-content">
                <ContactPagination
                  contact_list={contact_list}
                  current_page={current_page}
                  per_page={per_page}
                  total_data={total_data}
                  total_pages={total_pages}
                  changePage={this._changePage}
                  tags={tags}
                  tags={tags}
                />
              </div>
            </div>
          </div>

          {show_modal_import && (
            <ImportContact
              handleClose={() => this._closeImportContact()}
              handleSubmitImport={() => this._handleSubmitImport()}
              handleChangeImportFile={(event) =>
                this._handleChangeImportFile(event)
              }
            />
          )}

          {show_modal && (
            <AddContact
              country={country}
              errors={all_errors_add_contact}
              disabled={disabled_add_contact}
              showModal={show_modal}
              handleChangeState={this._handleChangeState}
              handleSubmitContact={() => this._handleSubmitContact()}
              handleClose={() => this._closeAddContact()}
            />
          )}

          {confirmation_modal && (
            <ConfirmationModal
              modalTitle="Delete Record"
              modalContent="Deleting this contact will delete conversations and broadcast contacts associated with this contact. Are you sure want to delete this record?"
              showConfirmModal={confirmation_modal}
              handleSubmit={() => this._handleSubmitDelete()}
              handleClose={() => this._closeConfirmDelete()}
            />
          )}
        </div>
      </>
    );
  };
}

export default Contact;
