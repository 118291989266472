import React, { useContext } from "react";
import axios from "axios";
import DashboardContext from "./DashboardContext";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Skeleton from "react-skeleton-loader";
import Select from "react-select";
import _ from "lodash";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import OutsideClickHandler from "react-outside-click-handler";
import { MentionsInput, Mention } from 'react-mentions'

class ConversationSendForm extends React.Component {
  static contextType = DashboardContext;

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      errorFile: false,
      messageType: "default",
      uploadPercentage: 0,
      showSendButton: false,
      isLoading: true,
      isLoadingTemplate: false,
      isLoadingMessageContents: false,
      templates: [],
      mentionTemplates: [],
      messageContents: [],
      selectedTemplate: null,
      selectedMessageContent: null,
      showSendTemplateConfirmModal: false,
      showEmoji: false,
      messageContent: "",
      confirmCreateTemplate: false,
      showAskCreateTemplate: false,
      appId: '',
      bodyParams: []
    };

    this.getMessageContents = _.debounce(this.getMessageContents, 500);
    this.getTemplate = _.debounce(this.getTemplate, 500);
  }

  componentDidMount = () => {
    this.setState({
      isLoading: false,
      showSendButton: true,
    });
    this.getTemplate();
  };

  handleClick(event) {
    let message =
      document.getElementById("conversation-textarea") !== null
        ? document.getElementById("conversation-textarea").value
        : null;
    const {
      file,
      messageType,
      selectedMessageContent,
      showSendTemplateConfirmModal,
      bodyParams,
    } = this.state;
    const { setSelectedAgentId } = this.props;

    // check if text message is not blank
    if (
      this.state.messageType == "template" ||
      !this.checkIsEmptyString(message) ||
      file != null
    ) {
      let _this = this;
      const token = document.querySelector("[name=csrf-token]").content;
      const {
        state,
        addNewMessage,
        updateConversations,
        removeFromConversations,
      } = this.context;
      let request_url = `${state.domain_url}/apps/${state.app_id}/conversations/${state.selectedConversation.id}/messages/`;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          if (percent < 100 && file !== null) {
            this.setState({ uploadPercentage: percent });
          }
        },
      };
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      if (file) {
        formData.append("message[file]", file, file.name);
      }
      if (this.props.quotedMessage) {
        formData.append(
          "message[quoted_message_id]",
          this.props.quotedMessage.id
        );
      }
      if (message) {
        formData.append("message[content]", message);
      }
    

      if (messageType == "note" || messageType == "chatgpt" || messageType == 'template') {
        formData.append("message[message_type]", messageType);
      }

      // check if message type is template
      if (messageType == "template") {
        formData.append("message[message_content_id]",selectedMessageContent.id); // then send the template message content id
        for (var i = 0; i < bodyParams.length; i++) {
          formData.append("message[body_params][]", bodyParams[i]);
        }
      }
      

      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .post(request_url, formData, config)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            addNewMessage(JSON.parse(response.data.message).data);
            updateConversations(JSON.parse(response.data.conversation).data);
            if (state.selectedTab == "unassigned") {
              removeFromConversations(state.selectedConversation.id);
            }
            setSelectedAgentId(state.currentUserId);
            if (file != null) {
              this.setState({ uploadPercentage: 100 }, () => {
                setTimeout(() => {
                  this.setState({ uploadPercentage: 0 });
                }, 1000);
              });
            }
            if (showSendTemplateConfirmModal) {
              _this.setState({
                showSendTemplateConfirmModal: false,
              });
            }
            if (messageType == "template") {
              _this.setState({
                selectedTemplate: null,
                selectedMessageContent: null,
              });
            }
          }
        })
        .catch((error) => {
          this.handleError(error)
        });

      if (message) {
        document.getElementById("conversation-textarea").value = "";
        this.setState({ messageContent: "", messageType: messageType == 'add_template' ? 'default' : messageType });
      }

      // clear file upload
      _this.handleRemoveFile();
      this.props.handler(null);
    }
  }

  checkIsEmptyString = (text) => {
    return !text || /^\s*$/.test(text);
  };

  handleMessageChange = (e) => {
    this.setState({
      messageContent: e.target.value
    });
  };

  handleTemplateParams(event, index) {
    var newBodyParams = [...this.state.bodyParams];
    newBodyParams[index] = event.target.value;
    this.setState({
      bodyParams: newBodyParams
    });
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.handleClick(event);
    }

    if (event.keyCode === 191 && !event.shiftKey) {
      if (!this.state.mentionTemplates.length) {
        this.setState({
          showAskCreateTemplate: true,
        });
      }
    }
  };

  cancelAskCreateTemplate = ()=> {
    this.setState({
      showAskCreateTemplate: false,
      confirmCreateTemplate: true,
      messageType: "default",
    });
  }

  redirectTemplatePage = ()=> {
    window.location.href = `edit#templates`
  }

  handlePaste = (event) => {
    if (event.clipboardData.files.length > 0) {
      let file = event.clipboardData.files[0];
      this.uploadFile(file);
    }
  };

  handleRemoveFile = () => {
    // clear file upload
    if (document.getElementById("message-file-attachment") != null) {
      document.getElementById("message-file-attachment").value = null;
    }
    this.setState({
      file: null,
      errorFile: false,
    });
  };

  handleChangeFile = (event) => {
    let file = event.target.files[0];
    // Validate file
    this.uploadFile(file);
  };

  uploadFile = (fileData) => {
    if (
      fileData &&
      fileData.size / 1024 / 1024 < 20 &&
      this.validateFileType(fileData.name)
    ) {
      // Update the state
      this.setState({ file: fileData, errorFile: false });
    } else {
      this.setState({ fileData: null, errorFile: true });
    }
  }

  validateFileType = (fileName) => {
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "gif" ||
      extFile == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  };

  showTemplate = () => {
    this.setState(
      {
        messageType: "template",
        isLoadingTemplate: true,
        isLoading: true,
        templates: [],
        showSendButton: false,
      },
      () => {
        this.getTemplate();
      }
    );
  };

  handleError = (error = null) => {
    if (error.response) {
      toastr.error(error.response.data.message);
    } else if (error.request) {
      toastr.error(error.request);
    } else {
      toastr.error('An error occurred!');
    }
  };

  addTemplate = () => {

    let message = document.getElementById("conversation-textarea");
    let insertPos = message.selectionStart;
    let textAreaValue = message.value;

    this.setState({
      messageType: 'add_template'
    })

    if (!this.state.mentionTemplates.length) {
      this.setState({
        showAskCreateTemplate: true,
        confirmCreateTemplate: false,
      });
      document.getElementById("reply_tab_button").click();
    } else {
      this.setState({
        messageContent: textAreaValue.substring(0, insertPos) +
        ' /' +
        textAreaValue.substring(insertPos)
      });

      message.focus();
    }
  }

  getTemplate = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    const { state } = this.context;
    const request_url = `${state.domain_url}/apps/${state.app_id}/template_messages/templates/${state.selectedConversation.id}`;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      method: "GET",
      url: request_url,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            const response_data = response.data.data;
            let template_types_data = {};
            let template_options = [];
            response_data.map((template) => {
              let type =
                template.attributes.template_type.charAt(0).toUpperCase() +
                  template.attributes.template_type.slice(1) || "General";
              let category = template.attributes.category || "General";
              template_types_data[type] = template_types_data[type] || []; // populate for template type

              let template_categories_data = {}; // populate options category
              template_categories_data[category] = [];

              template_options.push({
                label: template.attributes.name,
                value: template.attributes.id,
                message_content: template.attributes.message_content,
              });
            });

            let mention_templates = []
            if (template_options.length !== 0) {
              mention_templates = template_options.filter((template, index) => {
                if (template.message_content === null) {
                  return false
                }
                return true;
              }).map((template, index) => {
                  return {id: template.message_content, display: template.label}
              })
            }

            console.log(template_options)

            this.setState({
              templates: template_options,
              mentionTemplates: mention_templates,
              isLoading: false,
              isLoadingTemplate: false,
            });
          }
        } else {
          if (response.data.message) {
            toastr.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(error)
        toastr.error("There was an error while fetching template messages.");
      });
  };

  handleSelectTemplate = ({ value }) => {
    console.log("Value: " + value);
    this.setState(
      {
        selectedTemplate: value,
        isLoadingMessageContents: true,
      },
      () => {
        this.getMessageContents();
      }
    );
  };

  getMessageContents = () => {
    const { selectedTemplate } = this.state;
    if (selectedTemplate) {
      const token = document.querySelector("[name=csrf-token]").content;
      const { state } = this.context;
      const request_url = `${state.domain_url}/apps/${state.app_id}/template_messages/template_message_content/${selectedTemplate}/${state.selectedConversation.id}`;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios({
        method: "GET",
        url: request_url,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.setState({
              isLoading: false,
              disabledSendButton: false,
              isLoadingMessageContents: false,
              messageContents: response.data.data.map(
                (data) => data.attributes
              ),
            });
          } else {
            if (response.data.message) {
              toastr.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          toastr.error(
            "There was an error while fetching template message contents."
          );
        });
    } else {
      toastr.error("Please select the template first.");
    }
  };

  handleSelectMessageContent = (value) => {
    this.setState({
      selectedMessageContent: value,
      showSendTemplateConfirmModal: true,
    });
  };

  handleCloseTemplateConfirm = () => {
    this.setState({
      selectedMessageContent: null,
      showSendTemplateConfirmModal: false,
    });
  };

  setShowEmoji = (value) => {
    this.setState({ showEmoji: value });
  };

  insertEmoji = (emoji) => {
    let message = document.getElementById("conversation-textarea");
    let insertPos = message.selectionStart;
    let textAreaValue = message.value;
    let txtToAdd = emoji.native;

    this.setState({
      messageContent: textAreaValue.substring(0, insertPos) +
      txtToAdd +
      textAreaValue.substring(insertPos)
    });

    message.selectionStart = insertPos + txtToAdd.length;
    message.selectionEnd = insertPos + txtToAdd.length;
    message.focus();
    message.blur();
  };

  selectedTab = (tab) => {
    this.setState({
      messageType: tab, showSendButton: true,
    })
    this.backslashRemove()
  }

  backslashRemove = () => {
    let message = document.getElementById("conversation-textarea");
    let textAreaValue = message.value;

    if ( (this.state.messageType == 'add_template') && (textAreaValue.slice(-1) == '/') ) {
      let backslash_removed = textAreaValue.substring(0, textAreaValue.length - 1);
      this.setState({ messageContent: backslash_removed });
    }
  }

  render() {
    const {
      showEmoji,
      file,
      errorFile,
      messageType,
      isLoadingTemplate,
      templates,
      bodyParams,
      mentionTemplates,
      isLoadingMessageContents,
      messageContents,
      showSendButton,
      showSendTemplateConfirmModal,
      selectedMessageContent,
      uploadPercentage,
      messageContent,
      confirmCreateTemplate,
      showAskCreateTemplate,
    } = this.state;

    const { state } = this.context;

    return (
      <>
        <div id="conversation-send-form">
          <ul className="nav mb-3 border-bottom">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  messageType == "default" ? "active" : "font-weight-normal"
                }`}
                id="reply_tab_button"
                data-toggle="pill"
                aria-selected="true"
                onClick={() => this.selectedTab('default') }
              >
                Reply
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  messageType == "note" ? "active" : "font-weight-normal"
                }`}
                data-toggle="pill"
                aria-selected="false"
                onClick={() => this.selectedTab('note') }
              >
                Notes
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  messageType == "template" ? "active" : "font-weight-normal"
                }`}
                data-toggle="pill"
                aria-selected="false"
                onClick={() => this.showTemplate()}
            >
              Template 
            </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  messageType == "chatgpt" ? "active" : "font-weight-normal"
                }`}
                data-toggle="pill"
                aria-selected="false"
                onClick={() => this.selectedTab('chatgpt') }
              >
                ChatGPT
              </a>
            </li>
          </ul>
          <div className="conversation-form-message">
            {messageType != "template" ? (
              <>
                <MentionsInput
                  value={messageContent}
                  onChange={this.handleMessageChange}
                  className="send-message-area"
                  placeholder="Type a message"
                  id="conversation-textarea"
                  autoFocus
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  onPaste={(e) => this.handlePaste(e)}
                >
                  <Mention
                    markup="￿__id__"
                    appendSpaceOnAdd={true}
                    displayTransform={(id) => `${id}`}
                    trigger="/"
                    data={mentionTemplates}
                  />
                </MentionsInput>
              </>
            ) : (
              <Templates
                isLoadingTemplate={isLoadingTemplate}
                isLoadingMessageContents={isLoadingMessageContents}
                bodyParams={bodyParams} 
                handleTemplateParams={(event, index) => this.handleTemplateParams(event, index)}
                templates={templates}
                messageContents={messageContents}
                handleSelectTemplate={(value) =>
                  this.handleSelectTemplate(value)
                }
                handleSelectMessageContent={(value) =>
                  this.handleSelectMessageContent(value)
                }
                showSendTemplateConfirmModal={showSendTemplateConfirmModal}
                selectedMessageContent={selectedMessageContent}
                handleCloseTemplateConfirm={() =>
                  this.handleCloseTemplateConfirm()
                }
                handleClick={(event) => this.handleClick(event)}
              />
            )}
            <div id="conversation-send-form-bottom">
              <div id="file-attachement-detail">
                {uploadPercentage > 0 && (
                  <ProgressBar className="w-100">
                    <ProgressBar
                      animated
                      now={uploadPercentage}
                      className="position-relative"
                    />
                  </ProgressBar>
                )}
                {file && !errorFile && uploadPercentage == 0 && (
                  <span className="file-detail-span">
                    <i className="fa fa-file" color="gray" />
                    <p>{file.name}</p>
                    <div className="remove-file-wrap">
                      <Button
                        variant="outline-secondary"
                        className="btn-remove-file"
                        onClick={() => this.handleRemoveFile()}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    </div>
                  </span>
                )}
                {errorFile && (
                  <span className="file-detail-span">
                    <p className="error-message-text">
                      File attachement only for image and pdf file (Size is
                      &#8804; 20MB).
                    </p>
                  </span>
                )}
              </div>

              <div id="conversation-send-form-bottom-right">
                {messageType != "template" && messageType != "chatgpt" ? (
                  <>
                    <OutsideClickHandler
                      onOutsideClick={() => this.setShowEmoji(false)}
                    >
                      <button
                        className="btn btn-no-border icon"
                        onClick={() =>
                          this.setShowEmoji(showEmoji ? false : true)
                        }
                      >
                        <i className="fa fa-smile" />
                      </button>
                      {showEmoji && (
                        <Picker
                          style={{
                            position: "absolute",
                            bottom: "50px",
                            right: "24px",
                          }}
                          set={"twitter"}
                          showPreview={false}
                          showSkinTones={false}
                          onSelect={(emoji) => this.insertEmoji(emoji)}
                        />
                      )}
                    </OutsideClickHandler>
                    <div className="upload-attachment-btn-wrapper">
                      <label
                        className="btn btn-no-border icon mb-0"
                        htmlFor="message-file-attachment"
                      >
                        <i className="fa fa-paperclip" />
                      </label>
                      <input
                        type="file"
                        className="btn btn-no-border"
                        id="message-file-attachment"
                        accept="application/pdf,image/*"
                        onChange={(event) => this.handleChangeFile(event)}
                        disabled={uploadPercentage > 0}
                      />
                    </div>
                  </>
                ) : null}
                {/* <span data-tip={state.disabledSendButton == true ? 'Session Expired' : 'Send'} data-tip-disable={false} data-place={"top"} data-multiline={false}> */}

                {showSendButton && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {state.disabledSendButton == true
                          ? "Session Expired"
                          : "Send"}
                      </Tooltip>
                    }
                  >
                    <button
                      id="conversation-send-btn"
                      className="btn btn-primary float-right"
                      type="button"
                      onClick={(event) => this.handleClick(event)}
                      disabled={
                        state.disabledSendButton || uploadPercentage > 0
                      }
                    >
                      {messageType == "note" ? "Add note" :
                        messageType == "chatgpt" ? "Ask ChatGPT" : "Send"
                      }
                    </button>
                  </OverlayTrigger>
                )}
                {/* </span> */}

                {showAskCreateTemplate && !confirmCreateTemplate && (
                  <AskCreateTemplate
                    showAskCreateTemplate={showAskCreateTemplate}
                    cancelAskCreateTemplate={this.cancelAskCreateTemplate}
                    appId={state.app_id}
                    redirectTemplatePage={this.redirectTemplatePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const Templates = ({
  templates,
  isLoadingTemplate,
  bodyParams,handleTemplateParams,
  handleSelectTemplate,
  isLoadingMessageContents,
  messageContents,
  handleSelectMessageContent,
  showSendTemplateConfirmModal,
  selectedMessageContent,
  handleCloseTemplateConfirm,
  handleClick,
}) => {
  if (isLoadingTemplate) {
    return <LoadingTemplateSkeleton />;
  }
  return (
    <div className="templates templates-data">
      <Select
        options={templates}
        multi={true}
        placeholder="Select Template"
        onChange={handleSelectTemplate}
        menuPlacement="top"
        maxMenuHeight={220}
        className="select-template"
        styles={{
          menuList: (base) => ({
            ...base,
            position: "fixed !important",
            backgroundColor: "white",
            border: "1px solid lightgray",
            width: "calc(100vh - 10px)",
            bottom: "50px",
          }),
        }}
      />

      <MessageContents
        isLoadingMessageContents={isLoadingMessageContents}
        bodyParams={bodyParams} 
        handleTemplateParams={handleTemplateParams}
        messageContents={messageContents}
        handleSelectMessageContent={handleSelectMessageContent}
        showSendTemplateConfirmModal={showSendTemplateConfirmModal}
        selectedMessageContent={selectedMessageContent}
        handleCloseTemplateConfirm={handleCloseTemplateConfirm}
        handleClick={handleClick}
      />
    </div>
  );
};

const LoadingTemplateSkeleton = () => {
  return (
    <div className="templates">
      <Skeleton
        height={"24px"}
        width={"320px"}
        borderRadius={"8px"}
        color={"#dedede"}
        widthRandomness={0}
        heightRandomness={0}
      />
    </div>
  );
};

const MessageContents = ({
  isLoadingMessageContents,
  bodyParams,handleTemplateParams,
  messageContents,
  handleSelectMessageContent,
  showSendTemplateConfirmModal,
  selectedMessageContent,
  handleCloseTemplateConfirm,
  handleClick,
}) => {
  if (isLoadingMessageContents) {
    return <LoadingMessageContentsSkeleton />;
  }

  let messageContentsView = [];
  if (messageContents && messageContents.length > 0) {
    messageContents.map((message_content) => {
      messageContentsView.push(
        <OverlayTrigger
          key={message_content.id}
          placement="top"
          overlay={
            <Tooltip id="button-tooltip-message-content">Select</Tooltip>
          }
        >
          <button
            className="btn btn-default"
            onClick={() => handleSelectMessageContent(message_content)}
          >
            {`${message_content.content} (${message_content.language})`}
            <i className="fa fa-paper-plane icon-margin-left" />
          </button>
        </OverlayTrigger>
      );
    });
  }

  return (
    <div className="message-contents">
      {messageContentsView}

      {showSendTemplateConfirmModal && (
        <SendTemplateConfirmationModal
          showSendTemplateConfirmModal={showSendTemplateConfirmModal}
          bodyParams={bodyParams} 
          handleTemplateParams={handleTemplateParams}
          selectedMessageContent={selectedMessageContent}
          handleCloseTemplateConfirm={handleCloseTemplateConfirm}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

const LoadingMessageContentsSkeleton = () => {
  return (
    <div className="message-contents">
      <span className="message-content-skeleton">
        <Skeleton
          height={"24px"}
          width={"160px"}
          borderRadius={"8px"}
          color={"#dedede"}
          widthRandomness={0}
          heightRandomness={0}
        />
      </span>
      <span className="message-content-skeleton">
        <Skeleton
          height={"24px"}
          width={"160px"}
          borderRadius={"8px"}
          color={"#dedede"}
          widthRandomness={0}
          heightRandomness={0}
        />
      </span>
    </div>
  );
};

const SendTemplateConfirmationModal = ({
  selectedMessageContent,
  bodyParams,handleTemplateParams,
  showSendTemplateConfirmModal,
  handleCloseTemplateConfirm,
  handleClick,
}) => {
  const paramsCount = (selectedMessageContent.content.match(/\{\{\d+\}\}/g) || []).length;
  const inputs = Array.from({ length: paramsCount }, (_, index) => (
    <input name="bodyParams" className="form-control" value={bodyParams[index]} onChange={(e) => handleTemplateParams(e, index)}/>
  ));

  return (
    <Modal
      show={showSendTemplateConfirmModal}
      onHide={handleCloseTemplateConfirm}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedMessageContent ? selectedMessageContent.content : " "}
        <div className="mt-3">{paramsCount > 0 ? "Body Params:" : " "}</div>
        {inputs}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleCloseTemplateConfirm}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AskCreateTemplate = ({
  showAskCreateTemplate,
  cancelAskCreateTemplate,
  appId,
  redirectTemplatePage,
}) => {
  return (
    <Modal
      show={showAskCreateTemplate}
      onHide={cancelAskCreateTemplate}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Message Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        If you don't already have one, create a message template on the Template page follow this <a href="https://help.novochat.co/Template-Messages-2e108c804bdb46fb9214273735553d91">instructions</a>.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={cancelAskCreateTemplate}>
          Remind later
        </Button>
        <Button variant="primary" onClick={redirectTemplatePage}>
          Leave page & create template
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConversationSendForm;
