$(window).on("load", function () {
  $("body").on("change", "#template-type", function (event) {
    var value = $(this).val();
    if (value === "whatsapp") {
      $("#template-category").show();
      $("#template-category-select").attr("name", "template_message[category]");
      $("#template-category-select").attr("required", "required");

      $("#template-platform_id").show();
      $("#template-platform_id-select").attr("name", "template_message[platform_id]");
      $("#template-platform_id-select").attr("required", "required");
    } else {
      $("#template-category").hide();
      $("#template-category-select").removeAttr("name");
      $("#template-category-select").removeAttr("required");

      $("#template-platform_id").hide();
      $("#template-platform_id-select").removeAttr("name");
      $("#template-platform_id-select").removeAttr("required");
    }
  });

  $("body").on("submit", "form#form-template-message", function (e) {
    $("input[type=submit]").attr("disabled", "disabled");
  });

  $("body").on(
    "click",
    "div.template-messages a#btn-new-template",
    function () {
      window.location.hash = "#templates#new";
    }
  );

  $("body").on(
    "click",
    "div.template-messages a#btn-edit-template",
    function () {
      window.location.hash = "#templates#edit";
    }
  );

  $("body").on("keypress", "#template-message-name", function (event) {
    var regex = new RegExp("^[a-z0-9_]*$");
    var str = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  });
});
