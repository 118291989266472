$(window).on("load", function () {
  if ($("#app_timezone").length > 0 && !$("#app_timezone").val()) {
    $("#app_timezone").val(window.browser_timezone);
  }

  $("body").on("click", "button.tag-placeholder", function () {
    let message = document.querySelector('#templateContentArea, #broadcast-content-textarea');
    let insertPos = message.selectionStart;
    let textAreaValue = message.value;
    let txtToAdd = $(this).data("value");

    message.value =
      textAreaValue.substring(0, insertPos) +
      txtToAdd +
      textAreaValue.substring(insertPos);

    message.selectionStart = insertPos + txtToAdd.length;
    message.selectionEnd = insertPos + txtToAdd.length;
    message.focus();
    message.blur();
  });

  // ------ Broadcast ------ //
  $("body").on("click", "#select_all_recipient", function () {
    $(this)
      .parents("table")
      .find(".existing-recipient-checkbox")
      .prop("checked", this.checked);
  });

  $("body").on("change", "input[type='file']", function (e) {
    var files = e.target.files || null;
    var fileName = "";
    var label = $(".label-file");

    if (files && files.length > 1) {
      fileName = ($(this).attr("data-multiple-caption") || "").replace(
        "{count}",
        files.length
      );
    } else {
      fileName = e.target.files[0].name;
    }

    if (fileName) {
      label.html(fileName);

      var btnRemove = document.getElementById("remove-file-input");
      if (!btnRemove) {
        var remove = document.createElement("BUTTON");
        remove.innerHTML = "Remove";
        remove.setAttribute("onclick", "broadcast_remove_file()");
        remove.setAttribute("class", "btn btn-no-border mx-3");
        remove.setAttribute("type", "button");
        remove.setAttribute("id", "remove-file-input");
        label[0].parentNode.append(remove);
      }

      // remove button to remove current file
      $("#button-remove-current-file").css("display", "none");

      // if has widget preview
      var widgets = $(".novochat-widget-header");
      if (widgets.length > 0) {
        var url = URL.createObjectURL(e.target.files[0]);
        widgets.html(`<img class="novochat-image-logo" src="${url}"/>`);
      }
    } else {
      label.html(label.text());
    }
  });

  $("body").on("click", ".button-menu", function () {
    if (!$(this).hasClass("disabled")) {
      var data_id = $(this).attr("data-id");

      if (data_id) {
        $(".button-menu").removeClass("active");
        $(this).addClass("active");

        if (data_id === "upload-csv") {
          $("#broadcast-recipient-type").val("new_recipient");
          $(".upload-csv").css("display", "block");
          $(".upload-csv").show();
          $(".existing-leads").hide();
          $(".check-box-input").attr("name", "broadcast[use_csv_message]");
          $("#recipient-status-map-fields").removeClass("active");
          $("#recipient-status-map-fields")
            .find("div.title")
            .addClass("disabled");
          $("#recipient-status-map-fields")
            .find("div.subtitle")
            .addClass("disabled");
          $("#recipient-status-add-file").addClass("active");
          $("#recipient-status-add-leads").removeClass("active");
          $("#recipient-status-add-leads").find("svg").addClass("active");
          $(".select-recipients").hide();
          $("#import-file-box").show();
          $("#select-existing-leads").hide();
          $("#upload-new-recipients").show();
          $("#recipient-status-review-fields").show().removeClass("active");
          $("#recipient-status-review-fields")
            .find("div.title")
            .addClass("disabled");
          $("#recipient-status-review-fields")
            .find("div.subtitle")
            .addClass("disabled");
          window.location.hash = "#upload-csv";
        } else if (data_id === "select-existing-leads") {
          $("#broadcast-recipient-type").val("existing_recipient");
          $(".upload-csv").hide();
          $(".existing-leads").show();
          $(".check-box-input").attr("name", "");
          $("#recipient-status-add-leads").removeClass("active");
          $("#recipient-status-add-leads").find("svg").addClass("active");
          $(".select-recipients").show();
          $("#import-file-box").hide();
          $("#select-existing-leads").show();
          $("#upload-new-recipients").hide();
          $("#recipient-status-select-existing-leads")
            .removeClass("disabled")
            .addClass("active");
          $("#recipient-status-select-existing-leads")
            .find("div.title")
            .removeClass("disabled");
          $("#recipient-status-select-existing-leads")
            .find("div.subtitle")
            .removeClass("disabled");
          window.location.hash = "#select-existing-leads";
        } else if (data_id === "new-message") {
          $(".broadcast-template-list-wrap").hide();
          $("#request-template-link").hide();
          $("#insert-content-placeholder").show();
          $("#content-type").val("new_message");
          $("#broadcast-template-id").val(null);
          $("#broadcast-template-id").removeAttr("required");
          $("#broadcast-content-textarea")
            .removeAttr("disabled")
            .val($("#broadcast-content-textarea").attr("data-content"));
          $("#text-area-content-broadcast").addClass("pl-0 col-md-12");
        } else if (data_id === "templates") {
          $(".broadcast-template-list-wrap").show();
          $("#request-template-link").show();
          $("#insert-content-placeholder").hide();
          $("#content-type").val("templates");

          $("#broadcast-template-id").attr("required", "required");
          $("#broadcast-content-textarea").attr("disabled", "disabled");
          var broadcast_template_content = $(
            '.template-item[data-id="' +
              $("#broadcast-template-id").attr("data-id") +
              '"]'
          ).attr("data-content");
          if (broadcast_template_content) {
            $("#broadcast-content-textarea").val(
              broadcast_template_content.replace(/\\r?\\n/g, "\n")
            );
          } else {
            $("#broadcast-content-textarea").val(null);
          }
          $("#text-area-content-broadcast").removeClass("pl-0 col-md-12");
        }
      }
    }
  });

  $("body").on("click", ".check-box-input", function () {
    if ($(this).is(":checked")) {
      $(this).val("true");
    } else {
      $(this).val("false");
    }
  });

  // automation
  $("body").on("change", "#automation-template", function () {
    let id = $(this).val();
    let content = $(this).find('option[value="'+id+'"]').attr('data-content').replace(/\\r?\\n/g, "\n");
    set_template(content, id);
  });

  $("body").on("click", ".template-item", function () {
    let content = $(this).attr("data-content").replace(/\\r?\\n/g, "\n");
    let id = $(this).attr("data-id");
    set_template(content, id);
  });

  function set_template (content, id) {
    $(".template-item").removeClass("active");
    $("#broadcast-template-id").val(id);
    $("#text-area-content-broadcast").find('textarea').val(content);

    $('.content-placeholders').show();
    $(".placeholders-input").hide();
    $("#title-placeholders").hide();
    $(".placeholders-input input")
      .removeAttr("required")
      .attr("disabled", "disabled");
    if ($('.placeholders-input[data-id="' + id + '"]').length) {
      $("#title-placeholders").show();
      $('.placeholders-input[data-id="' + id + '"]').css("display", "flex");
      $('.placeholders-input[data-id="' + id + '"] input')
        .attr("required", "required")
        .removeAttr("disabled");
    }
  }

  // Broadcast
  $("body").on("click", "#add-message-custom", function () {
    $('#broadcast-template-id').removeAttr('name');
    $('#text-area-content-broadcast').find('textarea').removeAttr('id').removeAttr('name').removeAttr('required');

    $('#custom-message-wrap').find('textarea').attr('id', 'broadcast-content-textarea').attr('name', 'broadcast[content]').attr('required', 'required');
  });

  $("body").on("click", "#add-message-template", function () {
    $('#broadcast-template-id').attr('name', 'broadcast[broadcast_template_id]');
    $('#custom-message-wrap').find('textarea').removeAttr('id').removeAttr('name').removeAttr('required');

    $('#text-area-content-broadcast').find('textarea').attr('id', 'broadcast-content-textarea').attr('name', 'broadcast[content]').attr('required', 'required');
  });

  // Automation
  $("body").on("click", "#automation-add-message-custom", function () {
    $('#broadcast-template-id').removeAttr('name');
    $('#automation-template').removeAttr('required');
    $('#text-area-content-broadcast').find('textarea').removeAttr('id').removeAttr('name').removeAttr('required');

    $('#custom-message-wrap').find('textarea').attr('id', 'broadcast-content-textarea').attr('name', 'event[content]').attr('required', 'required');
  });

  $("body").on("click", "#automation-add-message-template", function () {
    $('#automation-template').attr('required', 'required');
    $('#broadcast-template-id').attr('name', 'event[broadcast_template_id]');
    $('#custom-message-wrap').find('textarea').removeAttr('id').removeAttr('name').removeAttr('required');

    $('#text-area-content-broadcast').find('textarea').attr('id', 'broadcast-content-textarea').attr('name', 'event[content]').attr('required', 'required');
  });

  $("body").on("click", "#close-icon-button", function () {
    $("#search-filter-tags-wrap").hide();
    var tags_filter = document.querySelectorAll(
      "div#tags-filter-selected > div.tag-search"
    );
    if (tags_filter.length > 0) {
      if (!$("#filter-contact-broadcast").hasClass("active")) {
        $("#filter-contact-broadcast").add("active");
      }
    } else {
      $("#filter-contact-broadcast").removeClass("active");
    }
  });
  // ------- End of Broadcast ------- //

  $("body").on("input", "#stripe_subscription_quantity", function () {
    $("#billingUserNo").html($(this).val());
  });

  $("body").on("click", ".stripe-upgrade-a", function () {
    $(this).addClass("disabled");

    var productId = $(this).data("id");

    $("#stripe_subscription_stripe_product_id").val(productId);
    if ($("#stripe_subscription_form .stripe-submit-btn").length > 0) {
      $("#stripe_subscription_form .stripe-submit-btn")[0].click();
    }
  });

  $("body").on("click", "#sub_edit_btn", function () {
    $(this).addClass("d-none");
    $(".sub-quantity").removeClass("d-none");
    $(".stripe-submit-btn").removeClass("d-none");
  });

  $("body").on("change", "#app_auto_reply", function () {
    if ($(this).is(":checked")) {
      $(".row-in-office, .row-out-office").removeClass("d-none");
    } else {
      $(".row-in-office, .row-out-office").addClass("d-none");
    }
  });
});

window.open_modal = (id) => {
  jquery(id).modal("show");
};

window.change_settings_roundrobin = (element) => {
  let el = $(element);
  let id = el.data('id');
  let val = el.val();
  el.val((val + '') != 'true');
  
  if (val) {
    let data = { role: val };

    $.ajax({
      type: "PUT",
      url: `/app_users/${id}/change_role`,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      cache: false,
      responseType: "json",
      data: JSON.stringify(data)
    })
    .done((response) => {
      if (response?.status === 'success') {
        toastr.success(response.message);
      }
    })
    .catch((error) => {
      toastr.error(error.message);
    });
  }
}
window.change_settings_agents = (element) => {
  let el = $(element);
  let id = el.data('id');
  let val = el.val();

  if (val) {
    let data = { roundrobin: val };

    $.ajax({
      type: "PUT",
      url: `/app_users/${id}/change_role`,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      cache: false,
      responseType: "json",
      data: JSON.stringify(data)
    })
    .done((response) => {
      if (response?.status === 'success') {
        toastr.success(response.message);
      }
    })
    .catch((error) => {
      toastr.error(error.message);
    });
  }
}
window.update_app = (element) => {
  let el = $(element);
  let id = el.data('id');
  let val = el.val();
  el.val((val + '') != 'true');
  if (val) {
    let data = { roundrobin: val };

    $.ajax({
      type: "PUT",
      url: `/apps/${id}`,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      cache: false,
      responseType: "json",
      data: JSON.stringify(data)
    })
    .done((response) => {
      if (response?.status === 'success') {
        toastr.success(response.message);
      }
    })
    .catch((error) => {
      toastr.error(error.message);
    });
  }
}

window.delete_confirmation = () => {
  jquery("#modal-confirmation").modal({ show: true });
};
