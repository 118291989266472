window.additional_field_options = (el, name)=> {
  if ( el.value === 'other' || el.value === 'Other' || el.checked === true) {
    $(`#${name}`).show();
    $(`#${name}`).val("");
  } else {
    $(`#${name}`).hide();
    $(`#${name}`).val(el.value);
  }
}

