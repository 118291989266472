import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import _ from 'lodash';
import SvgLineIconPath from "images/svgs/icons/line.svg";
import SvgFacebookIconPath from "images/svgs/icons/facebook.svg";
import SvgTelegramIconPath from "images/svgs/icons/telegram.svg";
import SvgLiveChatIconPath from "images/svgs/icons/live_chat.svg";
import SvgWhatsappIconPath from "images/svgs/icons/whatsapp.svg";
import SvgInstagramIconPath from "images/svgs/icons/instagram.svg";
import Axios from 'axios';
import ContactPagination from '../../contacts/ContactPagination';

const TableBroadcastRecipients = forwardRef(({ 
  isSearchLoading = false, 
  searchText = '', 
  isBroadcastSent = false, 
  currentRecipients = [] /** Current Contact */, 
  domainURL = '',
  appId = null,
  broadcastId = null,
  tagsRef }, ref) => {
  const [ contactList, setContactList ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(10);
  const [ totalData, setTotalData ] = useState(0);
  const [ totalPages, setTotalPages ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isCheckAll, setIsCheckAll ] = useState(false);
  const [ recipients, setRecipients ] = useState([]); // all contact list
  const [ selectedRecipients, setSelectedRecipients ] = useState([]); // selected contact


  useEffect(() => {
    if(domainURL !== '') {
      async function search() {
        search_filter();
      }

      search();
    }
  }, [domainURL, searchText, currentPage]);

  useEffect(() => {
    if(isCheckAll === true) {
      search_filter(true);
    }
    else {
      setSelectedRecipients([]);
    }
  }, [isCheckAll]);

  useEffect(() => {
    setSelectedRecipients(currentRecipients);
  },
  []);

  useImperativeHandle(ref, () => ({
    handleFilterTags (data, meta) {
      _handleFilterTags(data, meta);
    }
  }));

  const search_filter = (isCheckAll = false) => {
    const token = document.querySelector("[name=csrf-token]").content;
    Axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    Axios({
      method: 'POST',
      url: `${domainURL}/apps/${appId}/broadcasts/${broadcastId}/broadcast_recipients/contact_recipients`,
      responseType: 'json',
      data: {
        by_keyword: searchText,
        page: currentPage,
        tags: tagsRef.current,
        is_check_all: isCheckAll
      }
    })
      .then((response) => {
        if(response.data.status === 'success') {
          if(!isCheckAll) {
            setContactList(response.data.data.data);
            setCurrentPage(response.data.data.meta.current_page);
            setPerPage(response.data.data.meta.per_page);
            setTotalData(response.data.data.meta.total_data);
            setTotalPages(response.data.data.meta.total_pages);
          }
          else {
            setSelectedRecipients(response.data.data);
          }
        }
        setIsLoading(false);
      });
  };

  const _handleFilterTags = (data, meta) => {
    setContactList(data);
    setCurrentPage(meta.current_page);
    setPerPage(meta.per_page);
    setTotalData(meta.total_data);
    setTotalPages(meta.total_pages);
  };

  const _handleCheckAllChange = _.debounce(() => {
    if (totalData == selectedRecipients.length) {
      setIsCheckAll(false);
      setSelectedRecipients([]);
    }
    else {
      setIsCheckAll(!isCheckAll);
    }
  }, 300);

  const _handleRecipientsClicked = (id) => {
    let index = selectedRecipients.findIndex(x => x == id);
    if(index > -1) {
      let ids = [...selectedRecipients]
      ids.splice(index, 1);
      setSelectedRecipients(ids);
    }
    else {
      let ids = [...selectedRecipients, id];
      setSelectedRecipients(ids);
    }
  };

  const _changePage = (page = 1) => {
    setCurrentPage(page)
  };

  if(domainURL === '') return null;

  return(
    <>
      <div className="table-responsive">
        <input
          type="hidden"
          value={[...selectedRecipients]}
          name="recipient" />
        <table className="table" id="broadcast-contacts">
          <thead>
            <tr className="border-0">
              <th className="pt-3 pl-1 d-flex align-items-center justify-content-center border-0">
                <input 
                  type="checkbox"
                  checked={ totalData == selectedRecipients.length }
                  className="mr-0 check-input"
                  disabled={isBroadcastSent || isLoading}
                  onChange={() => _handleCheckAllChange()} />
              </th>
              <th className="pl-0 border-0">#</th>
              <th className="border-0" scope="col">Name</th>
              <th className="border-0" scope="col">Channel</th>
              <th className="border-0" scope="col">Phone</th>
              <th className="border-0" scope="col">Email</th>
              <th className="border-0" scope="col">Tags</th>
            </tr>
          </thead>
          <tbody>
            <Row
              contactList={contactList}
              isLoading={isLoading}
              selectedRecipients={selectedRecipients}
              isBroadcastSent={isBroadcastSent}
              isCheckAll={isCheckAll}
              currentRecipients={currentRecipients}
              handleRecipientsClicked={_handleRecipientsClicked} />
          </tbody>
        </table>
      </div>

      <div className="mb-3">
        <ContactPagination
          contact_list={contactList}
          current_page={currentPage}
          per_page={perPage}
          total_data={totalData}
          total_pages={totalPages}
          changePage={_changePage} />
      </div>
    </>
  )
});

const Row = ({ contactList = [], isLoading = false, selectedRecipients, isBroadcastSent, handleRecipientsClicked, currentRecipients }) => {
  let rows = [];

  if(contactList.length <= 0) {
    return(
      <tr className="border-0">
        <td colSpan={7}>
          <p className="mb-0 text-center">
            { isLoading ? 'Loading ...' : 'No data' }
          </p>
        </td>
      </tr>
    );
  }
  else if(contactList.length > 0 && !isLoading) {
    contactList.map((contact, index) => {
      rows.push(
        <tr key={`row-broadcast-contact-${index}`} className="table-search-available" style={{ backgroundColor: (index + 1) % 2 === 0 ? '#F9F9F9' : '#FFFF' }}>
          <td className="border-0 pt-3 pl-1 d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="m-0 check-input existing-recipient-checkbox"
              defaultValue={contact.attributes.id}
              checked={selectedRecipients.includes(contact.attributes.id)}
              onChange={(e) => handleRecipientsClicked(e.target.value)}
              disabled={isBroadcastSent} />
          </td>
          <td className="pl-0 border-0">{ (index+1) }</td>
          <td className="border-0">{ contact.attributes.name }</td>
          <td className="border-0">
            {contact.attributes.channels.length ? (
              <div>
                <Channel channels={contact.attributes.channels} />
              </div>
            ) : (
              "-"
            )}
          </td>
          <td className="border-0">{ contact.attributes.phone }</td>
          <td className="border-0">{ contact.attributes.email }</td>
          <td className="border-0 d-flex flex-wrap tags-elements">
            <Tags
              dataList={contact.attributes.tags.data} />
          </td>
        </tr>
      )
    });
  }
  return rows;
};

const Channel = ({ channels }) => {
  var list = [];

  const channelsUniqueByService = [
    ...new Map(channels.map((item) => [item["service"], item])).values(),
  ];

  channelsUniqueByService.map((data) => {
    if (data.service == "line") {
      list.push(
        <img
          src={SvgLineIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-line'}
        />
      );
    } else if (data.service == "telegram") {
      list.push(
        <img
          src={SvgTelegramIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-telegram'}
        />
      );
    } else if (data.service == "live_chat") {
      list.push(
        <img
          src={SvgLiveChatIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-live-chat'}
        />
      );
    } else if (data.service == "facebook") {
      list.push(
        <img
          src={SvgFacebookIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-facebook'}
        />
      );
    } else if (data.service == "whatsapp" || "whatsapp_chatapi" || "whatsapp_business") {
      list.push(
        <img
          src={SvgWhatsappIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-whatsapp'}
        />
      );
    } else if (data.service == "instagram") {
      list.push(
        <img
          src={SvgInstagramIconPath}
          width={25}
          height={25}
          style={{ marginRight: "10px" }}
          key={'service-whatsapp'}
        />
      );
    }
  });
  return list;
};

const Tags = ({ dataList }) => {
  var list = [];
  dataList.map((data, index) => {
    list.push(
      <div key={index} variant="primary" className="col-tag">
        <div className={`tag-search border mb-2 py-1 px-2${(index > 0 ) ? ' ml-2' : '' }`}>
          { data.attributes.name }
        </div>
      </div>
    );
  });
  return list;
};

export default TableBroadcastRecipients;