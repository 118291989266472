import FacebookSVG from './Facebook';
import WhatsAppSVG from './WhatsApp';
import TelegramSVG from './Telegram';
import LineSVG from './Line';

export {
  FacebookSVG,
  WhatsAppSVG,
  TelegramSVG,
  LineSVG
}