
if (('serviceWorker' in navigator) && window.location.pathname == '/') {
  // declaring scope manually
  navigator.serviceWorker.register('service_workers/serviceworker.js', {scope: './service_workers/'}).then(function(registration) {
    // console.log('Service worker registration succeeded:', registration);
    return registration.pushManager.getSubscription()
            .then(function(subscription) {
              if (subscription) {
                return subscription;
              }
              return registration.pushManager.subscribe({                           //6
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
              });
            });
        }).then(function(subscription) {
          // console.log(subscription.toJSON());
          $.post("/push_subscription", { subscription: subscription.toJSON() });
          console.log('Service worker registration successful');

  }, /*catch*/ function(error) {
    console.log('Service worker registration failed:', error);
  });
} else {
  // console.log('Service workers are not supported.');
}