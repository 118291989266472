import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const UserAvatar = (props) => {
  const { name, platformName } = props;
  const formatName = (nameInput) => {
    if (nameInput != null) {
      return nameInput
        .split(" ")
        .map((item) => {
          return item[0];
        })
        .slice(0, 2)
        .join("");
    }
    return "NC";
  };

  return(

    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id="button-tooltip-2">{platformName}</Tooltip>}>
      <div className="user-avatar">{formatName(name)}</div>
    </OverlayTrigger>
  )


};

export default UserAvatar;
