import React from 'react';

const LineSVG = ({ width = '20', height = '20' }) => {
  return(
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#00C200"/>
      <path d="M16.3432 10.3562C16.3722 10.2033 16.3866 10.0746 16.3932 9.97369C16.4041 9.80711 16.3917 9.55979 16.3872 9.48235C16.2338 6.81367 13.4651 4.6875 10.072 4.6875C6.58041 4.6875 3.75 6.93883 3.75 9.71599C3.75 12.2686 6.14123 14.3769 9.23916 14.7012C9.42856 14.7211 9.56513 14.892 9.54413 15.0813L9.41223 16.2685C9.38228 16.538 9.66095 16.734 9.90516 16.6162C12.5225 15.353 14.0876 14.0535 15.0222 12.9277C15.1923 12.7228 15.7469 11.9458 15.8599 11.7418C16.0999 11.3085 16.2654 10.8433 16.3432 10.3562Z" fill="white"/>
      <path d="M6.59551 10.7347V8.6166C6.59551 8.438 6.45072 8.29321 6.27212 8.29321C6.09352 8.29321 5.94873 8.438 5.94873 8.6166V11.0581C5.94873 11.2367 6.09352 11.3815 6.27212 11.3815H7.56564C7.74424 11.3815 7.88903 11.2367 7.88903 11.0581C7.88903 10.8795 7.74424 10.7347 7.56564 10.7347H6.59551Z" fill="#00C500"/>
      <path d="M8.59037 11.3815H8.44899C8.30943 11.3815 8.19629 11.2683 8.19629 11.1288V8.54591C8.19629 8.40635 8.30943 8.29321 8.44899 8.29321H8.59037C8.72993 8.29321 8.84307 8.40635 8.84307 8.54591V11.1288C8.84307 11.2683 8.72993 11.3815 8.59037 11.3815Z" fill="#00C500"/>
      <path d="M11.2037 8.61661V10.1081C11.2037 10.1081 9.91221 8.42361 9.8925 8.40148C9.8308 8.33223 9.73987 8.28962 9.63893 8.29345C9.46302 8.30012 9.32812 8.45386 9.32812 8.62988V11.0581C9.32812 11.2367 9.47291 11.3815 9.65151 11.3815C9.83011 11.3815 9.9749 11.2367 9.9749 11.0581V9.5756C9.9749 9.5756 11.286 11.2748 11.3053 11.2931C11.3625 11.3472 11.4393 11.3807 11.5241 11.3815C11.7037 11.3831 11.8505 11.2242 11.8505 11.0446V8.61661C11.8505 8.43801 11.7057 8.29322 11.5271 8.29322C11.3485 8.29326 11.2037 8.43801 11.2037 8.61661Z" fill="#00C500"/>
      <path d="M14.2757 8.6166C14.2757 8.438 14.131 8.29321 13.9524 8.29321H12.6588C12.4802 8.29321 12.3354 8.438 12.3354 8.6166V11.0581C12.3354 11.2367 12.4802 11.3815 12.6588 11.3815H13.9524C14.131 11.3815 14.2757 11.2367 14.2757 11.0581C14.2757 10.8795 14.131 10.7347 13.9524 10.7347H12.9822V10.1607H13.9524C14.131 10.1607 14.2757 10.0159 14.2757 9.83731C14.2757 9.65871 14.131 9.51392 13.9524 9.51392H12.9822V8.93992H13.9524C14.131 8.93999 14.2757 8.7952 14.2757 8.6166Z" fill="#00C500"/>
    </svg>
  );
};

export default LineSVG;