export const MSG_STATUS = {
  SENT: "sent",
  CREATED: "created",
  RECEIVED: "received",
  ERROR: "error"
};

export const MESSAGE_TYPES = {
  DEFAULT: "default",
  IN_OFFICE_REPLY: "in_office_auto_reply",
  OUT_OFFICE_REPLY: "out_of_office_auto_reply",
  BROADCAST: "broadcast",
  LOG: 'log',
  NOTE: 'note',
  SENT_EXTERNALLY: 'sent_externally'
};
