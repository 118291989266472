import React from "react";
import { Button, Modal } from "react-bootstrap";

const ImportContact = ({
  handleClose,
  handleSubmitImport,
  handleChangeImportFile,
}) => {
  return (
    <Modal
      className="contacts"
      show={true}
      onHide={handleClose}
      size={"lg"}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Contacts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="file"
          id="import-contact-file"
          accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(event) => handleChangeImportFile(event)}
        />
      </Modal.Body>
      <Modal.Footer className="d-block">
        <a
          href="https://docs.google.com/spreadsheets/d/1zQooppVaFt9QFlqBG-C2o0FNEIr5RyJFcn3ExXQXeug/edit?usp=sharing"
          className="float-left btn-link btn"
          target="_blank"
          rel="noreferrer"
        >
          Download Template
        </a>
        <Button
          variant="primary"
          onClick={handleSubmitImport}
          className="float-right"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportContact;
