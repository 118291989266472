import React from 'react';

const WhatsAppSVG = ({ width = '20', height = '20' }) => {
  return(
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#55CD6C"/>
      <path d="M7.2045 5.30793L6.54934 5.27344C6.34243 5.27344 6.13555 5.34242 5.99762 5.48035C5.68727 5.75621 5.17004 6.27344 5.03211 6.96312C4.79075 7.99762 5.17004 9.23898 6.06661 10.4804C6.96317 11.7217 8.68731 13.7217 11.7218 14.5838C12.6873 14.8596 13.4459 14.6872 14.0666 14.3079C14.5494 13.9976 14.8597 13.5148 14.9632 12.9976L15.0666 12.5148C15.1011 12.3769 15.0321 12.2045 14.8942 12.1355L12.7218 11.1355C12.5838 11.0665 12.4114 11.101 12.308 11.2389L11.4459 12.3424C11.3769 12.4114 11.2735 12.4458 11.17 12.4114C10.5838 12.2045 8.61833 11.3769 7.54934 9.30793C7.51485 9.20449 7.51485 9.10102 7.58383 9.03207L8.41141 8.10105C8.4804 7.99762 8.51485 7.85969 8.4804 7.75621L7.4804 5.51484C7.44587 5.41137 7.34243 5.30793 7.2045 5.30793Z" fill="#FEFEFE"/>
    </svg>
  );
};

export default WhatsAppSVG;