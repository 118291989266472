import React from 'react'
import $ from "jquery"

class ScrollDown extends React.Component {
  state = {
    unread_message_count: this.props.messages.length // reaplace with unread message count when backend is done
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
  }


  handleClick(){
    $(".msg_history").scrollTop($(".msg_history")[0].scrollHeight);
    this.setState({
      unread_message_count: 0
    })
  }

  render(){
    return <button className="scroll_down" onClick={this.handleClick}>{this.state.unread_message_count}</button>;
  }
}

export default ScrollDown
