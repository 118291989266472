import React from "react";
import PropTypes from "prop-types";
import dateFormat from "dateformat";
import Gallery from "react-grid-gallery";
import ModalImage from "react-modal-image";

export const LogMessage = ({ message, locale }) => {
  let agent_name = message.agent_name;

  if (message.attachments.length) {
    file = message.attachments[0];
    imgFile = file.type.split("/")[0] == "image";
  }

  const date = new Date(message.created_at); // Replace this with your actual date, e.g., created_at

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "Asian/Singapore",
    locale,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);

  return (
    <div className="row-fluid" id={message.id}>
      <div className="col-12 message">
        <small className="text-center d-inline-block w-100">
          <strong className="mb-2 font-weight-bold text-blue">
            {agent_name}{" "}
          </strong>{" "}
          {message.content && message.content} on {formattedDate}
        </small>
      </div>
    </div>
  );
};

LogMessage.defaultProps = {
  message: PropTypes.shape({
    content: "",
    file: "",
    created_at: "",
    status: "",
  }),
};

LogMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    file: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default LogMessage;
