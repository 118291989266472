import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import DashboardContext from "../dashboard/DashboardContext.js";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";

const SendMessage = (props) => {
  const dashboardContext = React.useContext(DashboardContext);
  const { state, addNewMessage, removeFromConversations, updateConversations } =
    dashboardContext;
  const { showModal, changeModalSendMessage } = props;
  const [message, setMessage] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [platform, setPlatform] = React.useState("-");
  const [submitButton, setSubmitButton] = React.useState({
    disabled: false,
    content: "Send Message",
  });
  const [ selectPlatforms, setSelectPlatforms] = React.useState([]);

  useEffect(() => {
    checkUrlParams();
  }, []);

  useEffect(() => {
    if (state.platforms) {
      setSelectPlatforms(state.platforms.filter(p => !p.global && (p.service == 'whatsapp_chatapi')));
    }
  },[state.platforms]);

  useEffect(() => {
    if (selectPlatforms.length > 0 && state.directMessage) {
      setPlatform(selectPlatforms[0]?.value);
    }
  }, [selectPlatforms, state.directMessage]);

  const checkUrlParams = () => {
    if (state.directMessage) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const phoneNumber = urlParams.get('phone_number');
      const content = urlParams.get('content');
      setNumber(`+${phoneNumber}`);
      if (content) {
        setMessage(content);
      }
    }
  };

  const handleClose = () => {
    changeModalSendMessage();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitButton({
      disabled: true,
      content: "<i class='fa fa-spinner fa-spin'></i> Sending",
    });
    const requestUrl = `${state.domain_url}/apps/${state.app_id}/platforms/${platform}/messages/`;
    // Create an object of formData
    const formData = new FormData();
    formData.append("message[content]", message);
    formData.append("message[phone_number]", number);
    formData.append("message[message_type]", "new_message");
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios
      .post(requestUrl, formData, {})
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          addNewMessage(JSON.parse(response.data.message).data);
          updateConversations(JSON.parse(response.data.conversation).data);
          if (state.selectedTab === "unassigned") {
            removeFromConversations(state.selectedConversation.id);
          }
          toastr.success(response.data.info);
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.message);
        }
        setSubmitButton({
          disabled: false,
          content: "Send Message",
        });
      });
  };
  return (
    <Modal
      dialogClassName="send-message-modal"
      show={showModal}
      onHide={() => handleClose()}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Send Message</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="platform">
            Channel
          </label>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            id="platform"
            className="form-control"
          >
            <option value="-" disabled>
              Select Channel
            </option>
            {selectPlatforms && selectPlatforms.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
          </select>
          <br />
          <label htmlFor="phone-number">
            Phone Number
          </label>
          <PhoneInput
            inputProps={{
              required: true,
            }}
            inputStyle={{ width: "100%" }}
            country="us"
            placeholder="Enter Phone Number"
            value={number}
            onChange={(phone) => setNumber(phone)}
          />
          <br />
          <label htmlFor="message">
            Message
          </label>
          <textarea
            type="text"
            aria-label="Message"
            className="form-control"
            placeholder="Enter Message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            value="Submit"
            id="send-form-button"
            disabled={submitButton.disabled}
          >
            <div dangerouslySetInnerHTML={{ __html: submitButton.content }} />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default SendMessage;
