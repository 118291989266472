/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import Gallery from "react-grid-gallery";
import ModalImage from "react-modal-image";
import { Twemoji } from "react-emoji-render";
import ReactAudioPlayer from "react-audio-player";
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';
import dateFormat from 'dateformat';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination } from 'swiper';

import "../../../packs/src/slider.scss";
import NoProductImage from "images/pngs/no-product-listview.png";
import SvgVoucherIconPath from "../../../../assets/images/svgs/discount_24.svg";

export const IncomingMessage = ({
  handler,
  message: { content, created_at, content_template, attachments, sender_name, message_type },
  message,
  isWidget,
  position,
  toggleContactInfo,
  locale
}) => {
  let imgFiles = [];
  let otherFiles = [];
  let hasFile = false;

  if (attachments.length) {
    hasFile = true;
    attachments.map((file) => {
      if (file.type.split("/")[0] == "image") {
        imgFiles.push({
          src: file.url,
          thumbnail: file.url,
          thumbnailWidth: 300,
          thumbnailHeight: 212,
          thumbnailCaption: content,
        });
      } else {
        otherFiles.push(file);
      }
    });
  }

  const date = new Date(message.created_at); // Replace this with your actual date, e.g., created_at

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "Asian/Singapore",
    locale,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);

  const messageDisplay = ({ content, content_template }) => {
    const processMessage = (text) => {
      // Use react-html-parser to parse the HTML content
      const parsedContent = ReactHtmlParser(text, {
        // Add your custom parsing options if needed
      });
      // Wrap the parsed content with Linkify to automatically linkify URLs
      return (
        <span className="text-content-message">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {parsedContent}
          </Linkify>
        </span>
      );
    };

    const formatDate = (isoString) => {
      if (!isoString) return '';
      const date = new Date(isoString);
      return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
    };

    if (content_template === 'default' || content_template === undefined) {
      const processedMessage = processMessage(content);
      return <div>{processedMessage}</div>;
    } else {
      // Parse content_template as JSON when it is not "default"
      let parsedContent;
      try {
        parsedContent = content;
      } catch (error) {
        console.error('Error decoding JSON content_template:', error);
        return <div>Invalid content template</div>;
      }

      if (parsedContent.products_template) {
        const products = parsedContent.products_template;

        return (
          <div className="content_message">
            <Swiper
              modules={[Navigation, Pagination]}
              direction="horizontal"
              loop={false}
              spaceBetween={10}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
              }}
              touchEventsTarget='container' // Ensure touch is enabled
              simulateTouch={true} // Enable touch gestures
              grabCursor={true}
              slidesPerView={1.2} // Default for all screens
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 8,
                },
                480: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 15,// Tablets
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20, // Desktop
                },
              }}
            >
              <div className="product-message">

                {products.map((product, index) => (
                  <SwiperSlide key={index}>
                    <div className="product-card">
                      <div className="product-image">
                        <a
                          href={`${parsedContent.shop_url}/products/${product.product_handle}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {product.product_img_url == "" ? (
                            <img
                              src={NoProductImage}
                              className="product-thumbnail"
                            />
                          ) : (
                            <img
                              src={product.product_img_url}
                              alt={product.product_name}
                              className="product-thumbnail"
                            />

                          )}
                        </a>
                      </div>
                      <div className="product-details">
                        <h5 className="product-name">{product.product_name}</h5>
                        <p className="product-price">{parsedContent.currency} {product.product_min_price}</p>
                        <div className="rich-content-separator"></div>
                        <button
                          className="add-to-cart-btn"
                          onClick={() => window.open(`${parsedContent.shop_url}/products/${product.product_handle}`, '_blank')}
                        >
                          View Product
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>

          </div>
        );
      } else if (parsedContent.order_template) {
        const order = parsedContent.order_template;
        return (
          <div className="order-container"> {/* Wrapper element */}
            <div className="order-card"
              onClick={() =>
                window.open(
                  `${parsedContent.shop_url}/account/orders/${order.token}`,
                  "_blank"
                )
              }>
              <div className="order-header">
                <h5 className="order-id">{order.order_name}</h5>
                <span className="order-date">
                  {new Date(order.created_at).toLocaleDateString()}
                </span>
              </div>

              <div className="order-status">
                {order.is_cancelled ? (
                  <span className="badge order-cancelled">Cancelled</span>
                ) : (
                  <>
                    <span
                      className={`badge financial-status ${order.financial_status.toLowerCase()}`}
                    >
                      {order.financial_status}
                    </span>
                    <span
                      className={`badge fulfillment-status ${order.fulfillment_status.toLowerCase()}`}
                    >
                      {order.fulfillment_status}
                    </span>
                  </>
                )}
              </div>

              <div className="order-items">
                {order.items.slice(0, 4).map((item, index) => (
                  <img
                    key={index}
                    src={item.image_url || "/no-image.png"}
                    alt={`Item ${index + 1}`}
                    className="order-item-thumbnail"
                  />
                ))}
                {order.items.length > 4 && (
                  <div className="extra-items">
                    +{order.items.length - 4}
                  </div>
                )}
              </div>
            </div>

            <div className="order-footer">
              <div className="order-summary">
                <p>{order.total_item_quantity} items</p>
                <p className="order-total">RM{order.total_price}</p>
              </div>
              <div className="rich-content-separator"></div>
              <button
                className="view-order-btn"
                onClick={() =>
                  window.open(
                    `${parsedContent.shop_url}/account/orders/${order.token}`,
                    "_blank"
                  )
                }
              >
                View order
              </button>
            </div>
          </div>
        );
      } else if (parsedContent.voucher_template) {
        const voucher = parsedContent.voucher_template;
        const starts_at = formatDate(voucher.voucher_starts_at); // Format the date here

        return (
          <div>
            <div className="clickable-voucher" onClick={() => window.open(`${parsedContent.shop_url}/account/vouchers/${voucher.voucher_id}`, '_blank')}>
              <div className="voucher-card">
                <div className="voucher-icon">
                  <img
                    src={SvgVoucherIconPath}
                    width={30}
                  />
                </div>
                <div className="voucher-detail">
                  <div className="voucher-title">
                    {voucher.voucher_title}
                  </div>
                  <div className="voucher-desc">
                    <div className="voucher-starts-date">
                      Active from {starts_at}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-voucher">
              <button className="view-voucher-btn" onClick={() => window.open(`${parsedContent.shop_url}/account/vouchers/${voucher.voucher_id}`, '_blank')}>
                View voucher
              </button>
            </div>
          </div>
        );
      }
      else {
        console.warn('Unknown content_template structure:', parsedContent);
        return <div>Invalid content template</div>;
      }
    }
  };

  if (message.content_template == "default") {
    return (
      <div className="row-fluid" id={message.id}>
        <div className="col-12 message">
          <div className="received_message">
            <div
              className={
                message.message_type === "call"
                  ? "content-message log"
                  : "content-message"
              }
            >
              {position.first && !isWidget ? (
                <span
                  className="mb-2 d-block font-weight-bold"
                  role="button"
                  onClick={() => toggleContactInfo()}
                >
                  {sender_name}
                </span>
              ) : null}
              {!isWidget && (
                <>
                  <input className="reply-btn" />
                  <div className="reply-menu">
                    <span
                      type="button"
                      className="reply-menu-item"
                      onClick={() => {
                        handler(message);
                        document.getElementById("conversation-textarea").focus();
                      }}
                    >
                      Reply
                    </span>
                  </div>
                </>
              )}
              {message.quoted_message_id && (
                <a
                  href={`?tab=all&by_id=${message.conversation_id}&message_id=${message.quoted_message_id}&on_page=${message.quoted_message.on_page}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="reply-container">
                    <p className="reply-title">
                      {message.quoted_message.status === "received"
                        ? message.quoted_message.name
                        : "You"}
                    </p>
                    <p className="reply-text">
                      {message.quoted_message.content}
                    </p>
                  </div>
                </a>
              )}
              {message.content &&
                messageDisplay({
                  content: message.content,
                  content_template: message.content_template,
                })}
              {hasFile &&
                (imgFiles.length + otherFiles.length === 1 ? (
                  <span
                    className={`file-attachment-message-preview ${!imgFiles[0] ? "file-border" : ""
                      }`}
                  >
                    {imgFiles.length === 1 ? (
                      <ModalImage
                        className="img-preview-file-attached"
                        small={imgFiles[0].src}
                        medium={imgFiles[0].src}
                        large={imgFiles[0].src}
                        alt={message.content}
                        hideZoom
                        showRotate={false}
                      />
                    ) : (
                      otherFiles.map((file, i) => {
                        if (file.type.split("/")[0] === "audio") {
                          return <ReactAudioPlayer key={i} src={file.url} controls />;
                        }
                        if (file.type.split("/")[0] === "video") {
                          return (
                            <div key={i}>
                              <video width="100%" controls>
                                <source src={file.url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          );
                        }
                        return (
                          <a key={i} target="_blank" href={file.url} rel="noreferrer">
                            Show file attachment &gt;
                          </a>
                        );
                      })
                    )}
                  </span>
                ) : (
                  <div className="file-attachments-wrap">
                    {imgFiles.length > 0 && <Gallery images={imgFiles} />}
                    <div className="file-attachements-non-image">
                      {otherFiles.map((file, i) => (
                        <a
                          key={i}
                          target="_blank"
                          href={file.url}
                          rel="noreferrer"
                        >
                          Show file attachment &gt;
                        </a>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <span className="message-datetime-incoming">{formattedDate}</span>
          </div>
        </div>
      </div>
    );
  }
  else if (message.content_template == "product") {
    return (
      <div className="row-fluid" id={message.id}>
        <div className="col-12 message">
          <div className="received_message" style={{ maxWidth: '95%', cssText: 'max-width: 95% !important' }}>
            {message.content &&
              messageDisplay({
                content: message.content,
                content_template: message.content_template,
              })}
            <span className="message-datetime-incoming">{formattedDate}</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row-fluid" id={message.id}>
        <div className="col-12 message">
          <div className="received_message">
            <div className="content-message" style={{ cssText: 'max-width: unset !important' }}>
              {message.content &&
                messageDisplay({
                  content: message.content,
                  content_template: message.content_template,
                })}
            </div>
            <span className="message-datetime-incoming">{formattedDate}</span>
          </div>
        </div>
      </div>
    );
  }
};

IncomingMessage.defaultProps = {
  message: PropTypes.shape({
    content: "",
    created_at: "",
    content_template: ""
  }),
};

IncomingMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    created_at: PropTypes.string,
    content_template: PropTypes.string
  }),
};

export default IncomingMessage;